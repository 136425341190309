import { useCallback, useEffect } from "react";
import { useSetAtom } from "jotai";
import { useContentManagerNavigationContext } from "../../contexts/ContentManagerNavigationContext";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { createNewFolders, removeFolders } from "../../helpers/ContentManagerFolderHelper";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { sortFolderTree, updateParents } from "../../helpers/TreeFolderHelper";
import { foldersTreeAtom } from "../../atoms/ContentManager";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentFolderApi } from "./ContentFolderApiHook";
import { ITreeItem } from "../../models/contentManager/FolderTree";

interface IContentManagerTableHandleRefreshProps {
    tableId: string;
    selectedFolderId: string | null;
}

const findById = (id: string) => (f: ITreeItem) => f.id === id;

export const useContentManagerTableHandleRefresh = ({
    tableId,
    selectedFolderId,
}: IContentManagerTableHandleRefreshProps) => {
    const { onLoadError } = useContentManagerNavigationContext();
    const setFoldersTree = useSetAtom(foldersTreeAtom);
    const { subscribe, unsubscribe } = useTableRefreshContext();

    const { projectId } = useProjectContext();
    const { getSubFolders: getContentSubFolders, get: getFolder } = useContentFolderApi(projectId);

    const onRefreshCallback = useCallback(
        (id: string | null) => {
            if (!id) {
                setFoldersTree([]);

                (async () => {
                    try {
                        const subFolders = await getContentSubFolders(null);
                        const newFolders = createNewFolders(null, subFolders);
                        setFoldersTree(sortFolderTree(newFolders));
                    } catch (error) {
                        defaultRequestErrorHandler(error);
                    }
                })();
                return;
            }

            setFoldersTree((oldTree) => {
                const existingItem = oldTree.find(findById(id))!;
                existingItem.isLoadingChildren = true;
                const existingChildren = oldTree.filter((item) => item.parentId === id).map((item) => item.id);

                if (existingChildren.length > 0) {
                    const newTree = removeFolders(existingChildren, id, oldTree, false);
                    return newTree;
                }

                return oldTree;
            });

            (async () => {
                try {
                    const subFolders = await getContentSubFolders(id);

                    setFoldersTree((oldTree) => {
                        const existingItem = oldTree.find(findById(id))!;
                        existingItem.isLoadingChildren = false;

                        if (subFolders.length === 0) {
                            existingItem.hasChildren = false;
                            existingItem.isOpen = false;
                            existingItem.areChildrenLoaded = false;
                            return [...oldTree];
                        }

                        existingItem.isOpen = true;
                        existingItem.areChildrenLoaded = true;

                        const newFolders = createNewFolders(existingItem, subFolders);
                        oldTree = sortFolderTree([...oldTree, ...newFolders]);
                        oldTree = updateParents(oldTree, newFolders);
                        return oldTree;
                    });
                } catch (error) {
                    try {
                        await getFolder(id);
                    } catch {
                        // If we don't have the folder here, it means the getRecords api call will also fail
                        // so we can let the table handle the error.
                        return;
                    }

                    onLoadError(error);
                }
            })();
        },
        [getContentSubFolders, onLoadError, setFoldersTree, getFolder],
    );

    useEffect(() => {
        const callback = (refreshAllSubscribed?: boolean) => {
            if (refreshAllSubscribed) {
                onRefreshCallback(selectedFolderId);
            }
        };

        subscribe(tableId, callback);
        return () => {
            unsubscribe(tableId, callback);
        };
    }, [onRefreshCallback, selectedFolderId, subscribe, unsubscribe, tableId]);
};
