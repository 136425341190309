import React, { useCallback } from "react";
import OrganizationIcon from "../../assets/img/icons/Organization.svg";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { NotificationService } from "../../services/NotificationService";
import { TableNoResultCTA } from "../table/TableNoResultCTA";
import { useNavigation } from "../../hooks/NavigationHook";
import { organizationPaths } from "../../PathConstants";
import { useOrganizationCreation } from "../../hooks/organization/OrganizationCreationHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { useSidebarContext } from "../../contexts/SidebarContext";

const permissionObject = {
    permissionKeys: [PermissionKeys.organization.read, PermissionKeys.organization.write],
};

export const NoOrganizationCTA: React.FC = () => {
    const { navigate } = useNavigation();
    const { isAllowed } = usePermissionCheck(permissionObject);
    const { setRefreshSidebar } = useSidebarContext();

    const hasOrganizationCTAPermissions = isAllowed(PermissionKeys.organization.write);

    const onOrganizationCreated = useCallback(
        ({ name, organizationId }: IOrganizationModel) => {
            setRefreshSidebar(true);
            NotificationService.addSuccessNotification({
                messageKey: "OrganizationsView.CreationSuccess",
                messageKeyParams: { name },
            });
            if (isAllowed(PermissionKeys.organization.read)) {
                navigate(organizationPaths.link.edit(organizationId));
            }
        },
        [isAllowed, navigate, setRefreshSidebar],
    );

    const createHelper = useOrganizationCreation({ onCreated: onOrganizationCreated });

    const onClick = useCallback(() => {
        return createHelper.mode === "modal" && createHelper.onCreate(() => ({}));
    }, [createHelper]);

    return (
        <>
            {hasOrganizationCTAPermissions && (
                <TableNoResultCTA
                    title={"Organization.NoOrganizationFound"}
                    description={"EntityDescription.Organizations"}
                    createTitle={"Common.Create"}
                    onClick={onClick}
                    logo={{
                        imgAlt: "",
                        imgSrc: OrganizationIcon,
                    }}
                />
            )}
        </>
    );
};
