import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import { InvalidCharacterRegex, InvalidCharacterValidationError } from "../helpers/InvalidCharacterHelper";
import { descriptionMaxLength } from "./PropertySchemas";
import { NullableValue } from "../models/NullableValue";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";

/**
 * The content validator class.
 */
export class ContentFormValidator extends FormValidator<IDefaultEntityProps> {
    public static readonly nameMaxLength: number = 450;

    protected schema = Yup.object().shape({
        name: Yup.string()
            .max(ContentFormValidator.nameMaxLength, ({ max }) => {
                return <ValidatorError messageKey="Validation.NameMaxLength" options={{ max }} />;
            })
            .required(() => <ValidatorError messageKey="Common.Required" />)
            .matches(InvalidCharacterRegex, InvalidCharacterValidationError),
        description: Yup.string()
            .nullable(true)
            .max(descriptionMaxLength, ({ max }) => {
                return (
                    <ValidatorError
                        messageKey="ContentFormValidator.DescriptionMaxLengthValidation"
                        options={{ max }}
                    />
                );
            }),
    });

    public validateName(name: string): Promise<string> {
        return this.schema.fields.name.validate(name);
    }

    public validateDescription(description: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.description.validate(description);
    }
}
