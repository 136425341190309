import React, { useCallback } from "react";
import { FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { EntityForm } from "../../forms/EntityForm";
import { ContentFileShareBehaviors } from "../../../models/contentManager/ContentManagerApiModels";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { useCreateModalEntity } from "../../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../../models/entity/form/IEntityFormData";
import { useContentFileShareFormValidation } from "../../../hooks/contentManager/contentFileShare/ContentFileShareFormValidationHook";
import { IContentFileShareFormFields } from "../../../formValidators/ContentFileShareFormValidator";
import { DateChangeValue, DateWithTimeZoneSelect } from "../../forms/DateWithTimeZoneSelect";
import { formatContentFileShareExpirationDate } from "../../../helpers/ContentFileShareHelper";
import { TimeZoneExpirationDateType } from "../../../models/DateWithTimeZone";

import "./ContentFileShareForm.scss";

interface IContentFileShareFormProps {
    onFormChange: (formData: IEntityFormData<IContentFileShareFormFields>) => void;
    initialValue: IContentFileShareFormFields;
}

type BehaviorCheckboxConfig = {
    className: string;
    id: string;
    tooltip: string;
    label: string;
    behavior: ContentFileShareBehaviors;
    testSelectorValue?: string;
};

const behaviorTranslationConfig: { [behavior: number]: BehaviorCheckboxConfig } = {
    [ContentFileShareBehaviors.Download]: {
        className: "download",
        id: "add-download",
        tooltip: "Share.DownloadTooltip",
        label: "Common.Download",
        behavior: ContentFileShareBehaviors.Download,
        testSelectorValue: "downloadCheckbox",
    },
    [ContentFileShareBehaviors.View]: {
        className: "view-in-browser",
        id: "add-view",
        tooltip: "Share.ViewInBrowserTooltip",
        label: "Share.ViewInBrowser",
        behavior: ContentFileShareBehaviors.View,
        testSelectorValue: "viewInBrowserCheckbox",
    },
} as const;

export const CreateContentFileShareForm = ({ initialValue, onFormChange }: IContentFileShareFormProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const {
        data: { behaviors },
        errors,
        onChange,
    } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (data) => useContentFileShareFormValidation({ data, checkBehaviors: true }),
        isDirty: (data) =>
            data.behaviors !== initialValue.behaviors ||
            !moment(data.expirationDate).isSame(initialValue.expirationDate, "day") ||
            data.timeZoneId !== initialValue.timeZoneId,
    });

    const checkChange = (behavior: ContentFileShareBehaviors, checked: boolean) => {
        onChange("behaviors", checked ? behaviors | behavior : behaviors & ~behavior);
    };

    const onDateChange = useCallback(
        (value: DateChangeValue) => {
            onChange(
                "expirationDate",
                value ? formatContentFileShareExpirationDate(value.date, value.timeZone.offset) : null,
            );
            onChange("timeZoneId", value ? value.timeZone.timeZoneId : null);
        },
        [onChange],
    );

    return (
        <EntityForm className="content-file-share-form">
            <FormGroup>
                <FormGroup>
                    <LocalizedLabel
                        aria-required="true"
                        for="select-hyperlink-behavior"
                        text="Share.SelectHyperlinkBehavior"
                        required
                    />
                    <FormGroup id="select-hyperlink-behavior" className="select-behavior" check>
                        {Object.values(behaviorTranslationConfig).map(({ behavior, className, id, label, tooltip, testSelectorValue }) => (
                            <div className={`select-behavior__${className}`} key={behavior}>
                                <Input
                                    type="checkbox"
                                    id={id}
                                    className={`select-behavior__${className}-input`}
                                    checked={!!(behaviors & behavior)}
                                    {...setSelector(testSelectorValue)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        checkChange(behavior, event.target.checked)
                                    }
                                />
                                <LocalizedLabel
                                    check
                                    className={`select-behavior__${className}-label`}
                                    for={id}
                                    text={label}
                                />
                                <i id={`${className}-tooltip`} className="fa fa-question-circle text-blue" />
                                <UncontrolledTooltip placement="top" target={`${className}-tooltip`}>
                                    {t(tooltip)}
                                </UncontrolledTooltip>
                            </div>
                        ))}
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <DateWithTimeZoneSelect
                        defaultState={{
                            timeZoneExpirationDateType: TimeZoneExpirationDateType.Custom,
                            date: moment.parseZone(initialValue.expirationDate).utcOffset(0, true),
                            defaultTimeZoneId: initialValue.timeZoneId ?? undefined,
                        }}
                        dateError={errors.expirationDate}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onDateChange={onDateChange}
                        dateTestSelectorValue="expirationDateField"
                        timeZoneTestSelectorValue="timeZoneField"
                    />
                </FormGroup>
            </FormGroup>
        </EntityForm>
    );
};
