import React from "react";
import { FoldersTree } from "./folderTree/FoldersTree";
import { useSetAtom, useAtomValue } from "jotai";
import { selectedFolderIdAtom, foldersTreeAtom } from "../../atoms/ContentManager";
import { handleCollapseAtom, setItemLoadingStateAtom } from "../../atoms/contentManager/foldersTree";
import { useContentManagerNavigationContext } from "../../contexts/ContentManagerNavigationContext";
import { useSubFolders } from "../../hooks/contentManager/folderTree/subFoldersHook";

/**
 * The content manager tree menu component.
 */
export const ContentManagerTreeView: React.FC = () => {
    const handleCollapse = useSetAtom(handleCollapseAtom);
    const setItemLoadingState = useSetAtom(setItemLoadingStateAtom);
    const { handleSelectedPath } = useContentManagerNavigationContext();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const foldersTree = useAtomValue(foldersTreeAtom);
    const { getSubFolders } = useSubFolders();

    return (
        <FoldersTree
            handleTreeItem={handleSelectedPath}
            foldersTree={foldersTree}
            selectedFolderId={selectedFolderId}
            getSubFolders={getSubFolders}
            handleCollapse={handleCollapse}
            setItemLoadingState={setItemLoadingState}
        />
    );
};
