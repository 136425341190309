import { useMemo } from "react";
import { useMatches } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import trimEnd from "lodash/trimEnd";
import {
    isContentManagerEntityCrumb,
    isDynamicCrumb,
    isEntityCrumb,
    isSearchParamPathCrumbs,
} from "../../models/breadcrumbs/CrumbType";
import { ICrumb } from "../../models/breadcrumbs/ICrumb";
import { IRouteHandle } from "../../models/router/IRouteHandle";
import { HashTranslationMap, isTranslatableHash } from "../../models/breadcrumbs/HashType";
import { useCurrentEntitiesNameContext } from "../../contexts/CurrentEntitiesNameContext";
import { SearchQueryParamsName } from "../../atoms/ContentManager";
import { EntityType } from "../../models/EntityType";

type Unpacked<T> = T extends (infer U)[] ? U : T;
type UseMatchesReturnType = ReturnType<typeof useMatches>;
type TMatchNoHandle = Omit<Unpacked<UseMatchesReturnType>, "handle">;
export type OverridenUseMatches = (TMatchNoHandle & { handle?: IRouteHandle })[];

interface CrumbsHookResult {
    crumbs: ICrumb[];
}

/**
 * The use crumbs hook.
 */

const parseSearchParamPathCrumbs = (pathValue: string, paramName: string, pathName: string) => {
    if (pathValue) {
        let paramPath = `?${paramName}=`;

        return pathValue.split("/").reduce<ICrumb[]>((acc, crumb) => {
            if (crumb) {
                paramPath += `/${crumb}`;

                acc.push({
                    name: crumb,
                    path: `${pathName}${paramPath}`,
                    escapeTranslation: true,
                    paramCrumb: paramName,
                });
            }

            return acc;
        }, []);
    }

    return [];
};

export const useCrumbs = (): CrumbsHookResult => {
    const [searchParams] = useSearchParams();
    const { hash, pathname: currentPath } = useLocation();
    const { getEntityName } = useCurrentEntitiesNameContext();
    const matches = useMatches();

    const regularCrumb: ICrumb[] = useMemo(() => {
        return (matches as OverridenUseMatches).reduce<ICrumb[]>((acc, { handle, pathname, params }) => {
            if (handle?.crumb) {
                const crumb = handle.crumb;
                if (isSearchParamPathCrumbs(crumb)) {
                    const { searchParamPathName } = crumb;

                    if (searchParams.has(searchParamPathName)) {
                        const pathCrumbs = parseSearchParamPathCrumbs(
                            searchParams.get(searchParamPathName)!,
                            searchParamPathName,
                            pathname,
                        );
                        return [...acc, ...pathCrumbs];
                    }

                    return acc;
                }

                let crumbName = "";
                let escapeTranslation = true;
                if (isDynamicCrumb(crumb)) {
                    crumbName = params[crumb.parameterName]!;
                } else if (isEntityCrumb(crumb)) {
                    crumbName = getEntityName(crumb.entityType);
                } else if (isContentManagerEntityCrumb(crumb)) {
                    crumbName = getEntityName(params["type"] as EntityType, params["itemId"]);
                } else {
                    crumbName = crumb.name;
                    escapeTranslation = false;
                }

                if (pathname !== "/") {
                    pathname = trimEnd(pathname, "/");
                }

                acc.push({
                    name: crumbName,
                    path: `${pathname}${crumb.hash || ""}`,
                    escapeTranslation,
                });
            }
            return acc;
        }, []);
    }, [getEntityName, matches, searchParams]);

    const hashCrumbs: ICrumb[] = useMemo(() => {
        const innerCrumbs = [];
        if (hash.length > 0) {
            const isTranslatable = isTranslatableHash(hash);

            if (!isTranslatable) {
                // eslint-disable-next-line no-console
                console.warn(
                    `Missing translatable hash value '${hash}' in translation map. this hash wasn't translated`,
                );
            }

            innerCrumbs.push({
                name: isTranslatable ? HashTranslationMap[hash] : hash,
                path: currentPath + hash,
                escapeTranslation: !isTranslatable,
            });
        }

        return innerCrumbs;
    }, [currentPath, hash]);

    const searchResultCrumb = useMemo(() => {
        if (searchParams.has(SearchQueryParamsName)) {
            return [
                {
                    name: "Search.Results",
                },
            ];
        }
        return [];
    }, [searchParams]);

    const crumbs: ICrumb[] = useMemo(() => {
        return [...regularCrumb, ...hashCrumbs, ...searchResultCrumb];
    }, [regularCrumb, hashCrumbs, searchResultCrumb]);

    return { crumbs };
};
