import { useEffect } from "react";
import { useContentFileApi } from "../contentManager/ContentFileApiHook";
import { useDownloadFile } from "../contentManager/FileDownloadUrlHook";
import { FileDownloadService } from "../../services/FileDownloadService";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

const downloadScope = "project";

export const useProjectDownloadManager = (projectId: string) => {
    const { getFileDownloadUrl } = useContentFileApi(projectId);
    const { triggerFileDownload } = useDownloadFile();

    useEffect(() => {
        if (!projectId) {
            return;
        }

        const unsubscribe = FileDownloadService.onRequestFileDownload(downloadScope, (request) => {
            const { fileId, isAttachment, translationLanguageCode, action } = request;
            getFileDownloadUrl(fileId, isAttachment, translationLanguageCode, action)
                .then((downloadLink: string) => {
                    triggerFileDownload(downloadLink);
                })
                .catch((error) => defaultRequestErrorHandler(error));
        });

        return unsubscribe;
    }, [projectId, getFileDownloadUrl, triggerFileDownload]);
};
