import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table } from "../table/Table";
import { ISortField } from "../../models/ISortField";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { useTabFirstActivation } from "../../hooks/Tabs";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { IEntityResult } from "../../models/IEntityResult";
import { SortOrder } from "../../models/SortOrder";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { DependencyTableNameItem } from "../dependencies/DependencyTableNameItem";
import { DependencyTableTypeItem } from "../dependencies/DependencyTableTypeItem";

import "./DependenciesTable.scss";

/**
 * The dependencies table props interface.
 */
interface IDependenciesTableProps {
    getRecords: (
        dependencyType: DependencyType,
        offset?: number,
        sortField?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>>;
    dependencyType: DependencyType;
    active: boolean;
    parentEntityType: string;
}

const noResultsCTAProps = {
    title: "Common.Dependencies",
    description: "Dependency.Description",
};

const columnDefs: IEntityTableColumnDef[] = [
    {
        type: "JSX",
        fieldName: "entityName",
        displayName: "Common.Name",
        sortField: {
            name: "EntityName",
            order: SortOrder.Asc,
        },
        content: (entity: IDependencyModel) => <DependencyTableNameItem {...entity} />,
        shouldTruncateText: true,
        testSelectorColumnName: "name",
    },
    {
        type: "JSX",
        fieldName: "entityType",
        displayName: "Common.Type",
        content: ({ entityType }: IDependencyModel) => <DependencyTableTypeItem entityType={entityType} />,
        className: "entity-type",
        shouldTruncateText: true,
    },
];

/**
 * The dependencies table component.
 */
export const DependenciesTable: React.FC<IDependenciesTableProps> = ({
    getRecords,
    dependencyType,
    active,
    parentEntityType,
}: IDependenciesTableProps) => {
    const location = useLocation();
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const filterHelper = useDefaultTableFilterHelper("Dependency.Filter");

    const getDependenciesRecords = useCallback(
        (filterValue?: string, sortField?: ISortField[], offset?: number) => {
            return getRecords(dependencyType, offset, sortField, filterValue);
        },
        [dependencyType, getRecords],
    );

    const isActive = location.hash.toLowerCase() === "#dependencies" && active;

    const { tabActivatedFirstTime } = useTabFirstActivation({ active: isActive });

    useEffect(() => {
        if (isActive) {
            setTriggerUpdate(true);
        }
    }, [isActive]);

    return (
        <div className="dependency-table-view">
            {tabActivatedFirstTime && (
                <Table
                    className="dependency-table"
                    columnDefs={columnDefs}
                    getRecords={getDependenciesRecords}
                    triggerUpdate={triggerUpdate}
                    keyExtractor={(item) => item.entityId}
                    loadingMessageKey="Loading.Dependencies"
                    rowDisabled={(dependencyModel) => dependencyModel.restricted}
                    tableId={`${parentEntityType}-dependencies-${dependencyType}`}
                    noResultsCTAProps={noResultsCTAProps}
                    filterHelper={filterHelper}
                    defaultSortField={{
                        name: "EntityName",
                        order: SortOrder.Asc,
                    }}
                    counterPosition="Start"
                />
            )}
        </div>
    );
};
