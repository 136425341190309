import { createContext, useContext } from "react";
import { NotificationsContainer } from "../components/notifications/NotificationsContainer";

/**
 * The notification service context prop.
 */
export type NotificationServiceContextProps = React.MutableRefObject<NotificationsContainer | null>;

/**
 * The notification service context.
 */
export const NotificationServiceContext = createContext<NotificationServiceContextProps>(null!);

/**
 * The notification service context hook.
 */
export const useNotificationServiceContext = (): NotificationServiceContextProps =>
    useContext(NotificationServiceContext);
