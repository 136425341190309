import React from "react";
import { OptionProps } from "react-select";
import { IUserGroupModel } from "../../models/user/IUserGroupModel";
import { UserGroupAvatar } from "./UserGroupAvatar";

type AddPermissionsGroupDataType = {
    value: string;
    label: string;
    data: IUserGroupModel;
};

export const UserGroupOption: React.FC<OptionProps> = (optionProps) => {
    const {
        data: { name },
    } = optionProps.data as AddPermissionsGroupDataType;

    return (
        <div className="user-group-placeholder__wrapper">
            <UserGroupAvatar groupName={name} parentType="dropdown" />
            <span className="user-group-placeholder__text">{name}</span>
        </div>
    );
};
