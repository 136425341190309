import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface ISubFeatureItemProps {
    enabled: boolean;
    name: string;
}

export const SubFeatureItem: React.FC<ISubFeatureItemProps> = ({ enabled, name }: ISubFeatureItemProps) => {
    const { t } = useTranslation();

    return (
        <div className={classNames("accordion-body__item", { disabled: !enabled })}>
            <i className={classNames({ "fa fa-check": enabled, "fal fa-ban": !enabled })} />
            <div>{t(name)}</div>
        </div>
    );
};
