import React, { useCallback } from "react";
import { Trans } from "react-i18next";
import { Table } from "../table/Table";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { ServiceHookFormValidator } from "../../formValidators/ServiceHookFormValidator";
import { ISortField } from "../../models/ISortField";
import { IServiceHookExecutionModel } from "../../models/serviceHooks/IServiceHookExecutionModel";
import { useProjectContext } from "../../contexts/ProjectContext";
import {
    ServiceHookExecutionStatus,
    ServiceHookExecutionStatusTranslationMap,
} from "../../models/serviceHooks/ServiceHookExecutionStatus";
import { IServiceHookExecutionResultModel } from "../../models/serviceHooks/IServiceHookExecutionResultModel";
import { ServiceHookStatusCodeIndicator } from "./ServiceHookStatusCodeIndicator";
import { ServiceHookEventTypeLabel } from "./ServiceHookEventTypeLabel";
import { useServiceHookApi } from "../../hooks/serviceHook/ServiceHookApiHook";

const columnDefs: IEntityTableColumnDef[] = [
    {
        type: "JSX",
        displayName: "Common.Status",
        fieldName: "status",
        sortField: {
            name: "Status",
            order: SortOrder.Asc,
        },
        testSelectorColumnName: "status",
        content: (item: IServiceHookExecutionModel) => (
            <Trans i18nKey={ServiceHookExecutionStatusTranslationMap[item.status]} />
        ),
    },
    {
        type: "DateTime",
        displayName: "Common.Timestamp",
        fieldName: "queuedDate",
        sortField: {
            name: "QueuedDate",
            order: SortOrder.Desc,
        },
        testSelectorColumnName: "timestamp",
    },
    {
        type: "JSX",
        displayName: "Common.EventType",
        shouldTruncateText: true,
        fieldName: "eventType",
        sortField: {
            name: "EventType",
            order: SortOrder.Asc,
        },
        testSelectorColumnName: "eventType",
        content: ({ eventType }: IServiceHookModel) => <ServiceHookEventTypeLabel eventType={eventType} />,
    },
    {
        type: "JSX",
        displayName: "Common.HttpStatus",
        fieldName: "result",
        testSelectorColumnName: "httpStatus",
        content: ({ result, status }: IServiceHookExecutionModel) => {
            let response;
            if (result) {
                response = JSON.parse(result) as IServiceHookExecutionResultModel;
            }

            return response?.responseStatusCode || status === ServiceHookExecutionStatus.Failed ? (
                <ServiceHookStatusCodeIndicator statusCode={response?.responseStatusCode} />
            ) : (
                <></>
            );
        },
    },
];

export const ServiceHookExecutionView: React.FC = () => {
    const { projectId } = useProjectContext();
    const { getExecutionRecords } = useServiceHookApi(projectId);
    const {
        entityProps: {
            entity: { serviceHookId },
        },
    } = useEditEntityContext<IServiceHookModel, ServiceHookFormValidator>();

    const getExecutions = useCallback(
        (filterValue?: never, sortFields?: ISortField[], offset?: number) => {
            return getExecutionRecords(serviceHookId, filterValue, sortFields, offset);
        },
        [getExecutionRecords, serviceHookId],
    );

    return (
        <Table
            className="service-hook-execution-table"
            columnDefs={columnDefs}
            getRecords={getExecutions}
            keyExtractor={(item: IServiceHookExecutionModel) => item.serviceHookExecutionId}
            loadingMessageKey="Loading.ServiceHookExecutions"
            tableId="serviceHookExecutions"
            defaultSortField={{
                name: "QueuedDate",
                order: SortOrder.Desc,
            }}
        />
    );
};
