import { ILicenseFeatures } from "../models/license/ILicenseFeatureModel";

export const licenseFeaturesTranslationMap: Record<keyof ILicenseFeatures, string> = {
    contentFeature: "Common.ContentManager",
    dataSetsFeature: "Common.DataSets",
    templatesFeature: "Common.Templates",
    globalFeature: "Licensing.ContentFeature.OfficeForTheWeb",
};

export const licenseSubFeaturesTranslationMap = {
    aiDocumentTranslation: "Licensing.ContentFeature.AiDocumentTranslation",
    explicitPermissionsManagement: "Licensing.ContentFeature.ExplicitPermissionsManagement",
    officeForTheWeb: "Licensing.ContentFeature.OfficeForTheWeb",
    sharing: "Licensing.ContentFeature.Sharing",
    form: "Common.Forms",
    json: "Common.Json",
    xml: "Common.Xml",
    aiFormAssistant: "Licensing.DataSetsFeature.AIFormAssistant",
    word: "Common.Word",
    maxProjects: "Licensing.GlobalFeature.Projects",
    maxStorage: "Licensing.GlobalFeature.Storage",
} as const;
