import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { IProjectModelWithOrganizationName } from "../../models/project/IProjectModelWithOrganizationName";
import { SortOrder } from "../../models/SortOrder";
import { Table } from "../table/Table";
import { RecentProjectsDeck } from "../projects/RecentProjectsDeck";
import { EditView } from "../views/EditView";
import { NoOrganizationCTA } from "../organizations/NoOrganizationCTA";
import { LoadingScreen } from "../loading/LoadingScreen";
import { useHome } from "../../hooks/home/HomeHook";
import { ProjectNameWithAvatar } from "../projects/ProjectNameWithAvatar";
import { LoadingModal } from "../modals/LoadingModal";
import { ProjectStatus } from "../../models/project/ProjectStatus";
import { useFullscreenContext } from "../../contexts/FullscreenContext";
import { TableViewHeader } from "../views/TableViewHeader";

import "./HomeView.scss";

/**
 * The home view component.
 */
export const HomeView: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const {
        homeState,
        getProjects,
        filterHelper,
        homeOverlayLoading,
        showNoOrganizationCTA,
        showHomeViewDataVisibility,
    } = useHome({
        onRefresh: () => {
            setShowLoadingModal(false);
        },
    });

    const { fullscreen } = useFullscreenContext();

    const projectNameContent = useCallback(
        (model: IProjectModelWithOrganizationName) => (
            <ProjectNameWithAvatar
                project={model}
                organizationName={model.organizationName}
                onProjectStillAwaiting={() => setShowLoadingModal(true)}
            />
        ),
        [],
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "name",
            displayName: "Common.Name",
            className: "name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
            content: projectNameContent,
            shouldTruncateText: true,
            testSelectorColumnName: "name",
        },
        {
            type: "Text",
            fieldName: "organizationName",
            displayName: "EntityType.Organization",
            sortField: {
                name: "OrganizationName",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "organizationName",
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Common.Description",
            sortField: {
                name: "Description",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "description",
        },
    ];

    return (
        <EditView className="home-view" fullscreen={fullscreen}>
            <TableViewHeader titleKey="HomeView.Welcome" />
            {homeOverlayLoading && <LoadingScreen>{t("Home.Loading")}</LoadingScreen>}
            {showNoOrganizationCTA && <NoOrganizationCTA />}

            <div className={classnames("home-view-body", { loading: homeOverlayLoading })}>
                {homeState.organizationExist && (
                    <div
                        className={classnames("home-view-data", {
                            "home-view-data-visibility": !showHomeViewDataVisibility,
                        })}
                    >
                        {homeState.recentProjects.length > 0 && (
                            <>
                                <div className="recent-projects-title">{t("HomeView.RecentProjects")}</div>
                                <RecentProjectsDeck recentProjects={homeState.recentProjects} />
                            </>
                        )}

                        <div className="project-table-title">{t("HomeView.YourProjects")}</div>
                        <LoadingModal
                            show={showLoadingModal}
                            onButtonClose={() => setShowLoadingModal(false)}
                            labelKey="Project.CreateProject.Loading"
                        />
                        <Table
                            className="project-list"
                            columnDefs={columnDefs}
                            getRecords={getProjects}
                            keyExtractor={(item) => item.projectId}
                            nameExtractor={(item) => item.name}
                            loadingMessageKey="Loading.Projects"
                            filterHelper={filterHelper}
                            tableId="homeviewprojects"
                            triggerRefresh={homeState.triggerRefresh}
                            rowDisabled={(item) => item.state === ProjectStatus.AwaitingCreation}
                            counterPosition="Start"
                        />
                    </div>
                )}
            </div>
        </EditView>
    );
};
