import { useState, useCallback, useEffect } from "react";
import { useNavigation } from "../NavigationHook";
import { useModalContext } from "../../contexts/ModalContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import { NotificationService } from "../../services/NotificationService";
import { templatePaths } from "../../PathConstants";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { TemplateForm } from "../../components/templates/TemplateForm";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { ITemplateFormFields } from "../../models/templates/ITemplateFormFields";
import { useTemplateApi } from "./TemplateApiHook";

/**
 * The clone template return interface.
 */
interface CloneTemplateReturn {
    editCloneTemplate: (item: ITemplateModel) => void;
}

/**
 * The clone template hook.
 */
export const useCloneTemplate = (): CloneTemplateReturn => {
    const { navigate } = useNavigation();
    const { name: projectName, projectId, organizationName } = useProjectContext();
    const { showModal, closeModal } = useModalContext();

    const { clone, generateUniqueName } = useTemplateApi(projectId);
    const [cloneTemplateFormFields, setCloneTemplateFormFields] = useState<ITemplateFormFields>();
    const [triggerEditTemplateClone, setTriggerEditTemplateClone] = useState(false);
    const [clonedTemplateId, setClonedTemplateId] = useState<string | null>(null);

    useEffect(() => {
        if (clonedTemplateId) {
            navigate(templatePaths.link.edit(organizationName, projectName, clonedTemplateId));
            setClonedTemplateId(null);
        }
    }, [clonedTemplateId, navigate, projectName, organizationName]);

    const onCloneTemplateClosed = useCallback((): void => {
        closeModal();
        setCloneTemplateFormFields(undefined);
    }, [closeModal]);

    const onCloned = useCallback(
        ({ name, templateId: id }: ITemplateModel): void => {
            onCloneTemplateClosed();

            NotificationService.addSuccessNotification({
                messageKey: "Template.Create.Success",
            });

            setClonedTemplateId(id);
        },
        [onCloneTemplateClosed],
    );

    const showCloneTemplateModal = useCallback(() => {
        showModal({
            entity: cloneTemplateFormFields,
            onCreate: clone,
            onCreated: onCloned,
            formPropsToFlow: {
                mode: "Clone",
            },
            modalProps: {
                onClose: onCloneTemplateClosed,
                createLabel: "Common.Clone",
                className: "clone-template-modal",
                titleKey: "Template.Clone",
                unsavedWarningBody: "Template.UnsavedWarning",
                size: "md",
                expandable: false,
            },
            contentToDisplay: TemplateForm,
        });
    }, [cloneTemplateFormFields, clone, onCloned, showModal, onCloneTemplateClosed]);

    const editCloneTemplate = useCallback(
        (item: ITemplateModel): void => {
            (async () => {
                try {
                    const { name: newName } = await generateUniqueName(item.templateId);
                    setCloneTemplateFormFields({
                        name: newName,
                        description: "",
                        type: item.type,
                        dataSetIds: item.dataSetIds,
                        templateId: item.templateId,
                        projectId: projectId,
                    });
                    setTriggerEditTemplateClone(true);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                }
            })();
        },
        [generateUniqueName, projectId],
    );

    useEffect(() => {
        if (triggerEditTemplateClone && cloneTemplateFormFields) {
            showCloneTemplateModal();
            setTriggerEditTemplateClone(false);
        }
    }, [triggerEditTemplateClone, cloneTemplateFormFields, showCloneTemplateModal]);

    return {
        editCloneTemplate,
    };
};
