import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { useBuildSearchProjectProperties } from "../helpers/SearchHelper";
import { HeaderNavBarContext } from "../contexts/HeaderNavBarContext";
import { IRoute } from "../models/IRoute";
import { contentManagerPaths, dataSetPaths, paths, projectPaths, templatePaths } from "../PathConstants";
import { ProjectContext } from "../contexts/ProjectContext";
import { ProjectLoading } from "../components/projects/ProjectLoading";
import { SidebarLayout } from "./SidebarLayout";
import { SidebarNavItemIcon } from "../components/sidebar/SidebarNavItemIcon";
import { SidebarProjectItem } from "../components/projects/SidebarProjectItem";
import { useNavigation } from "../hooks/NavigationHook";
import { useProjectApi } from "../hooks/project/ProjectApiHook";
import { IProjectModelWithOrganizationName } from "../models/project/IProjectModelWithOrganizationName";
import { useSearchApi } from "../hooks/search/SearchApiHook";
import { useProjectDownloadManager } from "../hooks/project/ProjectDownloadManager";
import { defaultRequestErrorHandler } from "../helpers/ErrorHelper";
import { featuresStateAtom, useLicenseFeatures } from "../hooks/license/LicenseFeatureHook";
import { useFeatureCheck } from "../hooks/license/LicenseCheckHook";
import { useCurrentEntitiesNameContext } from "../contexts/CurrentEntitiesNameContext";

/**
 * The project layout params interface.
 */
export interface IProjectLayoutParams {
    projectName: string;
    organizationName: string;
}

/**
 * The feature status interface
 */
interface IFeatureStatus {
    dataSetEnabled: boolean;
    templateEnabled: boolean;
    contentEnabled: boolean;
}

/**
 * Builds sidebar routes
 * @returns [sidebar Routes, footer sidebar Routes]
 */
const buildProjectRoutes = (
    projectId: string,
    organizationNameOrId: string,
    projectNameOrId: string,
    projectDisplayName: string,
    organizationDisplayName: string,
    feature: IFeatureStatus,
): [IRoute[], IRoute[]] => {
    const headerRoute: (IRoute | null)[] = [
        {
            name: projectDisplayName,
            children: (
                <SidebarProjectItem
                    projectId={projectId}
                    projectName={projectDisplayName}
                    organizationName={organizationDisplayName}
                />
            ),
        },
        feature.dataSetEnabled
            ? {
                  path: projectPaths.link.dataSets(organizationNameOrId, projectNameOrId),
                  children: <SidebarNavItemIcon icon="far fa-database" name="Common.DataSets" />,
                  testSelectorValue: "dataSetsItem",
              }
            : null,
        feature.templateEnabled
            ? {
                  path: projectPaths.link.templates(organizationNameOrId, projectNameOrId),
                  children: <SidebarNavItemIcon icon="far fa-file-invoice" name="Common.Templates" />,
                  testSelectorValue: "templatesItem",
              }
            : null,
        feature.contentEnabled
            ? {
                  path: projectPaths.link.contentManager(organizationNameOrId, projectNameOrId),
                  children: <SidebarNavItemIcon icon="far fa-cabinet-filing" name="Common.Content" />,
                  testSelectorValue: "contentManagerItem",
              }
            : null,
    ];

    return [
        headerRoute.filter((hr): hr is IRoute => !!hr),
        [
            {
                path: projectPaths.link.settings(organizationNameOrId, projectNameOrId),
                children: <SidebarNavItemIcon icon="far fa-cog" name="Route.Settings.Project" />,
                testSelectorValue: "projectSettingsItem",
            },
        ],
    ];
};

/**
 * The project layout component.
 */
export const ProjectLayout: React.FC = () => {
    const { replace } = useNavigation();
    const { organizationName: organizationNameOrId, projectName: projectNameOrId } = useParams<{
        organizationName: string;
        projectName: string;
    }>() as Record<string, string>;
    const { setEntityName } = useCurrentEntitiesNameContext();
    const [projectModel, setProjectModel] = useState<IProjectModelWithOrganizationName>();
    const { isLoadingFeatures } = useAtomValue(featuresStateAtom);
    const { searchBy } = useSearchApi();
    const { getByOrganizationName } = useProjectApi();
    const { checkFeature } = useFeatureCheck();

    const getProjectModel = useCallback(async () => {
        setProjectModel(undefined);
        try {
            const project = await getByOrganizationName(organizationNameOrId, projectNameOrId);
            setEntityName("Project", project.name);
            setEntityName("Organization", project.organizationName);
            setProjectModel(project);
        } catch (error) {
            defaultRequestErrorHandler(error);
            replace(paths.home);
        }
    }, [getByOrganizationName, organizationNameOrId, projectNameOrId, replace, setEntityName]);

    const dataSetMatch = useMatch(`${dataSetPaths.route.base}/*`);
    const templateMatch = useMatch(`${templatePaths.route.base}/*`);
    const contentManagerMatch = useMatch(`${contentManagerPaths.route.base}/*`);

    useLicenseFeatures(projectModel?.organizationId);

    const dataSetEnabled = checkFeature("dataSetsFeature", "enabled");
    const templateEnabled = checkFeature("templatesFeature", "enabled");
    const contentEnabled = checkFeature("contentFeature", "enabled");

    const isLoadingProjectFeature =
        isLoadingFeatures ||
        (dataSetEnabled === undefined && templateEnabled === undefined && contentEnabled === undefined);

    useEffect(() => {
        getProjectModel();

        return () => {
            setEntityName("Project", "");
            setEntityName("Organization", "");
        };
    }, [getProjectModel, setEntityName]);

    useEffect(() => {
        if (isLoadingProjectFeature) {
            return;
        }

        if (
            (!dataSetEnabled && dataSetMatch) ||
            (!templateEnabled && templateMatch) ||
            (!contentEnabled && contentManagerMatch)
        ) {
            replace(projectPaths.link.dashboard(organizationNameOrId, projectNameOrId));
        }
    }, [
        dataSetMatch,
        replace,
        templateMatch,
        contentManagerMatch,
        isLoadingFeatures,
        dataSetEnabled,
        templateEnabled,
        contentEnabled,
        projectModel,
        isLoadingProjectFeature,
        organizationNameOrId,
        projectNameOrId,
    ]);

    const { projectId, projectDisplayName } = useMemo(() => {
        return {
            projectId: projectModel?.projectId ?? "",
            projectDisplayName: projectModel?.name ?? "",
        };
    }, [projectModel]);

    const projectContextValue = useMemo(() => {
        if (!projectModel) {
            return null;
        }
        return projectModel;
    }, [projectModel]);

    const onSearch = useMemo(() => (searchValue: string) => searchBy(searchValue, projectId), [projectId, searchBy]);

    const searchPropertiesObject = useMemo(() => {
        return {
            onSearch,
            organizationName: projectModel?.organizationName ?? organizationNameOrId,
            projectName: projectNameOrId,
        };
    }, [onSearch, organizationNameOrId, projectModel?.organizationName, projectNameOrId]);

    const searchProperties = useBuildSearchProjectProperties(searchPropertiesObject);

    const headerNavBarContextValue = useMemo(() => {
        return { search: searchProperties };
    }, [searchProperties]);

    useProjectDownloadManager(projectId);

    if (!projectModel || isLoadingProjectFeature) return <ProjectLoading />;

    const [routes, footerRoutes] = buildProjectRoutes(
        projectId,
        organizationNameOrId,
        projectNameOrId,
        projectDisplayName,
        projectModel.organizationName,
        {
            contentEnabled: !!contentEnabled,
            dataSetEnabled: !!dataSetEnabled,
            templateEnabled: !!templateEnabled,
        },
    );

    return (
        <ProjectContext.Provider value={projectContextValue}>
            <HeaderNavBarContext.Provider value={headerNavBarContextValue}>
                <SidebarLayout routes={routes} footerRoutes={footerRoutes}>
                    <Outlet />
                </SidebarLayout>
            </HeaderNavBarContext.Provider>
        </ProjectContext.Provider>
    );
};
