import { useCallback } from "react";
import { IShowModalParams, useModalContext } from "../../contexts/ModalContext";
import { CommentModalConfig as CommentModalconfig, IVersionComment } from "../../models/versioning/VersioningModels";
import { ReadVersionCommentForm } from "../../components/versioning/ReadVersionCommentForm";
import { RestoreVersionCommentForm } from "../../components/versioning/RestoreVersionCommentForm";
import { VersionCommentForm } from "../../components/versioning/VersionCommentForm";

interface IDefaultEditVersionCommentHook {
    showCommentModal: (config: CommentModalconfig) => Promise<string | false>;
}

export const useVersionComment = (): IDefaultEditVersionCommentHook => {
    const { showModal, closeModal } = useModalContext();

    const getModalProps = useCallback(
        (
            config: CommentModalconfig,
        ): Pick<
            IShowModalParams<object, object>,
            "contentToDisplay" | "modalProps" | "allowCreateWhenNotDirty" | "formPropsToFlow"
        > => {
            switch (config.type) {
                case "new":
                    return {
                        modalProps: {
                            createLabel: "Common.Proceed",
                            className: "version-comment-modal",
                            titleKey: "Version.AddComment",
                            unsavedWarningBody: "Version.UnsavedWarning",
                            size: "md",
                            expandable: false,
                        },
                        contentToDisplay: VersionCommentForm,
                        allowCreateWhenNotDirty: true,
                        formPropsToFlow: {
                            aboveCommentInputLabelKey: "Common.WhatChanged",
                            underCommentInputLabelKey: "Version.CommentDescription",
                        },
                    };
                case "edit":
                    return {
                        modalProps: {
                            createLabel: "Common.Save",
                            className: "versionning-comment-modal",
                            titleKey: "Version.EditComment",
                            unsavedWarningBody: "Version.UnsavedWarning",
                            size: "md",
                            expandable: false,
                        },
                        contentToDisplay: VersionCommentForm,
                        allowCreateWhenNotDirty: false,
                        formPropsToFlow: {
                            aboveCommentInputLabelKey: "Version.VersionComment",
                        },
                    };
                case "view":
                    return {
                        modalProps: {
                            className: "versionning-comment-modal",
                            titleKey: "Version.VersionComment",
                            size: "md",
                            expandable: false,
                            primaryActionRemoved: true,
                        },
                        contentToDisplay: ReadVersionCommentForm,
                        allowCreateWhenNotDirty: false,
                        formPropsToFlow: {},
                    };
                case "restore":
                    return {
                        modalProps: {
                            createLabel: "Common.Restore",
                            className: "version-comment-modal",
                            titleKey: "Version.RestoreVersion",
                            unsavedWarningBody: "Version.UnsavedWarning",
                            size: "md",
                            expandable: false,
                        },
                        contentToDisplay: RestoreVersionCommentForm,
                        allowCreateWhenNotDirty: true,
                        formPropsToFlow: {
                            aboveCommentInputLabelKey: "Common.Comment",
                            ...config,
                        },
                    };
            }
        },
        [],
    );

    const showCommentModal = useCallback(
        (config: CommentModalconfig) => {
            const { contentToDisplay, modalProps, allowCreateWhenNotDirty, formPropsToFlow } = getModalProps(config);
            const initialValue = config.type === "view" || config.type === "edit" ? config.comment : "";
            return new Promise<string | false>((res) => {
                showModal({
                    contentToDisplay,
                    entity: { comment: initialValue },
                    modalProps: {
                        onClose: () => {
                            res(false);
                            closeModal();
                        },
                        ...modalProps,
                    },
                    onCreate: async (entity: IVersionComment) => {
                        if (config.type === "edit" || config.type === "restore") {
                            await config.onCreate(entity);
                        }

                        return entity;
                    },
                    onCreated: (entity: IVersionComment) => {
                        closeModal();
                        res(entity.comment);
                    },
                    formPropsToFlow,
                    allowCreateWhenNotDirty,
                });
            });
        },
        [closeModal, getModalProps, showModal],
    );

    return {
        showCommentModal,
    };
};
