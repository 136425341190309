import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { ILicenseFeatures } from "../../models/license/ILicenseFeatureModel";
import { featuresStateAtom } from "./LicenseFeatureHook";

export const useFeatureCheck = () => {
    const features = useAtomValue(featuresStateAtom).features;

    const checkFeature = useCallback(
        <T extends keyof ILicenseFeatures, U extends keyof ILicenseFeatures[T]>(
            feature: T,
            key: U,
        ): ILicenseFeatures[T][U] | undefined => {
            if (!features) {
                return;
            }

            return features?.[feature]?.[key];
        },
        [features],
    );

    return { checkFeature };
};
