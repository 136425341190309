import React, { JSXElementConstructor, ReactElement } from "react";
import { Alert, AlertProps, Col } from "reactstrap";
import { Trans } from "react-i18next";
import { NotificationOptions } from "../../models/notification/NotificationOptions";

import "./NotificationsContainer.scss";

/**
 * The notifications container state interface.
 */
interface INotificationsContainerState {
    notifications: ReactElement<AlertProps>[];
    timeoutIds: number[];
}

const oneSecond: number = 1000;

/**
 * Animation duration (animate faster)
 */
const animationDuration = 0.5;

/**
 * The dismiss after fade should be 80% of the animation duration.
 */
const dismissAfterFade = oneSecond * animationDuration * 0.8;

/**
 * The notifications container component.
 */
export class NotificationsContainer extends React.Component<{}, INotificationsContainerState> {
    private lastNotificationKey: number = 0;
    constructor(props: {}) {
        super(props);
        this.state = {
            notifications: [],
            timeoutIds: [],
        };
    }

    // to stop the warning of calling setState of unmounted component
    public componentWillUnmount(): void {
        const { timeoutIds } = this.state;
        for (const timeoutId of timeoutIds) {
            window.clearTimeout(timeoutId);
        }
    }

    public render(): JSX.Element {
        if (this.state.notifications.length > 0) {
            return (
                <div className="notifications-container">
                    <Col className="container" sm={11} md={8}>
                        {this.state.notifications}
                    </Col>
                </div>
            );
        }

        return <div className="notifications-container"></div>;
    }

    public addNotification = (options: NotificationOptions): void => {
        if (!options.message && !options.messageKey) {
            return;
        }

        const nNumber = this.lastNotificationKey++;

        const toggle = options.closeButton !== false ? () => this.onDismiss(nNumber) : undefined;

        let messageValue: string | JSX.Element;
        if (options.message) {
            messageValue = options.message;
        } else {
            messageValue = (
                <Trans
                    i18nKey={options.messageKey}
                    values={options.messageKeyParams}
                    components={options.components}
                    shouldUnescape
                />
            );
        }

        const notification = (
            <Alert
                key={nNumber}
                color={options.type}
                className="alert-with-icon animated faster fadeInDown"
                toggle={toggle}
            >
                <span data-notify="icon" className={options.icon} />
                <span data-notify="message">{messageValue}</span>
            </Alert>
        );

        if (options.autoDismiss > 0) {
            const timeoutId = window.setTimeout(() => {
                this.onDismiss(nNumber);
            }, options.autoDismiss * oneSecond);
            this.setState(({ timeoutIds }) => {
                return {
                    timeoutIds: [timeoutId].concat(timeoutIds),
                };
            });
        }

        this.setState(({ notifications }) => {
            return {
                notifications: notifications.concat([notification]),
            };
        });
    };

    private onDismiss = (nNumber: number, noAnimation?: boolean): void => {
        const newNotifications: ReactElement<AlertProps>[] = [];
        const { notifications } = this.state;
        let notificationElement: ReactElement<AlertProps, string | JSXElementConstructor<any>>;

        notifications.forEach((notification) => {
            const notificationKey = +notification.key!; // Convert to number.
            if (notificationKey !== nNumber) {
                const className = notification.props.className;

                if (className && className.indexOf("fadeOutUp") !== -1) {
                    notificationElement = React.cloneElement(notification);
                } else {
                    if (!noAnimation) {
                        const animation: string = notificationKey > nNumber ? " animated faster moveUp" : "";

                        notificationElement = React.cloneElement(notification, {
                            className: "alert-with-icon" + animation,
                        });
                    } else {
                        notificationElement = React.cloneElement(notification, {
                            className: "alert-with-icon",
                        });
                    }
                }

                newNotifications.push(notificationElement);
            } else {
                if (!noAnimation) {
                    notificationElement = React.cloneElement(notification, {
                        className: "alert-with-icon animated faster fadeOutUp",
                    });
                    newNotifications.push(notificationElement);
                }
            }
        });

        if (!noAnimation) {
            const timeoutId = window.setTimeout(() => this.onDismiss(nNumber, true), dismissAfterFade);
            this.setState(({ timeoutIds }) => {
                return {
                    timeoutIds: [timeoutId].concat(timeoutIds),
                };
            });
        }

        this.setState({
            notifications: newNotifications,
        });
    };
}
