import { useCallback } from "react";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { DiscoveryModel, WopiAction } from "../../models/wopi/IWopiModel";
import { useContentFileApi } from "./ContentFileApiHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useNavigation } from "../NavigationHook";
import { contentManagerSearchParamPath } from "./ContentManagerNavigationHook";
import { contentManagerPaths } from "../../PathConstants";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { useWopi } from "../wopi/WopiHook";

interface WopiResult {
    microsoftProductItem: DiscoveryModel;
    action: WopiAction;
}

export const useOpenItem = () => {
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const { getFileDownloadUrl } = useContentFileApi(projectId);
    const { navigate } = useNavigation();
    const { wopiEnabled } = useAppConfigContext();
    const { findProduct } = useWopi();

    const getWopiFileInfo = (item: IContentEntityModel, canEdit: boolean, canRead: boolean): WopiResult | undefined => {
        if (item.type === "Folder" || !wopiEnabled) {
            return;
        }

        if (canEdit) {
            const microsoftProductItem = findProduct(item, WopiAction.Edit);
            if (microsoftProductItem) {
                return {
                    action: WopiAction.Edit,
                    microsoftProductItem,
                };
            }
        }

        if (canRead) {
            const microsoftProductItem = findProduct(item, WopiAction.View);
            if (microsoftProductItem) {
                return {
                    action: WopiAction.View,
                    microsoftProductItem,
                };
            }
        }
    };

    const gotoFolder = useCallback(
        (path: string) => {
            const searchParams = new URLSearchParams();
            searchParams.set(contentManagerSearchParamPath, path);
            navigate({
                pathname: contentManagerPaths.link.base(organizationName, projectName),
                search: searchParams.toString(),
            });
        },
        [navigate, organizationName, projectName],
    );

    const openFile = useCallback(
        async (fileId: string, fileName: string, translationLanguageCode?: string, action?: WopiAction) => {
            const uri = await getFileDownloadUrl(fileId, false, translationLanguageCode, action);

            const downloadLink = document.createElement("a");
            downloadLink.href = uri;
            downloadLink.target = "_blank";

            if (!uri.includes("/_wopi")) {
                downloadLink.download = fileName;
            }

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        [getFileDownloadUrl],
    );

    return { openFile, gotoFolder, getWopiFileInfo };
};
