import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { useUserContext } from "../../contexts/UserContext";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import {
    IFileVersioningModel,
    IVersionComment,
    IVersioningModel,
    VersionComment,
} from "../../models/versioning/VersioningModels";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { Table } from "../table/Table";
import { SortOrder } from "../../models/SortOrder";
import { defaultRequestErrorHandler, extractErrorMessage } from "../../helpers/ErrorHelper";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { useTabFirstActivation } from "../../hooks/Tabs";
import { EntityWithName, useEditEntityContext } from "../../contexts/EditEntityContext";
import { useVersionComment } from "../../hooks/versioning/VersionCommentHook";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { NotificationService } from "../../services/NotificationService";
import { FormValidator } from "../../formValidators/FormValidator";
import { formatBytes } from "../../helpers/formattingHelper";
import { VersionNumber } from "./VersionNumber";

import "./VersionsView.scss";

type EntityVersionType = "DataSet" | "File";

enum RestoreResult {
    Restored,
    Identical,
}

export interface IRestoreResult<T> {
    entity: T;
    result: RestoreResult;
}

interface VersionsViewProps<T extends EntityWithName> {
    getVersioningRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IVersioningModel>>;
    restore: (entityId: string, versionId: string, restoreComment: VersionComment) => Promise<IRestoreResult<T>>;
    updateVersionComment: (versionId: string, comment: VersionComment) => Promise<string>;
    entityId: string;
    updatedEntityDate: string;
    type: EntityVersionType;
}

const columnDefsProvider = (type: EntityVersionType): IEntityTableColumnDef[] => {
    const columnDef: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            content: (versionModel: IVersioningModel) => <VersionNumber {...versionModel} />,
            fieldName: "versions",
            displayName: "Common.Version",
            className: "version",
            testSelectorColumnName: "version",
            shouldTruncateText: true,
            sortField: { name: "Version", order: SortOrder.Asc },
        },
        {
            type: "DateTime",
            fieldName: "createdOn",
            displayName: "Common.CreatedOn",
            className: "created-on",
            testSelectorColumnName: "createdOn",
            sortField: { name: "CreatedOn", order: SortOrder.Asc },
        },
        {
            type: "Text",
            fieldName: "createdBy",
            displayName: "Common.CreatedBy",
            className: "created-by",
            testSelectorColumnName: "createdBy",
            shouldTruncateText: true,
            sortField: { name: "CreatedByUserId", order: SortOrder.Asc },
        },
        {
            type: "Text",
            content: ({ comment }: IVersioningModel) => comment ?? "-",
            fieldName: "comments",
            displayName: "Common.Comments",
            className: "comments",
            testSelectorColumnName: "comments",
            shouldTruncateText: true,
            sortField: { name: "Comment", order: SortOrder.Asc },
        },
    ];

    if (type === "File") {
        columnDef.splice(1, 0, {
            type: "JSX",
            content: ({ size }: IFileVersioningModel) => (size ? formatBytes(size) : "-"),
            fieldName: "size",
            displayName: "Common.Size",
            className: "size",
            shouldTruncateText: true,
        });
    }

    return columnDef;
};

export const VersionsView = <TEntity extends EntityWithName>({
    getVersioningRecords,
    updateVersionComment,
    entityId,
    updatedEntityDate,
    restore,
    type,
}: VersionsViewProps<TEntity>): JSX.Element => {
    const { userId } = useUserContext();
    const location = useLocation();
    const [currentUpdatedDate, setCurrentUpdatedDate] = useState<string>(updatedEntityDate);
    const isActive = location.hash.toLowerCase() === "#versions";

    const tableId = `${entityId}-versions`;

    const {
        permissionsProps: { canEdit },
        entityProps: { setEntityProperties, setInitialEntityProperties },
        dirtyProps: { isDirty },
    } = useEditEntityContext<TEntity, FormValidator<unknown>>();

    const { refreshTable } = useTableRefreshContext();
    const { showCommentModal } = useVersionComment();
    const { tabActivatedFirstTime } = useTabFirstActivation({ active: location.hash === "#versions" });
    const filterHelper = useDefaultTableFilterHelper("Version.Filter");

    const getVersioningRecordsHandler = useCallback(
        async (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const result = await getVersioningRecords(filterValue, sortFields, offset);
            return result;
        },
        [getVersioningRecords],
    );

    const onCommentClick = useCallback(
        async ({ comment, createdByUserId, versionId }: IVersioningModel) => {
            if (createdByUserId !== userId || !canEdit) {
                void showCommentModal({
                    type: "view",
                    comment: comment ?? "",
                });
                return;
            }

            const onCreate = async (entity: IVersionComment) => {
                try {
                    await updateVersionComment(versionId, entity.comment !== "" ? entity.comment : null);
                    NotificationService.addSuccessNotification({
                        messageKey: "Version.Update",
                    });
                    refreshTable(tableId);
                } catch (error) {
                    NotificationService.addErrorNotification({
                        message: extractErrorMessage(error),
                    });
                }
            };

            await showCommentModal({
                type: "edit",
                comment: comment ?? "",
                versionId: versionId,
                onCreate,
            });
        },
        [canEdit, refreshTable, showCommentModal, tableId, updateVersionComment, userId],
    );

    const onRestoreClick = useCallback(
        async (item: IVersioningModel) => {
            try {
                const restoreResult = await restore(entityId, item.versionId, null);

                if (restoreResult.result === RestoreResult.Restored) {
                    NotificationService.addSuccessNotification({
                        messageKey: "Version.Restore.Success",
                    });

                    setEntityProperties(restoreResult.entity);
                    setInitialEntityProperties(restoreResult.entity);
                } else {
                    NotificationService.addNotification({
                        type: "info",
                        icon: "fal fa-circle-info",
                        messageKey: "Version.Restore.Identical",
                        autoDismiss: NotificationService.defaultSuccessNotificationAutoDismissTimeoutInSecs,
                    });
                }
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [entityId, restore, setEntityProperties, setInitialEntityProperties],
    );

    const actionProvider = useCallback(
        (item: IVersioningModel): ITableRowActionProps[] => {
            return [
                {
                    iconClassName: "fal fa-eye",
                    label: "Version.ViewComment",
                    testSelectorValue: "editCommentItem",
                    permissions: [],
                    onClick: () => {
                        void onCommentClick(item);
                    },
                    transform: (actionProps) => {
                        if (canEdit && item.createdByUserId === userId) {
                            return {
                                ...actionProps,
                                label: "Version.EditComment",
                                iconClassName: "fal fa-pencil",
                            };
                        } else if ((item.createdByUserId !== userId || !canEdit) && !item.comment) {
                            return {
                                ...actionProps,
                                className: "disabled",
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fa fa-undo",
                    label: "Common.Restore",
                    testSelectorValue: "restoreItem",
                    permissions: [],
                    onClick: () => {
                        void onRestoreClick(item);
                    },
                    transform: (actionProps) => {
                        if (item.isCurrent || !canEdit) {
                            return null;
                        }

                        return actionProps;
                    },
                    disabled: isDirty(),
                },
            ];
        },
        [onCommentClick, canEdit, userId, onRestoreClick, isDirty],
    );

    // This useEffect will update the currentUpdatedDate if changes are made to the dataset BEFORE the versionsTable component is rendered to prevent triggering an update API call.
    useEffect(() => {
        if (!tabActivatedFirstTime) {
            setCurrentUpdatedDate(updatedEntityDate);
        }
    }, [tabActivatedFirstTime, updatedEntityDate]);

    useEffect(() => {
        if (isActive && currentUpdatedDate !== updatedEntityDate) {
            setCurrentUpdatedDate(updatedEntityDate);
            refreshTable(tableId);
        }
    }, [currentUpdatedDate, isActive, refreshTable, tableId, updatedEntityDate]);

    const columnDefs = useMemo(() => columnDefsProvider(type), [type]);

    return (
        <>
            {tabActivatedFirstTime && (
                <Table
                    className="versioning-table"
                    actionProvider={actionProvider}
                    columnDefs={columnDefs}
                    getRecords={getVersioningRecordsHandler}
                    keyExtractor={({ versionId }: IVersioningModel) => versionId}
                    loadingMessageKey="Version.Loading"
                    tableId={tableId}
                    filterHelper={filterHelper}
                    defaultSortField={{
                        name: "Version",
                        order: SortOrder.Desc,
                    }}
                    counterPosition="Start"
                />
            )}
        </>
    );
};
