import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, UncontrolledAccordion } from "reactstrap";
import { useAtomValue } from "jotai";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { featuresStateAtom, useLicenseFeatures } from "../../../hooks/license/LicenseFeatureHook";
import { ILicenseFeatures } from "../../../models/license/ILicenseFeatureModel";
import { IOrganizationModel, OrganizationLicenseState } from "../../../models/organization/IOrganizationModel";
import { OrganizationFormValidator } from "../../../formValidators/OrganizationFormValidator";
import { LoadingAnimation } from "../../loading/LoadingAnimation";
import { TemplatesFeature } from "./features/TemplatesFeature";
import { DataSetsFeature } from "./features/DataSetsFeature";
import { ContentFeature } from "./features/ContentFeature";
import { GlobalFeature } from "./features/GlobalFeature";
import { licenseSubFeaturesTranslationMap } from "../../../helpers/LicensingHelper";

import "./LicenseFeaturesCard.scss";

export interface FeatureContent {
    name: string;
    content: JSX.Element;
    enabled: boolean;
}

const noFeatures: ILicenseFeatures = {
    contentFeature: {
        enabled: false,
        aiDocumentTranslation: false,
        explicitPermissionsManagement: false,
        officeForTheWeb: false,
        sharing: false,
    },
    dataSetsFeature: {
        enabled: false,
        form: false,
        json: false,
        xml: false,
        aiFormAssistant: false,
    },
    globalFeature: {
        maxProjects: 0,
        maxStorage: "0",
    },
    templatesFeature: {
        enabled: false,
        word: false,
    },
};

const getDefaultOpen = ({ contentFeature, dataSetsFeature, templatesFeature }: ILicenseFeatures) => {
    const enabledFeatures = [];

    if (contentFeature.enabled) {
        enabledFeatures.push("contentFeature");
    }

    if (dataSetsFeature.enabled) {
        enabledFeatures.push("dataSetsFeature");
    }

    if (templatesFeature.enabled) {
        enabledFeatures.push("templatesFeature");
    }

    return enabledFeatures;
};

export const LicenseFeaturesCard: React.FC = () => {
    const { t } = useTranslation();
    const {
        entityProps: { entity: organization },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();
    const featuresState = useAtomValue(featuresStateAtom);

    const features = featuresState.features ?? noFeatures;

    useLicenseFeatures(organization.organizationId);

    const renderContent = useMemo(() => {
        if (featuresState.isLoadingFeatures) {
            return (
                <div className="license-features__loading">
                    <LoadingAnimation size={24} />
                    <span>{t("Licensing.Features.Loading")}</span>
                </div>
            );
        }

        if (organization.licenseState !== OrganizationLicenseState.Active) {
            return (
                <div className="license-features__no-active-license">
                    <span>{t("Licensing.Features.NoActiveLicenseFound")}</span>
                    <span>{t("Licensing.Features.PleaseActivateLicense")}</span>
                </div>
            );
        }

        return (
            <UncontrolledAccordion
                flush
                stayOpen
                defaultOpen={getDefaultOpen(features)}
                toggle={() => {
                    return;
                }}
            >
                <GlobalFeature
                    id="maxProjects"
                    featureName={t(licenseSubFeaturesTranslationMap.maxProjects, {
                        value: features.globalFeature.maxProjects,
                    })}
                />
                <GlobalFeature
                    id="maxStorage"
                    featureName={t(licenseSubFeaturesTranslationMap.maxStorage, {
                        value: features.globalFeature.maxStorage,
                    })}
                />
                <DataSetsFeature {...features.dataSetsFeature} />
                <ContentFeature {...features.contentFeature} />
                <TemplatesFeature {...features.templatesFeature} />
            </UncontrolledAccordion>
        );
    }, [features, featuresState.isLoadingFeatures, organization.licenseState, t]);

    return (
        <Card className="license-features">
            <CardHeader>
                <span>{t("Licensing.Features")}</span>
            </CardHeader>
            <CardBody>{renderContent}</CardBody>
        </Card>
    );
};
