import React, { useContext } from "react";
import { useContentManagerNavigation } from "../hooks/contentManager/ContentManagerNavigationHook";

type ContentManagerNavigationContextProps = ReturnType<typeof useContentManagerNavigation>;

/**
 * The ContentManager Navigation context.
 */
export const ContentManagerNavigationContext = React.createContext<ReturnType<
    typeof useContentManagerNavigation
> | null>(null);

/**
 * The hook that uses the content manager navigation context.
 */
export const useContentManagerNavigationContext = (): ContentManagerNavigationContextProps =>
    useContext(ContentManagerNavigationContext) as ContentManagerNavigationContextProps;
