import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { ObjectHelper } from "../../helpers/ObjectHelper";
import { TemplateFormValidator } from "../../formValidators/TemplateFormValidator";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { EditTemplateViewTabs } from "./EditTemplateViewTabs";
import { EditTemplateCustomDropdownMenu } from "./EditTemplateCustomDropdownMenu";
import { LoadingScreen } from "../loading/LoadingScreen";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { useEntityReloadByLocationPath } from "../../hooks/dataSet/EntityReloadHook";
import { useEditTemplate } from "../../hooks/template/EditTemplateHook";
import { EntityDependencyDeleteDialog } from "../dialogs/EntityDependencyDeleteDialog";
import { EditEntityName } from "../edit/EditEntityName";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { EditTemplateDesignButton } from "./EditTemplateDesignButton";

import "./EditTemplateView.scss";

/**
 * The edit template view component.
 */
export const EditTemplateView: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [loadedTemplate, setLoadedTemplate] = useState<ITemplateModel | undefined>();
    const {
        getDependencies,
        isAllowed,
        loadTemplate,
        nameIsUnique,
        permissionKeys: { templateDeleteKey, templateWriteKey, languageReadKey },
        saveTemplate,
        removeTemplate,
    } = useEditTemplate();

    const loadingScreenKey = "Template.Loading.Ellipsis";

    const formValidatorProvider = useCallback(() => new TemplateFormValidator(), []);

    const { entityReloadByLocationPath } = useEntityReloadByLocationPath();

    useEffect(() => {
        void loadTemplate().then((template) => {
            setLoadedTemplate(template);
        });
    }, [loadTemplate]);

    useEffect(() => {
        if (entityReloadByLocationPath) {
            setLoadedTemplate(undefined);
            void loadTemplate().then((template) => {
                setLoadedTemplate(template);
            });
        }
    }, [entityReloadByLocationPath, loadTemplate]);

    const canDelete = useMemo(() => isAllowed(templateDeleteKey), [isAllowed, templateDeleteKey]);
    const canEdit = useMemo(() => isAllowed(templateWriteKey), [isAllowed, templateWriteKey]);
    const canDesign = useMemo(() => isAllowed(languageReadKey) && canEdit, [canEdit, isAllowed, languageReadKey]);

    if (!loadedTemplate) {
        return <LoadingScreen>{t(loadingScreenKey)}</LoadingScreen>;
    }

    return (
        <EditEntityWrapper
            className="edit-template-view"
            loadedEntity={loadedTemplate}
            nameIsUniqueProps={{
                nameExistErrorKey: "Template.NameAlreadyExists",
                nameIsUnique,
            }}
            load={loadTemplate}
            promptDialogMessageKey="Template.UnsavedWarning"
            formValidatorProvider={formValidatorProvider}
            loadingScreenKey={loadingScreenKey}
            update={saveTemplate}
            deleteDialogProps={{
                dialogBodyKey: "Template.DeleteConfirmation",
                dialogTitleKey: "Template.Delete",
                entityCount: 1,
            }}
            delete={removeTemplate}
            propertiesComparator={{
                description: ObjectHelper.isNullableStringEqual,
                dataSetIds: (original: string[], current: string[]) => {
                    return isEqual(original, current);
                },
            }}
            canEdit={canEdit}
            canDelete={canDelete}
            entityType="Template"
            getDependencies={getDependencies}
            customDeleteComponent={EntityDependencyDeleteDialog}
            entityId={loadedTemplate.templateId}
            header={
                <SectionHeader>
                    <SectionHeader.Group flexGrow>
                        <SectionHeader.Icon type="Template" />
                        <SectionHeader.Group column>
                            <SectionHeader.OverTitle>
                                {t("Template.TemplateType", { templateType: t("Common.Standard") })}
                            </SectionHeader.OverTitle>
                            <EditEntityName />
                        </SectionHeader.Group>
                    </SectionHeader.Group>
                    <SectionHeader.ActionGroup
                        withSaveButton={canEdit}
                        dropdownMenu={<EditTemplateCustomDropdownMenu canDelete={canDelete} canEdit={canEdit} />}
                    >
                        {canDesign && <EditTemplateDesignButton loadedTemplate={loadedTemplate} />}
                    </SectionHeader.ActionGroup>
                </SectionHeader>
            }
        >
            <EditTemplateViewTabs />
        </EditEntityWrapper>
    );
};
