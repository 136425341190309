import React, { useEffect, useMemo, useRef } from "react";
import { FormGroup, Input } from "reactstrap";
import { useTranslateSelectComponentOptions } from "../../hooks/TranslateSelectComponentOptionsHook";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useDataSetValidation } from "../../hooks/dataSet/DataSetValidation";
import { IDataSetFormFields } from "../../models/dataSets/IDataSetFormField";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { EntityForm } from "../forms/EntityForm";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponentDataSetOptions } from "./SelectComponentDataSetOptions";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DataSetType } from "../../models/dataSets/DataSetType";
import { SelectDataSetType } from "./SelectDataSetType";
import { useFeatureCheck } from "../../hooks/license/LicenseCheckHook";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { DataSetTypeToFeature } from "../../models/license/DataSetTypeToFeature";

type FormMode = "Create" | "Clone";

interface ICreateDataSetFormProps {
    initialValue: IDataSetFormFields;
    onFormChange: (formData: IEntityFormData<IDataSetFormFields>) => void;
    mode: FormMode;
}

const removeDataSetType = (options: SelectComponentOptions<DataSetType>[], type: string) => {
    const index = options.findIndex((o) => o.value === type);
    options.splice(index, 1);
    return options;
};

const getInitialType = (
    type: DataSetType,
    checkFeature: ReturnType<typeof useFeatureCheck>["checkFeature"],
): DataSetType => {
    if (checkFeature("dataSetsFeature", DataSetTypeToFeature[type])) {
        return type;
    }

    return (
        (Object.keys(DataSetTypeToFeature).find((key) =>
            checkFeature("dataSetsFeature", DataSetTypeToFeature[key as keyof typeof DataSetTypeToFeature]),
        ) as DataSetType) ?? "System.Form"
    );
};

export const CreateDataSetForm: React.FC<ICreateDataSetFormProps> = ({
    initialValue,
    onFormChange,
    mode,
}: ICreateDataSetFormProps) => {
    const { checkFeature } = useFeatureCheck();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: {
            ...initialValue,
            // Need to make sure that the initial type is enabled in the license
            type: mode === "Clone" ? initialValue.type : getInitialType(initialValue.type, checkFeature),
        },
        onFormChange,
        useValidation: useDataSetValidation,
        isDirty: mode === "Clone" ? () => true : undefined,
    });

    const handleOnFocus = () => {
        inputRef.current?.focus();
        inputRef.current?.select();
    };

    useEffect(() => {
        mode === "Clone" && handleOnFocus();
    }, [mode]);

    const translatedSelectComponentOptions = useTranslateSelectComponentOptions(SelectComponentDataSetOptions);
    const selectComponentOptions = useMemo(() => {
        if (mode === "Clone") {
            return [translatedSelectComponentOptions.find((option) => option.value === data.type)!];
        }

        let options = [...translatedSelectComponentOptions];
        let type: DataSetType;
        for (type in DataSetTypeToFeature) {
            if (!checkFeature("dataSetsFeature", DataSetTypeToFeature[type])) {
                options = removeDataSetType(options, type);
            }
        }

        if (options.length === 0) {
            // If there are no options available, set the form dataset as default and let the action fail
            // so that user knows that his license does not allow to create any dataset
            options.push(translatedSelectComponentOptions.find((option) => option.value === "System.Form")!);
        }

        return options;
    }, [data.type, mode, translatedSelectComponentOptions, checkFeature]);

    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-dataset" className="createDataSetForm">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-dataset-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-dataset-name"
                    innerRef={inputRef}
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    {...setSelector("nameTextbox")}
                    onChange={(e) => onChange("name", e.target.value)}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    value={data.name}
                />
            </FormGroup>
            {mode === "Clone" && (
                <FormGroup check>
                    <Input
                        type="checkbox"
                        id="includes-samples-checkbox"
                        checked={!!data.includeSamples}
                        {...setSelector("includeSamplesCheckbox")}
                        onChange={() => onChange("includeSamples", !data.includeSamples)}
                    />
                    <LocalizedLabel check for="includes-samples-checkbox" text="Common.IncludeSamples" />
                </FormGroup>
            )}
            <FormGroup>
                <LocalizedLabel for="create-description" text="Common.Description" />
                <ValidatedInput
                    id="create-description"
                    autoComplete="off"
                    name="description"
                    type="textarea"
                    {...setSelector("descriptionTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                    error={errors.description}
                    value={data.description}
                />
            </FormGroup>
            <FormGroup className="col-lg-6 p-0">
                <SelectDataSetType
                    value={data.type}
                    options={selectComponentOptions}
                    onChange={(value: DataSetType) => onChange("type", value)}
                />
            </FormGroup>
        </EntityForm>
    );
};
