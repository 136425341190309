import React, { useCallback, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Collapse } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { ArrowToggleButton } from "../../buttons/ArrowToggleButton";
import { InheritanceSource, IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { SelectPermission, selectPermissionsOptions } from "../../selects/SelectPermissionComponent";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../../models/permissions/IEditPermissionGroupModel";
import { PermissionAccess } from "../../../models/permissions/PermissionAccess";
import { AlertPopover } from "../../language/AlertPopover";
import { useCurrentEntitiesNameContext } from "../../../contexts/CurrentEntitiesNameContext";

import "./PermissionSection.scss";

interface IPermissionSectionProps {
    handlePermissionChange: (permissions: IPermissionModel[]) => void;
    title: string;
    permissions: IPermissionModel[];
    scope: string;
    errors?: Record<string, string>;
}

export const PermissionSection: React.FC<IPermissionSectionProps> = ({
    handlePermissionChange,
    title,
    permissions,
    scope,
    errors,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const {
        permissionsProps: { canEdit },
    } = useEditEntityContext<IEditPermissionGroupModel, any>();

    const sortedPermissions = useMemo(() => {
        return [...permissions].sort((a, b) => a.name.localeCompare(b.name));
    }, [permissions]);

    const { t } = useTranslation();

    const handleChange = useCallback(
        (value: PermissionAccess, name: string) => {
            const newPermissions = cloneDeep(permissions);
            const p = newPermissions.find((np) => np.name === name);
            p!.value = value;
            if (p!.inheritedFromId) {
                p!.inheritedFromId = undefined;
            }

            handlePermissionChange(newPermissions);
        },
        [handlePermissionChange, permissions],
    );

    const { getEntityName } = useCurrentEntitiesNameContext();

    const getInheritSourceName = useCallback(
        (inheritSource: InheritanceSource, id: string) => {
            if (inheritSource === "Permissions.Group") {
                return t("Route.Settings.Project");
            }

            return getEntityName("Folder", id);
        },
        [getEntityName, t],
    );

    return (
        <div className="permission-section">
            <ArrowToggleButton
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((currentIsOpen) => !currentIsOpen);
                }}
                buttonText={title}
                className="permission-section__collapse-label"
            />
            <Collapse isOpen={isOpen}>
                {sortedPermissions.map(({ name, labelKey, value, readonly, inheritedFromId, inheritSource }, index) => {
                    const onChange = (val?: PermissionAccess) => handleChange(val!, name);

                    const error = errors?.[`${scope}/${name}`];
                    let errorMessage: JSX.Element | undefined;

                    if (error) {
                        const [errorEntityId, errorSource] = error.split(":");

                        const messageObject = {
                            i18nKey: "Permission.InheritDenyError",
                            values: {
                                name: getInheritSourceName(errorSource as InheritanceSource, errorEntityId),
                                currentpermission: t(
                                    selectPermissionsOptions.find((spo) => spo.value === value)?.label ?? "",
                                ),
                            },
                        };

                        errorMessage = <Trans {...messageObject} />;
                    }

                    const inheritedName = inheritedFromId && getInheritSourceName(inheritSource!, inheritedFromId);

                    return (
                        <div key={name} className="permission-section__row">
                            <SelectPermission
                                onChange={onChange}
                                value={value}
                                readonly={!canEdit || readonly}
                                label={t(labelKey)}
                                error={errorMessage}
                                inherited={!!inheritedFromId}
                            />
                            {inheritedName && (
                                <AlertPopover
                                    messageKey="Permission.InheritAlert"
                                    messageParams={{ name: inheritedName }}
                                    alertId={`${inheritedFromId}${index}`}
                                />
                            )}
                        </div>
                    );
                })}
            </Collapse>
        </div>
    );
};
