import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PermissionSection } from "./PermissionSection";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../../models/permissions/IEditPermissionGroupModel";
import { IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { IGroupPermissionModel } from "../../../models/permissions/IGroupPermissionModel";
import { PermissionGroupFormValidator } from "../../../formValidators/PermissionGroupFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { isContentPermissionGroupFormValidator } from "../../../formValidators/ContentPermissionGroupFormValidator";

import "./ManageGroupPermissions.scss";

export const ManageGroupPermissions: React.FC = () => {
    const {
        entityProps: {
            entity: { groupPermissions },
            setEntityProperties,
            formValidator,
        },
        errorProps: {
            errors: { groupPermissions: groupPermissionsErrors },
            setErrors,
        },
    } = useEditEntityContext<IEditPermissionGroupModel, PermissionGroupFormValidator<IEditPermissionGroupModel>>();

    const { t } = useTranslation();

    const handlePermissionsChange = useCallback(
        (scope: string, permissions: IPermissionModel[]) => {
            const groupIndex = groupPermissions.findIndex((gp) => gp.scope === scope);

            const modifiedGroup: IGroupPermissionModel = { ...groupPermissions[groupIndex], permissions };

            const newGroupPermissions = [...groupPermissions];
            newGroupPermissions.splice(groupIndex, 1, modifiedGroup);

            void (async () => {
                let errorMessage: string = "";

                try {
                    setEntityProperties({ groupPermissions: newGroupPermissions });
                    if (isContentPermissionGroupFormValidator(formValidator)) {
                        await formValidator.validateGroupPermissions(newGroupPermissions);
                    }
                } catch (e) {
                    errorMessage = extractErrorMessageOrEmptyString(e);
                }

                setErrors({ groupPermissions: errorMessage });
            })();
        },
        [formValidator, groupPermissions, setEntityProperties, setErrors],
    );

    const errors = useMemo(() => {
        if (groupPermissionsErrors) {
            try {
                return JSON.parse(groupPermissionsErrors) as Record<string, string>;
            } catch {
                return;
            }
        }
    }, [groupPermissionsErrors]);

    return (
        <div className="groups-permissions-container">
            {groupPermissions.map(({ scope, labelKey, permissions }) => {
                const handlePermissionChange = (newPermissions: IPermissionModel[]) =>
                    handlePermissionsChange(scope, newPermissions);
                return (
                    <PermissionSection
                        handlePermissionChange={handlePermissionChange}
                        key={scope}
                        scope={scope}
                        title={t(labelKey)}
                        permissions={permissions}
                        errors={errors}
                    />
                );
            })}
        </div>
    );
};
