import { useCallback } from "react";
import { useHttpRequest } from "../HttpRequestHook";
import { useCancellableRequest } from "../CancellableRequestHook";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { IOrganizationFormFields } from "../../models/organization/IOrganizationFormFields";
import { ISidebarOrganizationsModel } from "../../models/organization/ISidebarOrganizationsModel";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IUserWithGroupsAndInvitationStatusModel } from "../../models/user/IUserWithGroupsAndInvitationStatusModel";
import { ICreateUsersInvitationModel } from "../../models/user/invitations/ICreateUsersInvitationModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { IOrganizationWithProjectCountModel } from "../../models/organization/IOrganizationWithProjectCountModel";
import { IProjectModel } from "../../models/project/IProjectModel";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { IUpdateOrganizationModel } from "../../models/organization/IUpdateOrganizationModel";
import { ILicenseActivationResultModel } from "../../models/organization/ILicenseActivationResultModel";
import { ILicenseFeatures } from "../../models/license/ILicenseFeatureModel";

interface OrganizationApiHookReturn {
    create: (organization: IOrganizationFormFields) => Promise<IOrganizationModel>;
    remove: (organizationId: string) => Promise<void>;
    removeRecords: (ids: string[]) => Promise<IDeleteErrorResult[]>;
    getSidebarOrganizations: () => Promise<ISidebarOrganizationsModel>;
    get: (organizationId: string) => Promise<IOrganizationModel>;
    getCounts: () => Promise<number>;
    getProjectRecords: (
        organizationId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IProjectModel>>;
    getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
        take?: number,
    ) => Promise<IEntityResult<IOrganizationWithProjectCountModel>>;
    getUsers: (
        id: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>;
    inviteUsers: (createUsersInvitationModel: ICreateUsersInvitationModel, organizationId: string) => Promise<number>;
    nameIsUnique: (name: string) => Promise<boolean>;
    update: (organization: IUpdateOrganizationModel) => Promise<IOrganizationModel>;
    removeUsers: (ids: string[], organizationId: string) => Promise<IDeleteErrorResult[]>;
    validateEmailsUniqueness: (emails: string[], organizationId: string) => Promise<string[]>;
    activateLicense: (organizationId: string, licenseKey: string) => Promise<ILicenseActivationResultModel>;
    getLicenseFeatures: (organizationId: string) => Promise<ILicenseFeatures>;
}

const baseUrl = (organizationId?: string) => `/_api/organizations${organizationId ? "/" + organizationId : ""}`;

const getProjectRecordsRequestId = "getProjetsRecords";
const getOrganizationUsersRequestId = "getOrganizationUsers";

export const useOrganizationApi = (): OrganizationApiHookReturn => {
    const { httpGetJson, httpPost, httpPut, httpDelete, httpDeleteResponseJson } = useHttpRequest();

    const { cancellableRequest } = useCancellableRequest();

    const create = useCallback(
        (organization: IOrganizationFormFields) =>
            cancellableRequest<IOrganizationModel, IOrganizationFormFields>(
                { url: baseUrl(), body: organization },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const getSidebarOrganizations = useCallback(
        () => cancellableRequest<ISidebarOrganizationsModel>({ url: `${baseUrl()}/sidebar` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const get = useCallback(
        (organizationId: string) =>
            cancellableRequest<IOrganizationModel>({ url: `${baseUrl(organizationId)}` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getCounts = useCallback(
        () => cancellableRequest<number>({ url: `${baseUrl()}/count` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getProjectRecords = useCallback(
        (organizationId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Description = filterValue;
            }

            return cancellableRequest<IEntityResult<IProjectModel>>(
                {
                    url: `${baseUrl(organizationId)}/projects`,
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
                getProjectRecordsRequestId,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const getRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number, take?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Description = filterValue;
            }

            return cancellableRequest<IEntityResult<IOrganizationWithProjectCountModel>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                        take,
                    }),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const getUsers = useCallback(
        (organizationId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Email = filterValue;
            }

            return cancellableRequest<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>(
                {
                    url: `${baseUrl(organizationId)}/users`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                        },
                        false,
                    ),
                },
                httpGetJson,
                getOrganizationUsersRequestId,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const inviteUsers = useCallback(
        (createUsersInvitationModel: ICreateUsersInvitationModel, organizationId: string) =>
            cancellableRequest<number, ICreateUsersInvitationModel>(
                { url: `${baseUrl(organizationId)}/invite`, body: createUsersInvitationModel },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const nameIsUnique = useCallback(
        (name: string) =>
            cancellableRequest<boolean>({ url: `${baseUrl()}/nameisunique`, queryParams: { name } }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const remove = useCallback(
        (organizationId: string) => cancellableRequest({ url: `${baseUrl(organizationId)}` }, httpDelete),
        [httpDelete, cancellableRequest],
    );

    const removeRecords = useCallback(
        (ids: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>({ url: baseUrl(), body: ids }, httpDeleteResponseJson),
        [cancellableRequest, httpDeleteResponseJson],
    );

    const removeUsers = useCallback(
        (ids: string[], organizationId: string) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: `${baseUrl(organizationId)}/removeUsers`, body: ids },
                httpDeleteResponseJson,
            ),
        [httpDeleteResponseJson, cancellableRequest],
    );

    const update = useCallback(
        (organization: IUpdateOrganizationModel) =>
            cancellableRequest<IOrganizationModel, IUpdateOrganizationModel>(
                { url: baseUrl(organization.organizationId), body: organization },
                httpPut,
            ),
        [httpPut, cancellableRequest],
    );

    const validateEmailsUniqueness = useCallback(
        (emails: string[], organizationId: string) =>
            cancellableRequest<string[], string[]>(
                { url: `${baseUrl(organizationId)}/emailsareunique`, body: emails },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const activateLicense = useCallback(
        (organizationId: string, licenseKey: string): Promise<ILicenseActivationResultModel> =>
            cancellableRequest<ILicenseActivationResultModel, string>(
                { url: `${baseUrl(organizationId)}/license/activate`, body: licenseKey },
                httpPut,
            ),
        [cancellableRequest, httpPut],
    );

    const getLicenseFeatures = useCallback(
        (organizationId: string): Promise<ILicenseFeatures> =>
            // Keep the same request ID to cancel old requests and process only the latest one.
            cancellableRequest<ILicenseFeatures>({ url: `${baseUrl(organizationId)}/license/features` }, httpGetJson, "request_get_license_features"),
        [cancellableRequest, httpGetJson],
    );

    return {
        create,
        remove,
        removeRecords,
        getSidebarOrganizations: getSidebarOrganizations,
        get,
        getCounts,
        getProjectRecords,
        getRecords,
        getUsers,
        inviteUsers,
        nameIsUnique,
        update,
        removeUsers,
        validateEmailsUniqueness,
        activateLicense,
        getLicenseFeatures,
    };
};
