import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EditServiceHookViewTabs } from "./EditServiceHookViewTabs";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { useProjectContext } from "../../contexts/ProjectContext";
import { ServiceHookFormValidator } from "../../formValidators/ServiceHookFormValidator";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { NotificationService } from "../../services/NotificationService";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { serviceHooksPaths } from "../../PathConstants";
import { useNavigation } from "../../hooks/NavigationHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { useServiceHookApi } from "../../hooks/serviceHook/ServiceHookApiHook";
import { ReturnSection } from "../layout/ReturnSection";
import { EditEntityName } from "../edit/EditEntityName";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";

import "./EditServiceHookView.scss";

export const EditServiceHookView: React.FC<{}> = () => {
    const { t } = useTranslation();
    const { serviceHookId } = useParams() as Record<string, string>;
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const { replace } = useNavigation();

    const { get, nameIsUnique, remove, update: updateServiceHook } = useServiceHookApi(projectId);

    const serviceHookWriteKey = usePermissionKey({
        permission: PermissionKeys.serviceHook.write,
        projectId,
        objectId: serviceHookId,
    });
    const serviceHookDeleteKey = usePermissionKey({
        permission: PermissionKeys.serviceHook.delete,
        projectId,
        objectId: serviceHookId,
    });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [serviceHookWriteKey, serviceHookDeleteKey] }),
        [serviceHookWriteKey, serviceHookDeleteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const load = useCallback(async () => {
        try {
            return await get(serviceHookId);
        } catch (error) {
            defaultRequestErrorHandler(error);
            replace(serviceHooksPaths.link.base(organizationName, projectName), { state: { loadError: true } });
            return {} as IServiceHookModel;
        }
    }, [get, organizationName, projectName, replace, serviceHookId]);

    const update = useCallback(
        async (serviceHook: IServiceHookModel) => {
            const updatedServiceHook = await updateServiceHook(serviceHookId, serviceHook);
            NotificationService.addSuccessNotification({
                messageKey: "ServiceHook.SaveSuccess",
                messageKeyParams: { name: updatedServiceHook.name },
            });
            return updatedServiceHook;
        },
        [updateServiceHook, serviceHookId],
    );

    const deleteHandler = useCallback(
        async (serviceHookName: string) => {
            try {
                await remove(serviceHookId);

                NotificationService.addSuccessNotification({
                    messageKey: "ServiceHookView.DeleteSuccessSingle",
                    messageKeyParams: { name: serviceHookName },
                });
                replace(serviceHooksPaths.link.base(organizationName, projectName), {
                    state: { deleted: true },
                });
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [remove, projectName, replace, serviceHookId, organizationName],
    );

    const canEdit = isAllowed(serviceHookWriteKey);
    const canDelete = isAllowed(serviceHookDeleteKey);

    return (
        <>
            <ReturnSection
                label={t("CommonServiceHook.Return")}
                route={serviceHooksPaths.link.base(organizationName, projectName)}
                testSelectorValue="returnToServiceHooksLink"
            />
            <EditEntityWrapper
                className="edit-service-hook-view"
                delete={deleteHandler}
                nameIsUniqueProps={{
                    nameExistErrorKey: "ServiceHookValidator.NameExistsForProject",
                    nameIsUnique,
                }}
                deleteDialogProps={{
                    dialogTitleKey: "CommonServiceHook.DeleteTitle",
                    dialogBodyKey: "CommonServiceHook.DeleteBody",
                    entityCount: 1,
                }}
                promptDialogMessageKey="ServiceHook.UnsavedWarningBody"
                formValidatorProvider={() => new ServiceHookFormValidator()}
                load={load}
                loadingScreenKey="ServiceHook.Loading"
                update={update}
                canEdit={canEdit}
                canDelete={canDelete}
                entityType="ServiceHook"
                header={
                    <SectionHeader>
                        <SectionHeader.Group flexGrow>
                            <SectionHeader.Icon type="ServiceHook" />
                            <SectionHeader.Group column>
                                <SectionHeader.OverTitle>web hook</SectionHeader.OverTitle>
                                <EditEntityName />
                            </SectionHeader.Group>
                        </SectionHeader.Group>
                        <SectionHeader.ActionGroup
                            withSaveButton={canEdit}
                            dropdownMenu={<DefaultEditDropdownMenu canDelete={canDelete} />}
                        />
                    </SectionHeader>
                }
            >
                <EditServiceHookViewTabs />
            </EditEntityWrapper>
        </>
    );
};
