import { IGroupPermissionDataModel } from "../../../models/permissions/IGroupPermissionDataModel";
import { IGroupPermissionModel } from "../../../models/permissions/IGroupPermissionModel";
import { IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { PermissionAccess } from "../../../models/permissions/PermissionAccess";

interface IUseGroupPermissionDataResult {
    extract: (groupPermissionsData: IGroupPermissionDataModel[]) => IGroupPermissionModel[];
    serialize: (groupPermissions: IGroupPermissionModel[]) => IGroupPermissionDataModel[];
}

const entityResourceKeyMap = new Map<string, string>([
    ["organization", "EntityType.Organization"],
    ["project", "EntityType.Project"],
    ["dataset", "Common.DataSet"],
    ["template", "Common.Template"],
    ["servicehook", "EntityType.ServiceHook"],
    ["language", "EntityType.Language"],
    ["content", "Common.Content"],
    ["app", "Common.Application"],
]);

export const permissionResourceKeyMap = new Map<string, string>([
    ["app/manageSettings", "Permissions.App.ManageSettings"],
    ["organization/read", "Permissions.Organization.Read"],
    ["organization/write", "Permissions.Organization.Write"],
    ["organization/delete", "Permissions.Organization.Delete"],
    ["project/read", "Permissions.Project.Read"],
    ["project/write", "Permissions.Project.Write"],
    ["project/delete", "Permissions.Project.Delete"],
    ["project/managePermissions", "Permissions.Project.ManagePermissions"],
    ["project/import", "Permissions.Project.Import"],
    ["project/export", "Permissions.Project.Export"],
    ["content/read", "Permissions.Content.Read"],
    ["content/write", "Permissions.Content.Write"],
    ["content/delete", "Permissions.Content.Delete"],
    ["content/managePermissions", "Permissions.Content.ManagePermissions"],
    ["content/share", "Permissions.Content.Share"],
    ["dataset/read", "Permission.DataSet.Read"],
    ["dataset/write", "Permission.DataSet.Write"],
    ["dataset/delete", "Permission.DataSet.Delete"],
    ["dataset/managePermissions", "Permission.DateSet.ManagePermissions"],
    ["language/read", "Permissions.Language.Read"],
    ["language/write", "Permissions.Language.Write"],
    ["language/delete", "Permissions.Language.Delete"],
    ["servicehook/read", "Permissions.ServiceHook.Read"],
    ["servicehook/write", "Permissions.ServiceHook.Write"],
    ["servicehook/delete", "Permissions.ServiceHook.Delete"],
    ["template/read", "Permission.Template.Read"],
    ["template/write", "Permission.Template.Write"],
    ["template/delete", "Permission.Template.Delete"],
    ["template/managePermissions", "Permission.Template.ManagePermissions"],
]);

// if true, contains the scope and permissions, if false contains nothing.
type ExtractPermissionType = [true, string, IPermissionModel] | [false];

const extractPermission = (permissionData: IGroupPermissionDataModel): ExtractPermissionType => {
    const permissionLabelKey = permissionResourceKeyMap.get(permissionData.permission);

    if (!permissionLabelKey) {
        // eslint-disable-next-line no-console
        console.warn(
            `Missing translation key for '${permissionData.permission}' in translation map. this permission was skipped and isn't displayed`,
        );

        return [false];
    }

    const [scope, key] = permissionData.permission.split("/");

    const permission: IPermissionModel = {
        name: key,
        labelKey: permissionLabelKey,
        value: permissionData.accessType ?? PermissionAccess.NotSet,
        readonly: permissionData.isSystem,
    };

    const inheritedFromId = permissionData.inheritedFromContentId
        ? permissionData.inheritedFromContentId
        : permissionData.inheritedFromGroupId;

    if (inheritedFromId) {
        permission.inheritedFromId = inheritedFromId;
        permission.inheritSource = permissionData.inheritedFromContentId ? "Folder" : "Permissions.Group";
    }

    return [true, scope, permission];
};

/**
 * extract the groups and split them into scope
 * @param groupPermissionsData the group permission back end data.
 * @returns a GroupPermissionModel array containing the permissions split by scope
 */
const extractGroupPermission = (groupPermissionsData: IGroupPermissionDataModel[]): IGroupPermissionModel[] => {
    // takes in an array of group permission data, formats it and groups it by scope.
    return groupPermissionsData.reduce<IGroupPermissionModel[]>((acc, gpd) => {
        // format the permission and extract the scope
        const [valid, scope, permission] = extractPermission(gpd);

        if (!valid) {
            return acc;
        }

        // search the final object (acc) for the current scope
        let groupPermission = acc.find((p) => p.scope === scope);
        if (groupPermission) {
            groupPermission.permissions.push(permission);
        } else {
            // if the scope object doesn't exist yet, create it and push in the final object (acc)
            groupPermission = {
                scope,
                labelKey: entityResourceKeyMap.get(scope)!,
                permissions: [permission],
            };

            acc.push(groupPermission);
        }

        return acc;
    }, []);
};

const serializeGroupPermission = (groupPermissions: IGroupPermissionModel[]): IGroupPermissionDataModel[] => {
    return groupPermissions.reduce<IGroupPermissionDataModel[]>((acc, { scope, permissions }) => {
        return [
            ...acc,
            ...permissions.map<IGroupPermissionDataModel>(
                ({ value, readonly, name, inheritedFromId, inheritSource }) => {
                    const permissionData: IGroupPermissionDataModel = {
                        accessType: value === PermissionAccess.NotSet ? null : value,
                        isSystem: readonly,
                        permission: `${scope}/${name}`,
                    };
                    if (inheritedFromId) {
                        permissionData[inheritSource === "Folder" ? "inheritedFromContentId" : "inheritedFromGroupId"] =
                            inheritedFromId;
                    }

                    return permissionData;
                },
            ),
        ];
    }, []);
};

export const useGroupPermissionData = (): IUseGroupPermissionDataResult => {
    return { extract: extractGroupPermission, serialize: serializeGroupPermission };
};
