import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { foldersTreeAtom } from "../../../atoms/ContentManager";

/**
 * returns a function that gets the current state of the folders tree without creating a direct dependency on the atom.
 *
 * use this hook when you need to access the folders tree state in a callback function, but you don't want your component to re-render when the folders tree state changes.
 */
export const useFolderTreeAccessor = () => {
    const getFoldersTree = useAtomCallback(useCallback((get) => get(foldersTreeAtom), []));

    return { getFoldersTree };
};
