import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { IUserGroupModel } from "../../models/user/IUserGroupModel";
import { IPermissionsGroupWithAccessInfoModel } from "../../models/permissions/IPermissionsGroupWithAccessInfoModel";
import { IGroupPermissionModel } from "../../models/permissions/IGroupPermissionModel";
import { useGroupPermissionData } from "../permission/groupPermission/GroupPermissionDataHook";
import { IGroupPermissionDataModel } from "../../models/permissions/IGroupPermissionDataModel";
import { IUpdateContentGroupResult } from "../../models/permissions/IUpdateContentGroupResult";

/**
 * The content group api hook return.
 */
interface ContentGroupApiHookReturn {
    addGroup: (groupId: string) => Promise<IPermissionsGroupModel>;
    removeSingleGroup: (groupId: string) => Promise<void>;
    removeGroups: (groupId: string[]) => Promise<IDeleteErrorResult[]>;
    getById: (groupId: string) => Promise<IPermissionsGroupWithAccessInfoModel>;
    getAvailableGroups: () => Promise<IUserGroupModel[]>;
    getRecords: (
        filters: Record<string, string>,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IPermissionsGroupModel>>;
    validatePermissions: (
        groupId: string,
        groupPermissions: IGroupPermissionModel[],
    ) => Promise<Record<string, string>>;
    updateGroup: (group: IPermissionsGroupWithAccessInfoModel) => Promise<IUpdateContentGroupResult>;
}

interface IContentGroupApiHookProps {
    contentId: string;
    projectId: string;
}

const baseUrl = (projectId: string, contentId: string, groupId?: string) =>
    `/_api/project/${projectId}/contententity/${contentId}/groups${groupId ? "/" + groupId : ""}`;

export const useContentGroupApi = ({ contentId, projectId }: IContentGroupApiHookProps): ContentGroupApiHookReturn => {
    const { httpGetJson, httpPost, httpDeleteResponseJson, httpDelete, httpPut } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const addGroup = useCallback(
        (groupId: string) =>
            cancellableRequest<IPermissionsGroupModel, string>(
                {
                    url: baseUrl(projectId, contentId),
                    body: groupId,
                },
                httpPost,
            ),
        [contentId, projectId, cancellableRequest, httpPost],
    );

    const removeSingleGroup = useCallback(
        (groupId: string): Promise<void> =>
            cancellableRequest({ url: baseUrl(projectId, contentId, groupId) }, httpDelete),
        [contentId, projectId, cancellableRequest, httpDelete],
    );

    const removeGroups = useCallback(
        (groupIds: string[]): Promise<IDeleteErrorResult[]> =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(projectId, contentId), body: groupIds },
                httpDeleteResponseJson,
            ),
        [contentId, projectId, cancellableRequest, httpDeleteResponseJson],
    );

    const getAvailableGroups = useCallback(
        () => cancellableRequest<IUserGroupModel[]>({ url: `${baseUrl(projectId, contentId)}/available` }, httpGetJson),
        [contentId, projectId, cancellableRequest, httpGetJson],
    );

    const getById = useCallback(
        (groupId: string) =>
            cancellableRequest<IPermissionsGroupWithAccessInfoModel>(
                { url: `${baseUrl(projectId, contentId, groupId)}` },
                httpGetJson,
            ),
        [contentId, projectId, cancellableRequest, httpGetJson],
    );

    const getRecords = useCallback(
        (filters: Record<string, string>, sortFields?: ISortField[], offset?: number) => {
            return cancellableRequest<IEntityResult<IPermissionsGroupModel>>(
                {
                    url: baseUrl(projectId, contentId),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [contentId, projectId, cancellableRequest, httpGetJson],
    );

    const { serialize } = useGroupPermissionData();

    const updateGroup = useCallback(
        (group: IPermissionsGroupWithAccessInfoModel) =>
            cancellableRequest<IUpdateContentGroupResult, IPermissionsGroupModel>(
                { url: `${baseUrl(projectId, contentId, group.groupId)}`, body: group },
                httpPut,
            ),
        [cancellableRequest, projectId, contentId, httpPut],
    );

    const validatePermissions = useCallback(
        (groupId: string, groupPermissions: IGroupPermissionModel[]) => {
            const serializedPermissions = serialize(groupPermissions);

            return cancellableRequest<Record<string, string>, IGroupPermissionDataModel[]>(
                {
                    url: `${baseUrl(projectId, contentId, groupId)}/validatepermission`,
                    body: serializedPermissions,
                },
                httpPost,
            );
        },
        [cancellableRequest, contentId, httpPost, projectId, serialize],
    );

    return {
        addGroup,
        removeSingleGroup,
        removeGroups,
        getById,
        getAvailableGroups,
        getRecords,
        validatePermissions,
        updateGroup,
    };
};
