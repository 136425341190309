import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { useMobileMediaQuery } from "../../hooks/MobileMediaQueryHook";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { SidebarProjectRecentItem } from "./SidebarProjectRecentItem";
import { ProjectAvatar } from "./ProjectAvatar";
import classNames from "classnames";

import "./SidebarProjectRecentItems.scss";

/**
 * The sidebar project  actions props interface.
 */
interface ISidebarProjectRecentItemsProps {
    items: IRecentProjectModel[];
    projectName?: string;
    disabled?: boolean;
}

/**
 * The sidebar project recent items component.
 */
export const SidebarProjectRecentItems: React.FC<ISidebarProjectRecentItemsProps> = ({
    items,
    projectName,
    disabled,
}: ISidebarProjectRecentItemsProps) => {
    const { t } = useTranslation();
    const isMobile = useMobileMediaQuery();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => {
        setIsOpen((open) => {
            if (disabled) {
                return false;
            }

            return !open;
        });
    }, [disabled]);

    return (
        <>
            <Dropdown
                className={classNames("recent-projects", { disabled: disabled })}
                direction="end"
                isOpen={!disabled && isOpen}
                toggle={toggle}
            >
                <DropdownToggle data-toggle="dropdown" outline>
                    {projectName ? (
                        <ProjectAvatar className="sidebar-project-item-avatar" name={projectName} size="38" />
                    ) : (
                        <i className="far fa-ellipsis-h" />
                    )}
                </DropdownToggle>
                <DropdownMenu className="recent-projects-dropdown-menu" flip={isMobile} container="body" role="menu">
                    <h6 className="dropdown-header">{t("SidebarProjectRecentItems.Title")}</h6>
                    {items.map((itemProps, index) => {
                        return <SidebarProjectRecentItem key={index} recentProject={itemProps} />;
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
