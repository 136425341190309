import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AvatarWrapper } from "../../components/avatars/AvatarWrapper";
import { Button } from "../../components/buttons/Button";
import { CreateOrganizationForm } from "../../components/organizations/CreateOrganizationForm";
import { IRoute } from "../../models/IRoute";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { NotificationService } from "../../services/NotificationService";
import { paths, pathKeys, organizationPaths } from "../../PathConstants";
import { SidebarNavItemIcon } from "../../components/sidebar/SidebarNavItemIcon";
import { useModalContext } from "../../contexts/ModalContext";
import { useNavigation } from "../NavigationHook";
import { useSidebarContext } from "../../contexts/SidebarContext";
import { useTestSelector } from "../AutomatedTestsServiceHook";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { SideMenuItem } from "../../components/sidebar/SideMenuItem";
import { useOrganizationApi } from "./OrganizationApiHook";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

interface OrganizationSidebarReturn {
    mainRoutes: IRoute[];
    footerRoutes: IRoute[];
}

interface ILinkItemDefBase {
    name: string;
}

interface ILinkItemDefButton extends ILinkItemDefBase {
    type: "Button";
    icon: string;
    testSelectorValue?: string;
    onClick: () => void;
    className: string;
    ariaLabel: string;
}

interface ILinkItemDefOrganization extends ILinkItemDefBase {
    type: "Organization";
    className: string;
}

interface ILinkItemDefIcon extends ILinkItemDefBase {
    type: "Icon";
    icon: string;
}

const baseSize = 24;
const defaultAvatarSize = baseSize.toString();

// meaning that will be 10px font size.
const defaultAvatarFontSizeRatio = baseSize / 10;

// meaning that will be a minimum of 2px margin
const defaultAvatarMarginRatio = baseSize / 120;

type ILinkItemDef = ILinkItemDefButton | ILinkItemDefOrganization | ILinkItemDefIcon;

const permissionKeys = { permissionKeys: [PermissionKeys.app.manage, PermissionKeys.organization.write] };

export const useOrganizationSidebar = (): OrganizationSidebarReturn => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { showModal, closeModal } = useModalContext();
    const { navigate, replace } = useNavigation();
    const { refreshSidebar, setRefreshSidebar } = useSidebarContext();
    const { create, getSidebarOrganizations } = useOrganizationApi();
    const [createdOrganizationId, setCreatedOrganizationId] = useState<string | null>(null);
    const { isAllowed } = usePermissionCheck(permissionKeys);

    const hasAdminManagePermission = isAllowed(PermissionKeys.app.manage);
    const hasOrganizationPermission = isAllowed(PermissionKeys.organization.write);

    useEffect(() => {
        if (createdOrganizationId) {
            navigate(organizationPaths.link.edit(createdOrganizationId));
            setCreatedOrganizationId(null);
        }
    }, [createdOrganizationId, navigate]);

    const onOrganizationCreated = useCallback(
        (organization: IOrganizationModel) => {
            closeModal();

            NotificationService.addSuccessNotification({
                messageKey: "OrganizationsView.CreationSuccess",
                messageKeyParams: { name: organization.name },
            });
            setRefreshSidebar(true);
            setCreatedOrganizationId(organization.organizationId);
        },
        [closeModal, setRefreshSidebar],
    );

    const createOrganizationModal = useCallback(() => {
        showModal({
            entity: { name: "", description: "" },
            onCreate: create,
            onCreated: onOrganizationCreated,
            formPropsToFlow: {},
            modalProps: {
                className: "create-organization-modal",
                titleKey: "CreateOrganizationModal.Title",
                titleIconClassName: "fal fa-key",
                unsavedWarningBody: "Organization.UnsavedWarningBody",
                size: "md",
                expandable: false,
                onClose: closeModal,
            },
            contentToDisplay: CreateOrganizationForm,
        });
    }, [create, closeModal, onOrganizationCreated, showModal]);

    const createLinkItem = useCallback(
        (props: ILinkItemDef) => {
            switch (props.type) {
                case "Button":
                    return (
                        <div className={props.className}>
                            <Button
                                onClick={props.onClick}
                                icon={props.icon}
                                {...setSelector(props.testSelectorValue)}
                                outline
                                ariaLabel={props.ariaLabel}
                            >
                                <span className="nav-link-text text-truncate">{t(props.name)}</span>
                            </Button>
                        </div>
                    );
                case "Organization":
                    return (
                        <SideMenuItem>
                            <AvatarWrapper
                                className={props.className}
                                name={props.name}
                                size={defaultAvatarSize}
                                textSizeRatio={defaultAvatarFontSizeRatio}
                                textMarginRatio={defaultAvatarMarginRatio}
                            />
                            <span className="nav-link-text text-truncate">{props.name}</span>
                        </SideMenuItem>
                    );
                default:
                    return <SidebarNavItemIcon icon={props.icon} name={props.name} />;
            }
        },
        [t, setSelector],
    );

    const initialRoute: IRoute[] = useMemo(
        () => [
            {
                path: paths.home,
                children: createLinkItem({ type: "Icon", name: "Route.Home", icon: "far fa-home" }),
            },
        ],
        [createLinkItem],
    );

    const footerRoutes: IRoute[] = useMemo(
        () =>
            hasAdminManagePermission
                ? [
                      {
                          path: paths.applicationSettings,
                          children: createLinkItem({
                              type: "Icon",
                              name: "Route.ApplicationSettings",
                              icon: "far fa-cog",
                          }),
                      },
                  ]
                : [],
        [createLinkItem, hasAdminManagePermission],
    );

    const [mainRoutes, setMainRoutes] = useState<IRoute[]>(initialRoute);

    const createOrganizationButton: IRoute = useMemo(
        () => ({
            children: hasOrganizationPermission
                ? createLinkItem({
                      type: "Button",
                      name: "EntityType.Organization",
                      icon: "fas fa-plus",
                      className: "navbar-create-organization",
                      testSelectorValue: "createOrganizationButton",
                      onClick: createOrganizationModal,
                      ariaLabel: t("Organization.Create"),
                  })
                : undefined,
        }),
        [createLinkItem, createOrganizationModal, hasOrganizationPermission, t],
    );

    const loadSidebarOrganizations = useCallback(async () => {
        try {
            const { containsTooManyOrganizations, organizations } = await getSidebarOrganizations();
            if (containsTooManyOrganizations) {
                setMainRoutes([
                    ...initialRoute,
                    {
                        path: paths.organizations,
                        children: createLinkItem({
                            type: "Icon",
                            name: "Route.Organizations",
                            icon: "far fa-key",
                        }),
                    },
                ]);
            } else {
                const organizationRoutes: IRoute[] = organizations.map(({ name, organizationId }) => {
                    return {
                        name: name,
                        path: `${pathKeys.organization}/Edit/${organizationId}`,
                        children: createLinkItem({
                            type: "Organization",
                            name,
                            className: "organization-avatar",
                        }),
                    };
                });

                setMainRoutes([...initialRoute, ...organizationRoutes, createOrganizationButton]);
            }
        } catch (error) {
            defaultRequestErrorHandler(error);
            replace(organizationPaths.link.base, { state: { loadError: true } });
        }
    }, [createLinkItem, createOrganizationButton, getSidebarOrganizations, initialRoute, replace]);

    useEffect(() => {
        loadSidebarOrganizations();
    }, [loadSidebarOrganizations]);

    useEffect(() => {
        if (refreshSidebar) {
            loadSidebarOrganizations();
            setRefreshSidebar(false);
        }
    }, [loadSidebarOrganizations, setRefreshSidebar, refreshSidebar]);

    return {
        footerRoutes,
        mainRoutes,
    };
};
