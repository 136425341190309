import React from "react";
import { Outlet } from "react-router-dom";
import { ConfigProvider } from "react-avatar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DialogWithContext } from "../dialogs/DialogWithContext";
import { ModalWithContext } from "../modals/ModalWithContext";
import { ShortcutProvider } from "../shortcut/ShortcutProvider";
import { NotificationHubProvider } from "../hubs/NotificationHubProvider";
import { availableColors } from "../../helpers/themes/Themes";
import { TableRefreshContextProvider } from "../table/TableRefreshContextProvider";
import { NavigationBlockerProvider } from "../navigation/NavigationBlockerProvider";
import { PermissionHubProvider } from "../hubs/PermissionHubProvider";
import { SidePanelContextProvider } from "../sidePanel/SidePanelProvider";
import { CurrentEntitiesNameProvider } from "../CurrentEntities/CurrentEntitiesNameProvider";
import { PreviewConsentWrapper } from "../account/PreviewConsentWrapper";
import { NotificationProvider } from "../notifications/NotificationProvider";

/**
 * This is a temporary fix until react-avatar releases a fix
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const AvatarConfigProvider = ConfigProvider as React.ComponentType<React.PropsWithChildren<ConfigProvider>>;

/**
 * The authenticated section component.
 */
export const AuthenticatedSection: React.FC = () => {
    return (
        <NotificationProvider>
            <NotificationHubProvider>
                <DndProvider backend={HTML5Backend}>
                    <PermissionHubProvider>
                        <AvatarConfigProvider colors={availableColors}>
                            <ShortcutProvider>
                                <TableRefreshContextProvider>
                                    <DialogWithContext testSelectorValueDialog="confirmationDialog">
                                        <NavigationBlockerProvider>
                                            <SidePanelContextProvider>
                                                <ModalWithContext>
                                                    <CurrentEntitiesNameProvider>
                                                        <PreviewConsentWrapper>
                                                            <Outlet />
                                                        </PreviewConsentWrapper>
                                                    </CurrentEntitiesNameProvider>
                                                </ModalWithContext>
                                            </SidePanelContextProvider>
                                        </NavigationBlockerProvider>
                                    </DialogWithContext>
                                </TableRefreshContextProvider>
                            </ShortcutProvider>
                        </AvatarConfigProvider>
                    </PermissionHubProvider>
                </DndProvider>
            </NotificationHubProvider>
        </NotificationProvider>
    );
};
