import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { useAuthorization } from "../../hooks/authorization/AuthorizationHook";
import { LoadingScreen } from "../loading/LoadingScreen";
import { AuthenticatedSection } from "../sections/AuthenticatedSection";
import { UserWithContext } from "../users/UserWithContext";
import { applicationPaths, queryParameterNames } from "./ApiAuthorizationConstants";
import { useSingleProjectData } from "../../hooks/project/SingleProjectDataHook";
import { localeAtom } from "../../atoms/user";

/**
 * The authorize component.
 */
export const Authorize: React.FC = () => {
    const { ready, authorized, userId, locale, reloadClaims } = useAuthorization();
    const setLocale = useSetAtom(localeAtom);

    useEffect(() => {
        setLocale(locale);
    }, [locale, setLocale]);

    const status = useSingleProjectData(ready, authorized);

    const redirectUrl = `${applicationPaths.login}?${queryParameterNames.returnUrl}=${encodeURIComponent(
        window.location.href,
    )}`;

    if (!ready || status !== "Proceed") {
        return <LoadingScreen />;
    }

    if (authorized && userId) {
        return (
            <UserWithContext user={{ userId, locale, reloadClaims }}>
                <AuthenticatedSection />
            </UserWithContext>
        );
    }

    return <Navigate to={redirectUrl} />;
};
