import React, { useMemo } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Table as ReactstrapTable } from "reactstrap";
import { useDrop } from "react-dnd";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { ISortField } from "../../models/ISortField";
import { EntityTableLoadingTable } from "../table/EntityTableLoadingTable";
import { IEntityResult } from "../../models/IEntityResult";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { ITableRowItem } from "./TableRow";
import { TableHeader } from "../tableHeader/TableHeader";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { EntityView } from "../views/EntityView";
import { EntityViewBody } from "../views/EntityViewBody";
import { useTable } from "../../hooks/table/Table";
import { ITableContextModel, TableContext } from "./TableContext";
import { TableBody } from "./TableBody";
import { TableFooter } from "./TableFooter";
import { EntityTableHeader } from "./EntityTableHeader";
import { DeleteConfirmationDialog, DeleteConfirmationIcon } from "../dialogs/DeleteConfirmationDialog";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ITableState } from "../../hooks/table/TableReducer";
import { TableNoResultCTAWithTableContext } from "./TableNoResultCTAWithTableContext";
import { DefaultDeleteConfirmDialogBody } from "../dialogs/DefaultDeleteConfirmDialogBody";
import { EntityType } from "../../models/EntityType";
import { IExtraActions } from "../../models/IExtraActionsModel";
import { useTableDrop } from "../../hooks/table/TableDropHook";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { ITableDependencyDeleteDialogProps } from "../dialogs/TableDependencyDeleteDialog";

import "./Table.scss";

type TableCreateHelperModalContext = {
    mode: "modal";
    onCreate: (onEntityCreated: (entity: any) => void) => void;
    buttonTextKey?: string;
};

type TableCreateHelperTableContext = {
    mode: "table";
    modalComponent: React.ComponentType<any>;
    modalComponentProps?: any;
    onCreated?: (...props: any) => void;
    /** If not provided, "Create" will be used as default value. */
    buttonTextKey?: string;
};

export interface IRenderDeleteBlockerArgs<T> {
    selectedItems: T[];
    totalCount: number;
    tableId: string;
}

export type TableCreateHelper = TableCreateHelperModalContext | TableCreateHelperTableContext;

/**
 * The table delete helper interface.
 */
export interface ITableDeleteHelper<T> {
    confirmationTitleMessageKey: string;
    confirmationTitleMessageKeyParams?: Record<string, string>;
    confirmationBodyMessageKey: string;
    confirmationBodyMessageKeyParams?: Record<string, string>;
    confirmationBodyWarningMessageKey?: string;
    deleteRecords: (recordId: string[], tableRecords: Readonly<T>[]) => Promise<IDeleteErrorResult[]>;
    singleDelete?: (id: string) => Promise<void>;
    notificationMessageKey: string;
    notificationMessageKeyParams?: Record<string, string>;
    onDeleted?: (deletedIds: string[]) => void;
    /** If not provided, "Delete" will be used as default value. */
    buttonTextKey?: string;
    deleteOverride?: ({ selectedItems, totalCount, tableId }: IRenderDeleteBlockerArgs<T>) => JSX.Element | null;
    dialogIcon?: DeleteConfirmationIcon;
    buttonIcon?: string;
    deleteLabel?: string;
    customDeleteComponent?: React.ComponentType<ITableDependencyDeleteDialogProps>;
    getDependencies?: (ids: string[], dependencyType: DependencyType) => Promise<MultipleDependenciesResult>;
    entityType?: EntityType;
}

/**
 * The table filter helper interface.
 */
export interface ITableFilterHelper<TFilterValue> {
    defaultFilterValue: TFilterValue;
    filter: (filterValue: TFilterValue, onChange: (filterValue: TFilterValue) => void) => JSX.Element;
    isActive: (filterValue: TFilterValue) => boolean;
}

export interface ITableDragAndDropHelper {
    enabled: boolean;
    onDrop: (droppedItem: any, itemType: string) => void;
    supportedDrops: string[];
}

interface IEnabledTableRowDragAndDropProps {
    enabled: true;
    onDrop: (droppedItem: any, itemType: string) => void;
    supportedDrops: string[];
}

interface IDisabledTableRowDragAndDropProps {
    enabled: false;
    supportedDrops: string[];
}

interface ITableRowDragProps {
    enabled: boolean;
    itemType: string;
}

export interface ITableRowDragAndDropProps {
    dragProps?: ITableRowDragProps;
    dropProps?: IEnabledTableRowDragAndDropProps | IDisabledTableRowDragAndDropProps;
}

/**
 * The table props interface.
 */
export interface ITableProps<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> {
    columnDefs: IEntityTableColumnDef[];
    getRecords: (
        filterValue?: TFilterValue,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<TEntityModel>>;
    updateRecords?: (
        tableState: ITableState<TEntityModel, TRowItem, TFilterValue>,
    ) => Promise<IEntityResult<TEntityModel>>;
    triggerUpdate?: boolean;
    triggerRefresh?: boolean;
    actionProvider?: (
        item: TEntityModel,
        context: ITableContextModel<TEntityModel, TRowItem, TFilterValue>,
    ) => ITableRowActionProps[];
    keyExtractor: (item: TEntityModel) => string;
    /** Utility method to extract an entity's name (displayed in the notification service message for instance: Entity ABC has been deleted). */
    nameExtractor?: (item: TEntityModel) => string;
    loadingMessageKey: string;
    noResultMessageKey?: string;
    className?: string;
    createHelper?: TableCreateHelper;
    deleteHelper?: ITableDeleteHelper<TEntityModel>;
    filterHelper?: ITableFilterHelper<TFilterValue>;
    dragAndDropHelper?: ITableDragAndDropHelper;
    rowDragAndDropProvider?: (item: TEntityModel) => ITableRowDragAndDropProps;
    /** When provided, actions such as "delete" will automatically be available for every rows. */
    useDefaultActionProviders?: boolean;
    rowDisabled?: (item: TEntityModel) => boolean;
    selectable?: boolean;
    refreshable?: boolean;
    tableId: string;
    noResultsCTAProps?: Omit<Parameters<typeof TableNoResultCTAWithTableContext>[0], "onClick">;
    defaultSortField?: ISortField;
    counterPosition?: "Start" | "End";
    onSelectionChange?: (selectedItems: TEntityModel[]) => void;
    extraActions?: IExtraActions<TEntityModel, TRowItem, TFilterValue>[];
    onLoadError?: (error: Error) => void;
    onLoaded?: () => void;
}

/**
 * The table component.
 */
export const Table = <TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue>({
    columnDefs,
    getRecords,
    actionProvider,
    updateRecords,
    triggerUpdate,
    triggerRefresh,
    keyExtractor,
    nameExtractor,
    loadingMessageKey,
    noResultMessageKey,
    className,
    createHelper,
    deleteHelper,
    filterHelper,
    dragAndDropHelper,
    rowDragAndDropProvider,
    useDefaultActionProviders,
    rowDisabled,
    selectable = false,
    refreshable = true,
    tableId,
    noResultsCTAProps,
    defaultSortField,
    counterPosition,
    onSelectionChange,
    extraActions,
    onLoadError,
    onLoaded,
}: ITableProps<TEntityModel, TRowItem, TFilterValue>): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const {
        tableState,
        onSelect,
        onFilterChange,
        onSort,
        canLoadMore,
        onLoadMore,
        onCreateEntity,
        onToggleCreateEntityModal,
        onToggleDeleteEntityModal,
        onDeleteEntities,
        onSetItemsForDeletion,
        setIsFilterPanelOpen,
        selectAll,
        unselectAll,
    } = useTable<TEntityModel, TRowItem, TFilterValue>(
        getRecords,
        keyExtractor,
        tableId,
        updateRecords,
        triggerUpdate,
        triggerRefresh,
        nameExtractor,
        createHelper,
        deleteHelper,
        filterHelper,
        defaultSortField,
        onSelectionChange,
        onLoadError,
        onLoaded,
    );

    const { updateDropableStatus, canDropOnMain } = useTableDrop();

    const initialContext: ITableContextModel<TEntityModel, TRowItem, TFilterValue> = useMemo(
        () => ({
            tableState,
            onFilterChange,
            columnDefs,
            canLoadMore,
            className,
            onLoadMore,
            keyExtractor,
            onSelect,
            loadingMessageKey,
            onToggleCreateEntityModal,
            onToggleDeleteEntityModal,
            onSetItemsForDeletion,
            useDefaultActionProviders,
            actionProvider,
            rowDisabled,
            selectable,
            refreshable,
            createHelper,
            deleteHelper,
            filterHelper,
            setIsFilterPanelOpen,
            selectAll,
            unselectAll,
            extraActions,
            updateDropableStatus,
            rowDragAndDropProvider,
        }),
        [
            tableState,
            onFilterChange,
            columnDefs,
            canLoadMore,
            className,
            onLoadMore,
            keyExtractor,
            onSelect,
            loadingMessageKey,
            onToggleCreateEntityModal,
            onToggleDeleteEntityModal,
            onSetItemsForDeletion,
            useDefaultActionProviders,
            actionProvider,
            rowDisabled,
            selectable,
            refreshable,
            createHelper,
            deleteHelper,
            filterHelper,
            setIsFilterPanelOpen,
            selectAll,
            unselectAll,
            extraActions,
            updateDropableStatus,
            rowDragAndDropProvider,
        ],
    );

    const filterIsActive = filterHelper && filterHelper.isActive(tableState.filterValue);
    const displayFirstLoadOverlay = tableState.isLoading && tableState.totalCount <= 0 && !filterIsActive;

    const displayFilteredTableNoResults =
        !tableState.isLoading && tableState.totalCount > 0 && filterIsActive && tableState.filteredCount <= 0;

    const displaySearchTableNoResults = !tableState.isLoading && tableState.items.length === 0 && noResultMessageKey;

    const displayFilteredLoadingOverlay = tableState.isLoading && tableState.totalCount > 0 && filterIsActive;
    const displayLoadingOverlay = tableState.isLoading && tableState.offset === 0;

    const displayCTA = !tableState.isLoading && tableState.totalCount <= 0 && noResultsCTAProps && tableId !== "users";

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: dragAndDropHelper?.supportedDrops ?? [],
            drop: (item, monitor) => {
                const itemType = monitor.getItemType()?.toString();
                !monitor.didDrop() && dragAndDropHelper?.onDrop(item, itemType!);
            },
            canDrop: () => !!dragAndDropHelper && dragAndDropHelper.enabled,
            collect: (monitor) => ({ isOver: monitor.isOver() && monitor.canDrop() && canDropOnMain }),
        }),
        [dragAndDropHelper, canDropOnMain],
    );

    return (
        <TableContext.Provider value={initialContext}>
            <EntityView>
                {displayFirstLoadOverlay && <EntityTableLoadingTable message={loadingMessageKey} />}
                {displayCTA && (
                    <>
                        {noResultsCTAProps?.keepTableHeader && (
                            <TableHeader counterPosition={counterPosition ?? "End"} />
                        )}
                        <TableNoResultCTAWithTableContext
                            createTitle={"Common.Create"}
                            dropRef={drop}
                            className={classNames({ "drag-over": isOver })}
                            {...noResultsCTAProps}
                        />
                    </>
                )}
                {!displayFirstLoadOverlay && !displayCTA && (
                    <>
                        <TableHeader counterPosition={counterPosition || "End"} />
                        <EntityViewBody className={classNames("entity-list", { "drag-over": isOver })} dropRef={drop}>
                            <ReactstrapTable
                                className={classNames("entity-table", className)}
                                hover={true}
                                responsive={true}
                            >
                                <EntityTableHeader
                                    onSort={onSort}
                                    sortField={tableState.sortField}
                                    columnDefs={columnDefs}
                                    selectable={selectable}
                                    hasActions={!!actionProvider || !!useDefaultActionProviders}
                                />
                                <TableBody />
                                <TableFooter />
                            </ReactstrapTable>
                            {displayFilteredTableNoResults && (
                                <div className="no-entity-result" {...setSelector("tableNoResultDiv")}>
                                    <div>{t("Common.NoFilterResult")}</div>
                                </div>
                            )}
                            {displaySearchTableNoResults && (
                                <div className="no-entity-result" {...setSelector("tableNoResultDiv")}>
                                    {
                                        <Trans
                                            i18nKey={"ContentManager.Search.NoResults"}
                                            values={{ value: displaySearchTableNoResults }}
                                        />
                                    }
                                </div>
                            )}

                            {(displayFilteredLoadingOverlay || displayLoadingOverlay) && (
                                <EntityTableLoadingTable message={loadingMessageKey} />
                            )}
                        </EntityViewBody>
                    </>
                )}
            </EntityView>
            {createHelper && createHelper.mode === "table" && (
                <createHelper.modalComponent
                    {...createHelper.modalComponentProps}
                    isOpen={tableState.isCreateModalDisplayed}
                    onClose={() => onToggleCreateEntityModal(false)}
                    onCreated={onCreateEntity}
                />
            )}
            {deleteHelper &&
                (deleteHelper.customDeleteComponent ? (
                    <deleteHelper.customDeleteComponent
                        onDeleteEntities={onDeleteEntities}
                        getDependencies={deleteHelper.getDependencies}
                    />
                ) : (
                    <DeleteConfirmationDialog
                        show={tableState.isDeleteModalDisplayed}
                        title={t(deleteHelper.confirmationTitleMessageKey, {
                            count: tableState.itemIdsToDelete.length,
                            ...deleteHelper.confirmationTitleMessageKeyParams,
                        })}
                        onCancel={() => onToggleDeleteEntityModal(false)}
                        onDelete={onDeleteEntities}
                        dialogIcon={deleteHelper.dialogIcon}
                        deleteLabel={deleteHelper.deleteLabel}
                    >
                        <DefaultDeleteConfirmDialogBody
                            confirmationBodyMessageKey={deleteHelper.confirmationBodyMessageKey}
                            count={tableState.itemIdsToDelete.length}
                            confirmationBodyMessageKeyParams={deleteHelper.confirmationBodyMessageKeyParams}
                            confirmationBodyWarningMessageKey={deleteHelper.confirmationBodyWarningMessageKey}
                        />
                    </DeleteConfirmationDialog>
                ))}
        </TableContext.Provider>
    );
};
