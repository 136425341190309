import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import { IVersionComment } from "../models/versioning/VersioningModels";

/**
 * The version comment validator.
 */
export class VersionCommentValidator extends FormValidator<IVersionComment> {
    public static readonly commentMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        comment: Yup.string()
            .max(VersionCommentValidator.commentMaxLength, ({ max }) => {
                return <ValidatorError messageKey="Version.CommentMaxLengthValidation" options={{ max }} />;
            })
            .strict(true),
    });

    public validateComment(comment: string | null): Promise<string | undefined> {
        return this.schema.fields.comment.validate(comment);
    }
}
