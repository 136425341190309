import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

export interface ILanguageFormFields {
    languageCodes: string[];
}

export class LanguageFormValidator extends FormValidator<ILanguageFormFields> {
    protected schema = Yup.object().shape({
        languageCodes: Yup.array()
            .min(1, () => <ValidatorError messageKey="Common.Required" />)
            .of(Yup.string()),
    });

    public validateLanguageCodes(languageCodes: string[]): Promise<(string | undefined)[] | undefined> {
        return this.schema.fields.languageCodes.validate(languageCodes);
    }
}
