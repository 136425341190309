import React from "react";
import { Filter } from "../filter/Filter";
import { FilterInput } from "../filter/FilterInput";
import { IFilterOptionsComponents, OptionType } from "../../models/IFilterOptions";
import { FilterDropDownCheckboxComponent } from "../filter/FilterDropDownCheckboxComponent";

const throttleFilterValue: number = 500;

/**
 * The default table filter with option props.
 */
interface ITableFilterProps<TOption extends OptionType> {
    text?: string;
    filterComponents: IFilterOptionsComponents<TOption>[];
    filterPlaceHolder: string;
    onChange: (value: { filterText: string; filters: TOption[][] }) => void;
}

/**
 * The default table filter with Option.
 */
export const DefaultTableFilterWithOptions = <TOption extends OptionType>({
    text = "",
    filterComponents,
    onChange,
    filterPlaceHolder,
}: ITableFilterProps<TOption>): JSX.Element => {
    const onNameChange = (value: string): void => {
        const filtersArray = filterComponents.map((fc) => fc.activeFilter);
        onChange({
            filterText: value,
            filters: filtersArray,
        });
    };

    const onFilterChange = (filterIndex: number, options: TOption[]) => {
        const filtersArray = filterComponents.map((fc) => fc.activeFilter);
        filtersArray[filterIndex] = options;
        onChange({
            filterText: text,
            filters: filtersArray,
        });
    };

    return (
        <Filter
            onClear={() => onChange({ filterText: "", filters: filterComponents.map(() => []) })}
            clearDisabled={
                !text && filterComponents.every((filterComponent) => filterComponent.activeFilter.length === 0)
            }
            active={!!text || filterComponents.some((filterComponent) => filterComponent.activeFilter.length > 0)}
            size="lg"
        >
            <FilterInput
                value={text}
                throttle={throttleFilterValue}
                placeHolderName={filterPlaceHolder}
                onValueChange={onNameChange}
            />

            {filterComponents.map((component, i) => {
                return (
                    <FilterDropDownCheckboxComponent
                        text={text}
                        filterComponents={component}
                        onFilterChange={onFilterChange}
                        index={i}
                        key={component.defaultCheckboxLabel}
                        size="lg"
                    />
                );
            })}
        </Filter>
    );
};
