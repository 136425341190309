import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetAtom } from "jotai";
import { PermissionKeys } from "../../../PermissionKeyConstants";
import { usePermissionCheck } from "../../permission/PermissionCheckHook";
import { usePermissionKey } from "../../permission/PermissionKeyHook";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useContentFileApi } from "../ContentFileApiHook";
import { useContentEntityApi } from "../ContentEntityApiHook";
import { NotificationService } from "../../../services/NotificationService";
import { ContentEntityType, IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { useNavigation } from "../../NavigationHook";
import { contentManagerPaths } from "../../../PathConstants";
import { extractErrorMessage, extractErrorMessageAsHtml } from "../../../helpers/ErrorHelper";
import { useContentFolderApi } from "../ContentFolderApiHook";
import { ContentManagerEntityType } from "../../../models/EntityType";
import { useGetFoldersTreeFromPath } from "../GetFoldersTreeFromPathHook";
import { FoldersTree } from "../../../models/contentManager/FolderTree";
import { contentViewFolderTreeNeedRefreshAtom } from "../../../atoms/ContentManager";
import { contentManagerSearchParamPath } from "../ContentManagerNavigationHook";
interface IEditContentItemProps {
    itemId: string;
    itemType: ContentManagerEntityType;
}

export const useEditContentItem = ({ itemId, itemType }: IEditContentItemProps) => {
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const [searchParam] = useSearchParams();
    const [pathSet, setPathSet] = useState(false);
    const [preLoadedItem, setPreLoadedItem] = useState<null | IContentEntityModel>(null);
    const [itemParentId, setItemParentId] = useState<string | null | undefined>();
    const [foldersTree, setFoldersTree] = useState<FoldersTree>([]);
    const [updatedItem, setUpdatedItem] = useState<IContentEntityModel | null>(null);
    const setContentViewNeedRefresh = useSetAtom(contentViewFolderTreeNeedRefreshAtom);

    const { get: getFile, update: updateFile, nameIsUnique: fileNameIsUnique } = useContentFileApi(projectId);
    const { get: getFolder, update: updateFolder, nameIsUnique: folderNameIsUnique } = useContentFolderApi(projectId);
    const { removeRecords: remove } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: false,
    });

    const { replace } = useNavigation();
    const { hash } = useLocation();

    const { loadFolderTree } = useGetFoldersTreeFromPath();

    const contentItemWriteKey = usePermissionKey({
        permission: PermissionKeys.contentManager.write,
        projectId,
        objectId: itemId,
    });
    const contentItemReadKey = usePermissionKey({
        permission: PermissionKeys.contentManager.read,
        projectId,
        objectId: itemId,
    });
    const contentItemDeleteKey = usePermissionKey({
        permission: PermissionKeys.contentManager.delete,
        projectId,
        objectId: itemId,
    });

    const permissionsObject = useMemo(
        () => ({
            permissionKeys: [contentItemWriteKey, contentItemDeleteKey, contentItemReadKey],
        }),
        [contentItemWriteKey, contentItemDeleteKey, contentItemReadKey],
    );
    const { isAllowed } = usePermissionCheck(permissionsObject);

    const updatePath = useCallback(
        async (item: IContentEntityModel) => {
            const dividedPath = item.path.split("/");
            dividedPath.pop();
            const itemPath = dividedPath.join("/");

            const { currentFolderLocationId, foldersTree: loadedFoldersTree } = await loadFolderTree(itemPath);
            replace({
                search: `?${contentManagerSearchParamPath}=${itemPath}`,
                hash,
            });

            setContentViewNeedRefresh(true);
            setPathSet(true);
            setItemParentId(currentFolderLocationId);
            setFoldersTree(loadedFoldersTree);
        },
        [hash, loadFolderTree, replace, setContentViewNeedRefresh],
    );

    const load = useCallback(async (): Promise<IContentEntityModel> => {
        try {
            const getItem = itemType === "Folder" ? getFolder : getFile;
            const item = await getItem(itemId);
            void updatePath(item);
            return item;
        } catch (error) {
            NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
            replace(contentManagerPaths.link.base(organizationName, projectName), { state: { loadError: true } });
            return {} as IContentEntityModel;
        }
    }, [getFile, getFolder, itemType, replace, itemId, organizationName, projectName, updatePath]);

    const saveItem = useCallback(
        async (item: IContentEntityModel): Promise<IContentEntityModel> => {
            const updateItem = itemType === "Folder" ? updateFolder : updateFile;
            // error is handled by onSave method in EditEntityWrapper
            const result = await updateItem(item.id, {
                description: item.description,
                name: item.name,
            });
            NotificationService.addSuccessNotification({
                messageKey: `ContentManager.${itemType}.SaveSuccess`,
                messageKeyParams: { name: result.name },
            });
            setUpdatedItem(result);
            return result;
        },
        [itemType, updateFile, updateFolder],
    );

    const removeItem = useCallback(
        async (itemName: string): Promise<void> => {
            try {
                const errorList = await remove([
                    {
                        id: itemId,
                        type: itemType === "Folder" ? ContentEntityType.Folder : ContentEntityType.File,
                    },
                ]);

                if (errorList.length > 0) {
                    // In that case, only one error message is possible because it's only one item.
                    throw new Error(errorList[0].errorMessage);
                }

                setContentViewNeedRefresh(true);
                NotificationService.addSuccessNotification({
                    messageKey: `ContentManager.${itemType}.DeleteSuccessSingle`,
                    messageKeyParams: { name: itemName },
                });
                replace(contentManagerPaths.link.base(organizationName, projectName), { state: { deleted: true } });
            } catch (error) {
                NotificationService.addErrorNotification({
                    message: extractErrorMessageAsHtml(error),
                });
            }
        },
        [remove, itemId, itemType, replace, organizationName, projectName, setContentViewNeedRefresh],
    );

    const nameIsUnique = useCallback(
        (name: string) => {
            const innerNameIsUnique = itemType === "Folder" ? folderNameIsUnique : fileNameIsUnique;
            return innerNameIsUnique(name, itemParentId as string | null);
        },
        [fileNameIsUnique, folderNameIsUnique, itemType, itemParentId],
    );

    useEffect(() => {
        if (updatedItem) {
            void updatePath(updatedItem);
            setUpdatedItem(null);
        }

        if (searchParam.has(contentManagerSearchParamPath) && pathSet) {
            return;
        }

        (async () => {
            const item = await load();
            setPreLoadedItem(item);
        })();
    }, [searchParam, pathSet, updatePath, load, updatedItem]);

    return {
        canDelete: isAllowed(contentItemDeleteKey),
        canEdit: isAllowed(contentItemWriteKey),
        canRead: isAllowed(contentItemReadKey),
        load,
        remove: removeItem,
        update: saveItem,
        nameIsUnique,
        preLoadedItem,
        ready: preLoadedItem && itemParentId !== undefined,
        foldersTree,
        itemParentId,
    };
};
