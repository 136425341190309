import React from "react";
import { useTranslation } from "react-i18next";
import { ILicenseDataSetFeatures } from "../../../../models/license/ILicenseFeatureModel";
import { licenseFeaturesTranslationMap, licenseSubFeaturesTranslationMap } from "../../../../helpers/LicensingHelper";
import { FeatureItem } from "./FeatureItem";
import { SubFeatureItem } from "./SubFeatureItem";

export const DataSetsFeature: React.FC<ILicenseDataSetFeatures> = ({ enabled, form, json, xml, aiFormAssistant }) => {
    const { t } = useTranslation();

    return (
        <FeatureItem
            id={"dataSetsFeature"}
            enabled={enabled}
            featureName={t(licenseFeaturesTranslationMap["dataSetsFeature"])}
        >
            <SubFeatureItem enabled={form} name={licenseSubFeaturesTranslationMap.form} />
            <SubFeatureItem enabled={json} name={licenseSubFeaturesTranslationMap.json} />
            <SubFeatureItem enabled={xml} name={licenseSubFeaturesTranslationMap.xml} />
            <SubFeatureItem enabled={aiFormAssistant} name={licenseSubFeaturesTranslationMap.aiFormAssistant} />
        </FeatureItem>
    );
};
