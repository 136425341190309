import React from "react";
import { Route, Navigate } from "react-router-dom";
import { DefaultLayout } from "../../layouts/DefaultLayout";
import { ProfileLayout } from "../../layouts/ProfileLayout";
import { ProjectLayout } from "../../layouts/ProjectLayout";
import { IRouteHandle } from "../../models/router/IRouteHandle";
import {
    paths,
    apiKeyPaths,
    projectPaths,
    dataSetPaths,
    templatePaths,
    organizationPaths,
    accountPaths,
    twoFactorAuthenticationPaths,
    translationPaths,
    personalDataPaths,
    serviceHooksPaths,
    contentManagerPaths,
    languagePaths,
    PermissionGroupPaths,
} from "../../PathConstants";
import { EditProfileView } from "../account/EditProfileView";
import { Authorize } from "../api-authorization/Authorize";
import { Configure2FA } from "../account/twoFA/Configure2FA";
import { DisableAuthenticator } from "../account/twoFA/DisableAuthenticator";
import { Manage2FA } from "../account/twoFA/Manage2FA";
import { Manage2FAView } from "../account/twoFA/Manage2FAView";
import { ResetAuthenticator } from "../account/twoFA/ResetAuthenticator";
import { ResetRecoveryCodes } from "../account/twoFA/ResetRecoveryCodes";
import { ApplicationSettingsView } from "../applicationSettings/ApplicationSettingsView";
import { DataSetView } from "../dataSets/DataSetView";
import { HomeView } from "../home/HomeView";
import { ProjectDatasetsRedirect } from "../projects/ProjectDashBoardRedirect";
import { ProjectSettingsView } from "../projects/ProjectSettingsView";
import { EditOrganizationView } from "../organizations/EditOrganizationView";
import { OrganizationsView } from "../organizations/OrganizationsView";
import { EditTemplateView } from "../templates/EditTemplateView";
import { TemplateView } from "../templates/TemplateView";
import { EditPasswordView } from "../account/password/EditPasswordView";
import { ManagePersonalDataForm } from "../account/personalData/ManagePersonalDataForm";
import { ManagePersonalDataView } from "../account/personalData/ManagePersonalDataView";
import { PersonalDataConfirmPasswordView } from "../account/personalData/PersonalDataConfirmPasswordView";
import { EditExternalLoginsView } from "../account/externalLogins/EditExternalLoginsView";
import { LazyApiKeysView } from "../apiKeys/lazy/LazyApiKeysView";
import { LazyEditApiKeyView } from "../apiKeys/lazy/LazyEditApiKeyView";
import { LazyDataSetViewer } from "../dataSets/lazy/LazyDataSetViewer";
import { LazyEditDataSetView } from "../dataSets/lazy/LazyEditDataSetView";
import { EditServiceHookView } from "../serviceHook/EditServiceHookView";
import { ContentManagerView } from "../contentManager/ContentManagerView";
import { EditLanguageView } from "../language/EditLanguageView";
import { EditPermissionsGroupView } from "../permissions/EditPermissionsGroupView";
import { OrganizationProvider } from "../organizations/OrganizationProvider";
import { EditItemDetailsView } from "../contentManager/editItemView/EditItemDetailsView";
import { ContentManagerLayout } from "../contentManager/ContentManagerLayout";
import { EditPermissionsContentGroupView } from "../permissions/EditPermissionsContentGroupView";
import { contentManagerSearchParamPath } from "../../hooks/contentManager/ContentManagerNavigationHook";
import { EditItemDetailsWrapper } from "../contentManager/editItemView/EditItemDetailsWrapper";

const apiKeysHandle: IRouteHandle = { crumb: { name: translationPaths[paths.apiKeys] } };
const projectHandle: IRouteHandle = { crumb: { entityType: "Project" } };
const projectSettingsHandle: IRouteHandle = { crumb: { name: translationPaths[projectPaths.route.settings] } };
const dataSetHandle: IRouteHandle = { crumb: { name: translationPaths[projectPaths.route.dataSets] } };
const templateHandle: IRouteHandle = { crumb: { name: translationPaths[projectPaths.route.templates] } };
const homeHandle: IRouteHandle = { crumb: { name: translationPaths[paths.home] } };
const organizationHandle: IRouteHandle = { crumb: { name: translationPaths[paths.organizations] } };
const applicationSettingsHandle: IRouteHandle = { crumb: { name: translationPaths[paths.applicationSettings] } };
const accountHandle: IRouteHandle = { crumb: { name: translationPaths[paths.account] } };
const profileHandle: IRouteHandle = { crumb: { name: translationPaths[paths.profile] } };
// NOTE: The path to email is temporarily disabled until its functionalities are implemented - Ref: Work item #44289.
// const emailHandle: IRouteHandle = { crumb: { name: translationPaths[paths.email] } };
const externalLoginsHandle: IRouteHandle = { crumb: { name: translationPaths[paths.externalProviders] } };
const personalDataHandle: IRouteHandle = { crumb: { name: translationPaths[paths.personalData] } };
const twoFAHandle: IRouteHandle = { crumb: { name: translationPaths[paths.twoFactorAuthentication] } };
const twoFAConfigureHandle: IRouteHandle = { crumb: { name: "2FA.Enable.Title" } };
const twoFAResetAuthenticatorHandle: IRouteHandle = { crumb: { name: "2FA.ResetAuthenticator.Title" } };
const twoFAResetCodesHandle: IRouteHandle = { crumb: { name: "2FA.RecoveryCodes.GenerateTitle" } };
const twoFADisableHandle: IRouteHandle = { crumb: { name: "2FA.Disable.Title" } };
const passwordHandle: IRouteHandle = { crumb: { name: translationPaths[paths.password] } };
const serviceHookHandle: IRouteHandle = { crumb: { name: translationPaths[paths.serviceHook], hash: "#serviceHooks" } };
const serviceHookEditHandle: IRouteHandle = { crumb: { entityType: "ServiceHook", hash: "#overview" } };
const contentManagerHandle: IRouteHandle = { crumb: { name: translationPaths[paths.contentManager] } };
const contentManagerEditItemHandle: IRouteHandle = { crumb: { entityType: "ContentManagerItem", hash: "#overview" } };
const languageHookHandle: IRouteHandle = { crumb: { name: translationPaths[paths.language], hash: "#languages" } };
const languageEditHandle: IRouteHandle = { crumb: { entityType: "Language", hash: "#overview" } };
const editOrganizationHandle: IRouteHandle = { crumb: { entityType: "Organization", hash: "#projects" } };
const editTemplateHandle: IRouteHandle = { crumb: { entityType: "Template", hash: "#overview" } };
const editDataSetHandle: IRouteHandle = { crumb: { entityType: "DataSet", hash: "#overview" } };
const editApiKeyHandle: IRouteHandle = { crumb: { entityType: "ApiKey", hash: "#overview" } };
const permissionsGroupHandle: IRouteHandle = { crumb: { name: "Common.Groups", hash: "#permissions" } };
const permissionsGroupEditHandle: IRouteHandle = { crumb: { entityType: "Permissions.Group" } };
const permissionsHandle: IRouteHandle = { crumb: { name: "Common.Permissions", hash: "#permissions" } };
const contentItemPathHandle: IRouteHandle = { crumb: { searchParamPathName: contentManagerSearchParamPath } };

export const authorizedRoutes = (
    <Route element={<Authorize />} handle={homeHandle}>
        <Route path={accountPaths.route.base} element={<ProfileLayout />} handle={accountHandle}>
            <Route path={accountPaths.route.profile} element={<EditProfileView />} handle={profileHandle} />
            {/* NOTE: The path to email is temporarily disabled until its functionalities are implemented - Ref: Work item #44289. */}
            {/* <Route path={accountPaths.route.email} element={<EditEmailView />} handle={emailHandle} /> */}
            <Route path={accountPaths.route.twoFactorAuthentication} element={<Manage2FAView />} handle={twoFAHandle}>
                <Route index path={twoFactorAuthenticationPaths.route.base} element={<Manage2FA />} />
                <Route
                    path={twoFactorAuthenticationPaths.route.configure}
                    element={<Configure2FA />}
                    handle={twoFAConfigureHandle}
                />
                <Route
                    path={twoFactorAuthenticationPaths.route.resetAuthenticator}
                    element={<ResetAuthenticator />}
                    handle={twoFAResetAuthenticatorHandle}
                />
                <Route
                    path={twoFactorAuthenticationPaths.route.resetRecoveryCodes}
                    element={<ResetRecoveryCodes />}
                    handle={twoFAResetCodesHandle}
                />
                <Route
                    path={twoFactorAuthenticationPaths.route.disable}
                    element={<DisableAuthenticator />}
                    handle={twoFADisableHandle}
                />
            </Route>
            <Route index path={accountPaths.route.password} element={<EditPasswordView />} handle={passwordHandle} />
            <Route
                path={accountPaths.route.personalData}
                element={<ManagePersonalDataView />}
                handle={personalDataHandle}
            >
                <Route index element={<ManagePersonalDataForm />} />
                <Route path={personalDataPaths.route.delete} element={<PersonalDataConfirmPasswordView />} />
            </Route>
            <Route path={paths.apiKeys} handle={apiKeysHandle}>
                <Route index element={<LazyApiKeysView />} />
                <Route path={apiKeyPaths.route.edit} element={<LazyEditApiKeyView />} handle={editApiKeyHandle} />
            </Route>
            <Route
                path={accountPaths.route.externalProviders}
                element={<EditExternalLoginsView />}
                handle={externalLoginsHandle}
            />
            <Route index element={<Navigate to={accountPaths.route.profile} />} />
            <Route path="*" element={<Navigate to={accountPaths.route.profile} replace={true} />} />
        </Route>
        <Route path={paths.dataSetViewer} element={<LazyDataSetViewer isPublicDataSet={false} />} />
        <Route path={paths.project} element={<ProjectLayout />} handle={projectHandle}>
            {/* <Route path={projectPaths.route.dashboard} element={<ProjectDashboard />} handle={projectDashboardHandle} /> */}
            <Route path={dataSetPaths.route.base} handle={dataSetHandle}>
                <Route path={dataSetPaths.route.edit} element={<LazyEditDataSetView />} handle={editDataSetHandle} />
                <Route index element={<DataSetView />} />
            </Route>
            <Route path={templatePaths.route.base} handle={templateHandle}>
                <Route path={templatePaths.route.edit} element={<EditTemplateView />} handle={editTemplateHandle} />
                <Route index element={<TemplateView />} />
            </Route>
            <Route
                path={contentManagerPaths.route.base}
                handle={contentManagerHandle}
                element={<ContentManagerLayout />}
            >
                <Route handle={contentItemPathHandle}>
                    <Route
                        path={contentManagerPaths.route.editItem}
                        handle={contentManagerEditItemHandle}
                        element={<EditItemDetailsWrapper />}
                    >
                        <Route handle={permissionsHandle}>
                            <Route handle={permissionsGroupHandle}>
                                <Route
                                    path={PermissionGroupPaths.route.edit.content}
                                    handle={{ ...permissionsGroupEditHandle, scope: "content" }}
                                    element={<EditPermissionsContentGroupView />}
                                />
                            </Route>
                        </Route>
                        <Route index element={<EditItemDetailsView />} />
                    </Route>
                    <Route index element={<ContentManagerView />} />
                </Route>
            </Route>
            <Route path={projectPaths.route.settings} handle={projectSettingsHandle}>
                <Route handle={serviceHookHandle}>
                    <Route
                        path={serviceHooksPaths.route.edit}
                        element={<EditServiceHookView />}
                        handle={serviceHookEditHandle}
                    />
                </Route>
                <Route handle={languageHookHandle}>
                    <Route path={languagePaths.route.edit} element={<EditLanguageView />} handle={languageEditHandle} />
                </Route>
                <Route handle={permissionsHandle}>
                    <Route handle={permissionsGroupHandle}>
                        <Route
                            path={PermissionGroupPaths.route.edit.project}
                            handle={{ ...permissionsGroupEditHandle, scope: "project" }}
                            element={<EditPermissionsGroupView />}
                        />
                    </Route>
                </Route>
                <Route index element={<ProjectSettingsView />} />
            </Route>
            <Route index element={<ProjectDatasetsRedirect />} />
            <Route path="*" element={<ProjectDatasetsRedirect />} />
        </Route>
        <Route element={<DefaultLayout />}>
            <Route path={paths.home} element={<HomeView />} />
            <Route path={organizationPaths.route.base} handle={organizationHandle}>
                <Route
                    path={organizationPaths.route.edit}
                    handle={editOrganizationHandle}
                    element={<OrganizationProvider />}
                >
                    <Route handle={permissionsHandle}>
                        <Route handle={permissionsGroupHandle}>
                            <Route
                                path={PermissionGroupPaths.route.edit.organization}
                                handle={{ ...permissionsGroupEditHandle, scope: "organization" }}
                                element={<EditPermissionsGroupView />}
                            />
                        </Route>
                    </Route>
                    <Route index element={<EditOrganizationView />} />
                </Route>
                <Route index element={<OrganizationsView />} />
            </Route>
            <Route path={paths.applicationSettings} handle={applicationSettingsHandle}>
                <Route handle={permissionsHandle}>
                    <Route handle={permissionsGroupHandle}>
                        <Route
                            path={PermissionGroupPaths.route.edit.appSettings}
                            handle={{ ...permissionsGroupEditHandle, scope: "appSettings" }}
                            element={<EditPermissionsGroupView />}
                        />
                    </Route>
                </Route>
                <Route element={<ApplicationSettingsView />} index />
            </Route>
        </Route>
        <Route path="*" element={<Navigate to={paths.home} replace={true} />} />
    </Route>
);
