const permission = {
    read: "read",
    write: "write",
    delete: "delete",
    manage: "manageSettings",
    managePermissions: "managePermissions",
    export: "export",
    import: "import",
    share: "share",
};
const scope = {
    app: "app",
    organization: "organization",
    project: "project",
    template: "template",
    dataSet: "dataset",
    serviceHook: "servicehook",
    content: "content",
    language: "language",
};

const buildKey = (scopeKey: string, permisisonKey: string) => `${scopeKey}/${permisisonKey}`;

export const PermissionKeys = {
    app: {
        manage: buildKey(scope.app, permission.manage),
    },
    organization: {
        read: buildKey(scope.organization, permission.read),
        write: buildKey(scope.organization, permission.write),
        delete: buildKey(scope.organization, permission.delete),
    },
    project: {
        read: buildKey(scope.project, permission.read),
        write: buildKey(scope.project, permission.write),
        delete: buildKey(scope.project, permission.delete),
        managePermissions: buildKey(scope.project, permission.managePermissions),
        export: buildKey(scope.project, permission.export),
        import: buildKey(scope.project, permission.import),
    },
    template: {
        read: buildKey(scope.template, permission.read),
        write: buildKey(scope.template, permission.write),
        delete: buildKey(scope.template, permission.delete),
    },
    dataSet: {
        read: buildKey(scope.dataSet, permission.read),
        write: buildKey(scope.dataSet, permission.write),
        delete: buildKey(scope.dataSet, permission.delete),
    },
    serviceHook: {
        read: buildKey(scope.serviceHook, permission.read),
        write: buildKey(scope.serviceHook, permission.write),
        delete: buildKey(scope.serviceHook, permission.delete),
    },
    contentManager: {
        read: buildKey(scope.content, permission.read),
        write: buildKey(scope.content, permission.write),
        delete: buildKey(scope.content, permission.delete),
        share: buildKey(scope.content, permission.share),
        managePermissions: buildKey(scope.content, permission.managePermissions),
    },
    language: {
        read: buildKey(scope.language, permission.read),
        write: buildKey(scope.language, permission.write),
        delete: buildKey(scope.language, permission.delete),
    },
};
