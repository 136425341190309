import React, { useCallback, useEffect, useMemo, useState } from "react";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { CreateEntityModal } from "../modals/CreateEntityModal";
import { useUsersInvitationHook } from "../../hooks/user/UsersInvitationHook";
import { CreateUsersInvitationForm } from "./CreateUsersInvitationForm";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { IUserInvitationFormField } from "../../models/user/invitations/IUserInvitationFormField";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { UserGroupAvatar } from "../userGroups/UserGroupAvatar";
import { useGroupPermission } from "../../hooks/permission/groupPermission/GroupPermissionHook";

import "./CreateUsersInvitationModal.scss";

/**
 * The interface for the ICreateUsersInvitationModalProps.onCreated param type.
 */
export interface IOnCreatedUserInvitationParams {
    emailsCount: number;
    sentCount: number;
}
/**
 * The create users invitation modal props interface.
 */
interface ICreateUsersInvitationModalProps {
    isOpen: boolean;
    onClose: () => void;
    supportedLanguages: string[];
    onCreated: (params: IOnCreatedUserInvitationParams) => void;
    organizationId?: string;
    projectId?: string;
}

const defaultFormData: IEntityFormData<IUserInvitationFormField> = {
    data: {
        emails: [],
        language: "",
    },
    dirty: false,
    validationStatus: "Invalid",
};

/**
 * The create users invitation modal component.
 */
export const CreateUsersInvitationModal: React.FC<ICreateUsersInvitationModalProps> = ({
    isOpen,
    onClose,
    onCreated,
    projectId,
    organizationId,
    supportedLanguages,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [formData, setFormData] = useState<IEntityFormData<IUserInvitationFormField>>({ ...defaultFormData });
    const { getAllGroups } = useGroupPermission({ projectId, organizationId });
    const { createUsersInvitation } = useUsersInvitationHook({
        projectId,
        organizationId,
    });

    const [availableGroups, setAvailableGroups] = useState<SelectComponentOptions<string>[]>([]);

    const getAvailableGroups = useCallback(async () => {
        try {
            const groups = await getAllGroups();

            setAvailableGroups(
                groups.map((group) => {
                    return {
                        label: group.name,
                        value: group.groupId,
                        data: group,
                        componentIcon: <UserGroupAvatar groupName={group.name} parentType="dropdown" />,
                    };
                }),
            );
        } catch (error) {
            setErrorMessage(extractErrorMessageOrEmptyString(error));
        }
    }, [getAllGroups]);

    useEffect(() => {
        getAvailableGroups();
    }, [getAvailableGroups]);

    useEffect(() => {
        if (!isOpen) {
            setFormData(() => defaultFormData);
        }
    }, [isOpen]);

    const canCreate = useCallback(
        (): boolean => formData.validationStatus === "Valid" && formData.dirty && !isCreating,
        [formData.dirty, formData.validationStatus, isCreating],
    );

    const inviteUsers = useCallback(async () => {
        setIsCreating(true);

        try {
            const result = await createUsersInvitation({
                emails: formData.data.emails,
                groupId: formData.data.groupId!,
                language: formData.data.language,
            });
            onCreated({
                emailsCount: result.inviteCount,
                sentCount: result.inviteSent,
            });
        } catch (error) {
            setErrorMessage(extractErrorMessageOrEmptyString(error));
        } finally {
            setIsCreating(false);
        }
    }, [createUsersInvitation, formData.data.emails, formData.data.groupId, formData.data.language, onCreated]);

    const onSubmit = useCallback(async () => {
        if (canCreate()) {
            await inviteUsers();
        }
    }, [canCreate, inviteUsers]);

    const resetErrorMessage = (): void => {
        setErrorMessage("");
    };

    const modalTitle = projectId ? "UsersView.InviteUsers" : "Administration.InviteAdministrator";

    const modalName = useMemo(() => {
        let scope = "App";
        if (projectId) {
            scope = "Project";
        } else if (organizationId) {
            scope = "Organization";
        }

        return `inviteModal${scope}`;
    }, [projectId, organizationId]);

    return (
        <CreateEntityModal
            className="createUsersInvitationModal"
            titleKey={modalTitle}
            modalName={modalName}
            createLabel="UsersInvitation.Invite"
            unsavedWarningBody="UsersInvitation.UnsavedWarningBody"
            errorMessage={errorMessage}
            show={isOpen}
            canCreate={canCreate()}
            isDirty={formData.dirty}
            onCreate={onSubmit}
            onErrorMessageToggle={resetErrorMessage}
            expandable={true}
            onClose={onClose}
            isCreating={isCreating}
        >
            <CreateUsersInvitationForm
                supportedLanguages={supportedLanguages}
                availableGroups={availableGroups}
                onFormChange={setFormData}
                projectId={projectId}
                organizationId={organizationId}
            />
        </CreateEntityModal>
    );
};
