import React from "react";
import { NavItem, NavLink, NavItemProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { SideMenuItem } from "../sidebar/SideMenuItem";

import "./NavMenuItem.scss";

/**
 * The NavMenuItem props interface.
 */
export interface INavMenuItemProps extends NavItemProps {
    name: string;
    displayName: string;
    invalid?: boolean;
    dirty?: boolean;
    testSelectorValue?: string;
    onToggle: (name: string) => void;
    icon: string;
}

/**
 * The NaveMenuItem component.
 */
export const NavMenuItem: React.FC<INavMenuItemProps> = ({
    collapsed,
    name,
    icon,
    invalid,
    dirty,
    displayName,
    testSelectorValue,
    onToggle,
    ...navItemProps
}: INavMenuItemProps) => {
    const { setSelector } = useTestSelector();
    const { t } = useTranslation();

    const onTabClick = () => onToggle(name);

    return (
        <NavItem className="nav-menu-item" {...navItemProps}>
            <NavLink onClick={onTabClick}>
                <SideMenuItem error={invalid} dirty={dirty}>
                    <i className={icon} />
                    <div className={`nav-menu-item-content ${collapsed ? "collapsed" : ""}`}>
                        <span {...setSelector(testSelectorValue)}>{t(displayName)}</span>
                    </div>
                </SideMenuItem>
            </NavLink>
        </NavItem>
    );
};
