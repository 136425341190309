import React from "react";
import { InputWithCopy } from "./InputWithCopy";

interface IInputWithCopyProps {
    value: string;
    label?: string;
    displayedValueWhileCopying: string;
    testSelectorValue?: string;
    size?: string;
}

export const ReadOnlyInputWithCopy = ({
    value,
    label,
    displayedValueWhileCopying,
    testSelectorValue,
    size,
}: IInputWithCopyProps) => {
    return (
        <InputWithCopy
            name="input-with-copy"
            value={value}
            label={label}
            displayedValueWhileCopying={displayedValueWhileCopying}
            testSelectorValue={testSelectorValue}
            disabled
            size={size}
        />
    );
};
