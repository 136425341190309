import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { ServiceHookStateTranslationMap } from "../../models/serviceHooks/ServiceHookState";
import { ServiceHookTypeTranslationMap, ServiceHookType } from "../../models/serviceHooks/ServiceHookType";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { RadioComponentGroup } from "../radio/RadioComponentGroup";
import { SelectServiceHookEventType } from "./SelectServiceHookEventType";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import {
    ServiceHookEventType,
    ServiceHookEventTypeTranslationMap,
} from "../../models/serviceHooks/ServiceHookEventType";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { IWebHookConfigModel } from "../../models/serviceHooks/configs/IWebHookConfigModel";
import { useFeatureCheck } from "../../hooks/license/LicenseCheckHook";

interface IServiceHookFormFieldsProps {
    data: IServiceHookFormFields;
    onChange: <TField extends keyof IServiceHookFormFields>(
        field: TField,
        value: IServiceHookFormFields[TField],
    ) => void;
    errors: Record<string, string | JSX.Element | undefined>;
    disabled?: boolean;
}

const typeOptions = [
    {
        label: ServiceHookTypeTranslationMap[ServiceHookType.Webhook],
        value: ServiceHookType.Webhook,
        icon: "far fa-webhook",
    },
];

export const ServiceHookFormFields: React.FC<IServiceHookFormFieldsProps> = ({
    data,
    onChange,
    errors,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { checkFeature } = useFeatureCheck();

    const eventTypeOptions: SelectComponentOptions<ServiceHookEventType>[] = useMemo(() => {
        const eventTypeList: SelectComponentOptions<ServiceHookEventType>[] = [];
        const addEventType = (type: ServiceHookEventType) => {
            eventTypeList.push({
                label: t(ServiceHookEventTypeTranslationMap[type]),
                value: type,
                testSelectorValue: `${type.charAt(0).toLowerCase()}${type.slice(1)}Item`,
            });
        };

        if (checkFeature("dataSetsFeature", "enabled")) {
            ["DataSetCreated", "DataSetUpdated", "DataSetDeleted"].forEach((type) =>
                addEventType(type as ServiceHookEventType),
            );
        }

        if (checkFeature("contentFeature", "enabled")) {
            [
                "ContentFileCreated",
                "ContentFileUpdated",
                "ContentFileDeleted",
                "ContentFolderCreated",
                "ContentFolderUpdated",
                "ContentFolderDeleted",
            ].forEach((type) => addEventType(type as ServiceHookEventType));
        }

        if (checkFeature("contentFeature", "sharing")) {
            [
                "ContentFileShareCreated",
                "ContentFileShareUpdated",
                "ContentFileShareDeleted",
                "ContentFileShareAccessed",
            ].forEach((type) => addEventType(type as ServiceHookEventType));
        }

        return eventTypeList;
    }, [checkFeature, t]);

    const config = useMemo(() => JSON.parse(data.config) as IWebHookConfigModel, [data.config]);
    const [url, setUrl] = useState(() => config.serviceUri);

    useEffect(() => {
        if (url !== config.serviceUri) {
            setUrl(() => config.serviceUri);
        }
    }, [config.serviceUri, url]);

    const onChangeConfig = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newUrl = e.target.value;
            setUrl(() => {
                onChange("config", JSON.stringify({ serviceUri: newUrl }));
                return newUrl;
            });
        },
        [onChange],
    );

    return (
        <>
            <FormGroup>
                <SelectServiceHookEventType
                    value={data.eventType}
                    onChange={(v) => onChange("eventType", v)}
                    options={eventTypeOptions}
                    disabled={disabled}
                />
            </FormGroup>
            <FormGroup>
                <div>{t("Common.State")}</div>
                <FormGroup switch>
                    <Input
                        id="hook-state"
                        name="state"
                        type="switch"
                        role="switch"
                        checked={!data.state}
                        onChange={() => onChange("state", data.state ? 0 : 1)}
                        disabled={disabled}
                        {...setSelector("stateToggle")}
                    />
                    <LocalizedLabel for="hook-state" text={ServiceHookStateTranslationMap[data.state]} />
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <div className="select-hook-type">{t("Common.Type")}</div>
                <RadioComponentGroup
                    selectedValue={data.type}
                    options={typeOptions}
                    onChange={(type) => onChange("type", type)}
                    disabled={disabled}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="hook-config" text="Common.URL" required />
                <ValidatedInput
                    id="hook-config"
                    autoComplete="off"
                    name="config"
                    type="text"
                    onChange={onChangeConfig}
                    error={errors.config}
                    value={url}
                    disabled={disabled}
                    {...setSelector("urlTextbox")}
                />
            </FormGroup>
        </>
    );
};
