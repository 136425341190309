import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { ILoginModel, IUserLoginInfoModel } from "../../../models/account/IExternalProvider";

import "./EditExternalLoginsForm.scss";

/**
 * The active login button props interface.
 */
interface IActiveLoginButtonProps {
    provider: IUserLoginInfoModel;
    messageKey: string;
    removeLogin?: (login: ILoginModel) => void;
}

/**
 * The active login button component.
 */
export const ActiveLoginButton: React.FC<IActiveLoginButtonProps> = ({
    provider,
    messageKey,
    removeLogin,
}: IActiveLoginButtonProps) => {
    const { t } = useTranslation();

    const onRemoveService = useCallback(() => {
        removeLogin && removeLogin({ loginProvider: provider.loginProvider, providerKey: provider.providerKey });
    }, [removeLogin, provider.loginProvider, provider.providerKey]);

    return (
        <div tabIndex={0} className={`active-login-wrapper ${provider.providerDisplayName.toLowerCase()}`}>
            <i className={`logo fab fa-${provider.providerDisplayName.toLowerCase()}`} />
            {t(messageKey, { service: provider.providerDisplayName })}
            {removeLogin && (
                <Button
                    color="outline-secondary"
                    onClick={onRemoveService}
                    description={t("Common.Remove")}
                    ariaLabel={t("Common.Remove")}
                >
                    <i className="remove-icon fal fa-times" />
                </Button>
            )}
        </div>
    );
};
