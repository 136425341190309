import React from "react";
import { ISearchResult } from "../models/search/ISearchResult";
import { SearchEntityType } from "../models/search/SearchEntityType";
import {
    organizationPaths,
    projectPaths,
    dataSetPaths,
    dataSetSamplePaths,
    templatePaths,
    contentManagerPaths,
} from "../PathConstants";
import { EntityIcon } from "../components/icons/EntityIcon";
import { FluentFolderIcon } from "../components/icons/FluentFolderIcon";
import { SearchResultLinkItem } from "../components/search/SearchResultLinkItem";
import { SearchResultFileItem } from "../components/search/SearchResultFileItem";

interface IUseBuildSearchProjectProperties {
    onSearch: (searchValue: string) => Promise<ISearchResult[]>;
    organizationName: string;
    projectName: string;
}

const formatSearchResult = (
    { type, subType, name, entityId, parentKey, path }: ISearchResult,
    organizationName: string,
    projectName: string,
) => {
    if (type === SearchEntityType.DataSet) {
        return (
            <SearchResultLinkItem
                item={{
                    icon: <EntityIcon type={subType} />,
                    name: name,
                    entityTypeKey: "Common.DataSet",
                    link: dataSetPaths.link.edit(organizationName, projectName, entityId),
                }}
                key={entityId}
            />
        );
    }

    if (type === SearchEntityType.DataSetSample) {
        return (
            <SearchResultLinkItem
                item={{
                    icon: <EntityIcon type={subType} />,
                    name: name,
                    entityTypeKey: "Common.DataSetSample",
                    link: dataSetSamplePaths.link.edit(organizationName, projectName, parentKey!, entityId),
                }}
                key={entityId}
            />
        );
    }

    if (type === SearchEntityType.Template) {
        return (
            <SearchResultLinkItem
                item={{
                    icon: <EntityIcon type={subType} />,
                    name: name,
                    entityTypeKey: "Common.Template",
                    link: templatePaths.link.edit(organizationName, projectName, entityId),
                }}
                key={entityId}
            />
        );
    }

    if (type === SearchEntityType.File) {
        return <SearchResultFileItem name={name} entityId={entityId} key={entityId} />;
    }

    if (type === SearchEntityType.Folder) {
        return (
            <SearchResultLinkItem
                item={{
                    icon: <FluentFolderIcon size={16} />,
                    name: name,
                    entityTypeKey: "ContentManager.Folder",
                    link: contentManagerPaths.link.folder(organizationName, projectName, path!),
                }}
                key={entityId}
            />
        );
    }

    return <></>;
};

/**
 * Build the search properties for global scope.
 */
export const buildSearchGlobalProperties = (): {
    transform: (results: ISearchResult[]) => JSX.Element[];
    searchScopeKey: string;
} => {
    const transform = (results: ISearchResult[]): JSX.Element[] => {
        return results.map((element) => {
            if (element.type === SearchEntityType.Organization) {
                return (
                    <SearchResultLinkItem
                        item={{
                            icon: <EntityIcon type={element.subType} />,
                            name: element.name,
                            entityTypeKey: "EntityType.Organization",
                            link: organizationPaths.link.edit(element.entityId),
                        }}
                        key={element.entityId}
                    />
                );
            } else {
                return (
                    <SearchResultLinkItem
                        item={{
                            icon: <EntityIcon type={element.subType} />,
                            name: element.name,
                            entityTypeKey: "EntityType.Project",
                            link: projectPaths.link.dashboard(element.parentKey!, element.name),
                        }}
                        key={element.entityId}
                    />
                );
            }
        });
    };

    return {
        transform,
        searchScopeKey: "Search.Global",
    };
};

/**
 * Build the search properties for project scope.
 */
export const useBuildSearchProjectProperties = ({
    onSearch,
    organizationName,
    projectName,
}: IUseBuildSearchProjectProperties): {
    transform: (results: ISearchResult[]) => JSX.Element[];
    onSearch: (searchValue: string) => Promise<ISearchResult[]>;
    searchScopeKey: string;
} => {
    const transform = (results: ISearchResult[]): JSX.Element[] => {
        return results.map((result) => formatSearchResult(result, organizationName, projectName));
    };

    return {
        onSearch,
        transform,
        searchScopeKey: "Search.Project",
    };
};
