import React from "react";
import { useTranslation } from "react-i18next";
import { ILicenseContentFeatures } from "../../../../models/license/ILicenseFeatureModel";
import { licenseFeaturesTranslationMap, licenseSubFeaturesTranslationMap } from "../../../../helpers/LicensingHelper";
import { FeatureItem } from "./FeatureItem";
import { SubFeatureItem } from "./SubFeatureItem";

export const ContentFeature: React.FC<ILicenseContentFeatures> = ({
    enabled,
    aiDocumentTranslation,
    explicitPermissionsManagement,
    officeForTheWeb,
    sharing,
}) => {
    const { t } = useTranslation();

    return (
        <FeatureItem
            id={"contentFeature"}
            enabled={enabled}
            featureName={t(licenseFeaturesTranslationMap["contentFeature"])}
        >
            <SubFeatureItem
                enabled={aiDocumentTranslation}
                name={licenseSubFeaturesTranslationMap.aiDocumentTranslation}
            />
            <SubFeatureItem
                enabled={explicitPermissionsManagement}
                name={licenseSubFeaturesTranslationMap.explicitPermissionsManagement}
            />
            <SubFeatureItem enabled={officeForTheWeb} name={licenseSubFeaturesTranslationMap.officeForTheWeb} />
            <SubFeatureItem enabled={sharing} name={licenseSubFeaturesTranslationMap.sharing} />
        </FeatureItem>
    );
};
