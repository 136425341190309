import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { PermissionGroupPaths, paths } from "../../PathConstants";
import { useNavigation } from "../NavigationHook";
import { useGroupPermission } from "./groupPermission/GroupPermissionHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { IEditPermissionGroupModel } from "../../models/permissions/IEditPermissionGroupModel";
import { usePermissionCheck } from "./PermissionCheckHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { NotificationService } from "../../services/NotificationService";
import { permissionResourceKeyMap, useGroupPermissionData } from "./groupPermission/GroupPermissionDataHook";
import { useGroups } from "./GroupsHook";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";

interface IPermissionGroupHookProps {
    contentId?: string;
    linkToGroupListProvider?: () => string;
}

interface PermissionGroupHookResult {
    getGroup: () => Promise<IEditPermissionGroupModel>;
    group?: IEditPermissionGroupModel;
    organizationId?: string;
    projectId?: string;
    linkToGroupList: string;
    nameIsUnique: (name: string) => Promise<boolean>;
    saveGroup: (model: IEditPermissionGroupModel) => Promise<IEditPermissionGroupModel>;
    removeGroup: () => Promise<void>;
    canEdit: boolean;
}

export const useEditPermissionsGroup = ({
    contentId,
    linkToGroupListProvider,
}: IPermissionGroupHookProps): PermissionGroupHookResult => {
    const { replace } = useNavigation();
    const [group, setGroup] = useState<IEditPermissionGroupModel>();

    const projectContext = useProjectContext();
    const organizationContext = useOrganizationContext();
    const { groupId } = useParams<{ groupId: string }>();

    const organizationId = organizationContext?.organization.organizationId;
    const projectId = projectContext?.projectId;

    const { getPermissionsGroup, nameIsUnique, updateGroup } = useGroupPermission({
        projectId: projectId,
        organizationId: organizationId,
        contentId,
    });
    const { removeSingleGroup } = useGroups({ projectId, contentId });

    const { extract, serialize } = useGroupPermissionData();

    const getGroup = useCallback(() => {
        return getPermissionsGroup(groupId!).then(({ accessInfo, ...groupData }) => {
            return {
                ...groupData,
                groupPermissions: extract(accessInfo),
            };
        });
    }, [extract, getPermissionsGroup, groupId]);

    useEffect(() => {
        (async () => {
            try {
                const permissionsGroup = await getGroup();
                setGroup(permissionsGroup);
            } catch (error) {
                defaultRequestErrorHandler(error);
                replace(paths.home, {
                    state: { loadError: true },
                });
            }
        })();
    }, [groupId, replace, getGroup]);

    const linkToGroupList = useMemo(() => {
        if (linkToGroupListProvider) {
            return linkToGroupListProvider();
        }

        if (projectContext) {
            return PermissionGroupPaths.link.base.project(projectContext.organizationName, projectContext.name);
        }

        if (organizationId) {
            return PermissionGroupPaths.link.base.organization(organizationId);
        }

        return PermissionGroupPaths.link.base.appSettings();
    }, [linkToGroupListProvider, organizationId, projectContext]);

    const managePermissionKey = useMemo(
        () =>
            contentId
                ? formatPermissionKey(PermissionKeys.contentManager.managePermissions, contentId, projectId)
                : formatPermissionKey(PermissionKeys.project.managePermissions, undefined, projectId),
        [contentId, projectId],
    );

    const permissionObject = useMemo(() => {
        return { permissionKeys: [managePermissionKey] };
    }, [managePermissionKey]);

    const { isAllowed } = usePermissionCheck(permissionObject);

    const saveGroup = useCallback(
        async ({
            groupPermissions,
            ...updatedGroup
        }: IEditPermissionGroupModel): Promise<IEditPermissionGroupModel> => {
            let permissionInErrors: string[] = [];
            const result = await updateGroup({ accessInfo: serialize(groupPermissions), ...updatedGroup })
                .then((updateGroupResult) => {
                    if ("contentGroup" in updateGroupResult) {
                        permissionInErrors = updateGroupResult.permissionsInError;

                        return updateGroupResult.contentGroup;
                    }

                    return updateGroupResult;
                })
                .then(({ accessInfo, ...groupData }) => ({
                    ...groupData,
                    groupPermissions: extract(accessInfo),
                }));

            if (permissionInErrors.length) {
                const translatedPermissionsInErrors = permissionInErrors.map((permissionName) =>
                    permissionResourceKeyMap.get(permissionName),
                );
                NotificationService.addWarningNotification({
                    messageKey: "Group.SaveSuccessPartial",
                    messageKeyParams: { name: result.name, val: translatedPermissionsInErrors },
                });
            } else {
                NotificationService.addSuccessNotification({
                    messageKey: "Group.SaveSuccess",
                    messageKeyParams: { name: result.name },
                });
            }

            setGroup(result);

            return result;
        },
        [extract, serialize, updateGroup],
    );

    const removeGroup = useCallback(() => {
        if (!groupId || !projectContext) {
            NotificationService.addErrorNotification({
                messageKey: "PermissionsGroupView.DeleteFail",
            });
            return Promise.resolve();
        }

        return removeSingleGroup(groupId)
            .then(() => {
                NotificationService.addSuccessNotification({
                    messageKey: "PermissionsGroupView.DeleteSuccessSingle",
                    messageKeyParams: { name: group?.name },
                });
                replace(PermissionGroupPaths.link.base.project(projectContext.organizationName, projectContext.name), {
                    state: { deleted: true },
                });
            })
            .catch((e) => {
                defaultRequestErrorHandler(e);
            });
    }, [group?.name, groupId, projectContext, removeSingleGroup, replace]);

    const canEdit = useMemo(() => {
        const allowed = isAllowed(managePermissionKey) && !!group;

        return contentId ? allowed : allowed && !group.isSystem;
    }, [contentId, group, isAllowed, managePermissionKey]);

    return {
        group,
        getGroup,
        organizationId,
        projectId: projectContext?.projectId,
        linkToGroupList,
        nameIsUnique,
        saveGroup,
        canEdit,
        removeGroup,
    };
};
