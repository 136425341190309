import { useEffect, useMemo, useState } from "react";
import { IAddContentPermissionGroupFormFieldsModel } from "../../models/permissions/IAddContentPermissionGroupFormFieldsModel";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { AddContentPermissionGroupFormValidator } from "../../formValidators/AddContentPermissionGroupFormValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";

export const useAddContentPermissionGroupValidation = ({ groupId }: IAddContentPermissionGroupFormFieldsModel) => {
    const { errors, setError } = useEntityErrors<IAddContentPermissionGroupFormFieldsModel>();

    const [valid, setValid] = useState(false);

    const formValidator = useMemo(() => new AddContentPermissionGroupFormValidator(), []);

    useEffect(() => {
        void formValidator
            .validateGroupId(groupId)
            .then(() => setError("groupId", ""))
            .catch((err: Error) => setError("groupId", extractErrorMessageOrEmptyString(err)));
    }, [formValidator, groupId, setError]);

    useEffect(() => {
        void formValidator.isValid({ groupId }).then(setValid);
    }, [formValidator, groupId]);

    return {
        valid,
        errors,
        validationStatuses: {},
        setError,
    };
};
