import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../loading/LoadingScreen";

/**
 * The DataSet loading component.
 */
export const DataSetLoading = (): JSX.Element => {
    const { t } = useTranslation();

    return <LoadingScreen>{t("DataSet.Loading.Ellipsis")}</LoadingScreen>;
};
