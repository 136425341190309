import React from "react";
import { useTableContext } from "./TableContext";
import { ITableRowItem } from "./TableRow";
import { TableNoResultCTA } from "./TableNoResultCTA";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";

type TableNoResultCTAWithTableContextProps = Omit<Parameters<typeof TableNoResultCTA>[0], "onClick">;

export const TableNoResultCTAWithTableContext: React.FC<TableNoResultCTAWithTableContextProps> = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>(
    props: TableNoResultCTAWithTableContextProps,
) => {
    const tableContext = useTableContext<TEntityModel, TRowItem, TFilterValue>();
    const { refreshTable } = useTableRefreshContext();

    if (!tableContext) {
        return <></>;
    }

    const { onToggleCreateEntityModal, createHelper } = tableContext;

    const onClick = createHelper
        ? () => {
              if (createHelper.mode === "modal") {
                  createHelper.onCreate && createHelper.onCreate(() => refreshTable(tableContext.tableState.tableId));
              } else {
                  onToggleCreateEntityModal(true);
              }
          }
        : null;

    return <TableNoResultCTA {...props} onClick={onClick} />;
};
