import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MultiValueProps, OptionProps } from "react-select";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { IUserModel } from "../../models/user/IUserModel";
import { UserAvatar } from "../userGroups/UserAvatar";
import { SelectComponent, SelectComponentOptions } from "./SelectComponent";

import "./SelectMembers.scss";

interface ISelectMembersProps {
    onChange: (val: string[]) => void;
    value: string[];
    options: SelectComponentOptions<string>[];
    className?: string;
    labelKey: string;
    labelRequired?: boolean;
    autoFocus?: boolean;
}

type AddUserDataType = {
    value: string;
    label: string;
    data: IUserModel;
};

const customOption: React.FC<OptionProps> = (optionProps) => {
    const {
        label,
        data: { name, email, firstName, lastName, initials },
    } = optionProps.data as AddUserDataType;

    return (
        <div className="member-dropdown-option">
            <UserAvatar name={firstName && lastName ? name : email} parentType="dropdown" initials={initials} />
            <div className="text-wrapper">
                {name && <span className="member-dropdown-option__name">{name}</span>}
                <span className="member-dropdown-option__email">{label}</span>
            </div>
        </div>
    );
};

const customMultiValueLabel: React.FC<MultiValueProps> = (multiValueProps) => {
    const { name, email, firstName, lastName, initials } = (multiValueProps.data as SelectComponentOptions<string>)
        .data as IUserModel;

    return (
        <div className="member-dropdown-multi-select-item">
            <UserAvatar name={firstName && lastName ? name : email} parentType="dropdown" initials={initials} />
            <span>{`${name || email}`}</span>
        </div>
    );
};

const filterOptions = (candidate: { label: string; value: string; data: AddUserDataType }, input: string) => {
    const { name, email } = candidate.data.data;

    if (input) {
        const transformedInput = input.toUpperCase();
        return name
            ? name
                  .split(" ")
                  .map((x) => x.toUpperCase().trim())
                  .filter((x) => x)
                  .some((x) => x.includes(transformedInput)) || email.toUpperCase().includes(transformedInput)
            : email.toUpperCase().includes(transformedInput);
    }

    return true;
};

export const SelectMembers: React.FC<ISelectMembersProps> = ({
    onChange,
    value,
    options,
    className,
    labelKey,
    labelRequired = false,
    autoFocus = true,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <LocalizedLabel
                for="select-members"
                text={labelKey}
                required={labelRequired}
                aria-required={labelRequired}
            />
            <SelectComponent
                id="select-members"
                value={value}
                onChange={onChange}
                options={options}
                size="large"
                multiple
                searchable={true}
                placeholder={t("Search.Users")}
                className={classNames("select-member-component", className)}
                customOption={customOption}
                customMultiValueLabel={customMultiValueLabel}
                filterOptions={filterOptions}
                autoFocus={autoFocus}
            />
        </>
    );
};
