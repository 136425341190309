import React from "react";
import classNames from "classnames";
import { GroupBase, components, MultiValueRemoveProps } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";

interface IMultiValueRemoveProps
    extends MultiValueRemoveProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    data: SelectComponentOptions<any>;
}

export const MultiValueRemove: React.FC<IMultiValueRemoveProps> = ({ innerProps, ...props }) => {
    const { className, ...rest } = innerProps;
    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{ className: classNames(className, "multi-value-remove"), ...rest }}
        >
            <i className="fal fa-times" />
        </components.MultiValueRemove>
    );
};
