import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FoldersTree } from "./folderTree/FoldersTree";
import { FoldersTree as IFoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { useSelectFolderModal } from "../../hooks/contentManager/SelectFolderModalHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { ContentInfoBox } from "./ContentInfoBox";
import { useContentEntityApi } from "../../hooks/contentManager/ContentEntityApiHook";

import "./SelectFolderModal.scss";

interface SelectFolderModalProps {
    foldersTree: IFoldersTree;
    projectId: string;
    canConfirm: (destination?: ITreeItem | null) => boolean;
    onFormChange: (formData: IEntityFormData<ITreeItem | null | undefined>) => void;
    itemIds: string[];
    action: string;
}

export const SelectFolderModal: React.FC<SelectFolderModalProps> = ({
    foldersTree,
    projectId,
    canConfirm,
    onFormChange,
    itemIds,
    action,
}: SelectFolderModalProps) => {
    const { t } = useTranslation();
    const {
        dialogFoldersTree,
        handleTreeItem,
        selectedFolder,
        handleCollapse,
        getSubFolders,
        setItemLoadingState,
        isDisabled,
    } = useSelectFolderModal({ projectId, foldersTree, canConfirm });

    const { checkForExplicitPermissions } = useContentEntityApi({ projectId, checkParentExistsOnError: false });

    const [hasExplicitPermissions, setHasExplicitPermissions] = useState<boolean>(false);

    const [checkingPermissions, setCheckingPermissions] = useState<boolean>(false);

    useEffect(() => {
        onFormChange({
            data: selectedFolder,
            dirty: false,
            validationStatus: isDisabled ? "Invalid" : "Valid",
        });
    }, [isDisabled, onFormChange, selectedFolder]);

    const checkPermissions = useCallback(async () => {
        const result = await checkForExplicitPermissions(itemIds);

        if (result) {
            setHasExplicitPermissions(true);
        }

        setCheckingPermissions(false);
    }, [checkForExplicitPermissions, itemIds]);

    useEffect(() => {
        setCheckingPermissions(true);
        if (action !== "Copy") {
            setHasExplicitPermissions(false);
            setCheckingPermissions(false);
        }

        if (action === "Copy") {
            checkPermissions();
        }
    }, [action, checkPermissions]);

    return (
        <>
            {hasExplicitPermissions && !checkingPermissions && (
                <ContentInfoBox message={t("ContentManager.Copy.Alert", { count: itemIds.length })} />
            )}
            <h2 className="select-folder-modal-message">{t("ContentManager.SelectDestination")}</h2>
            <FoldersTree
                foldersTree={dialogFoldersTree}
                getSubFolders={getSubFolders}
                handleCollapse={handleCollapse}
                handleTreeItem={handleTreeItem}
                selectedFolderId={!selectedFolder ? selectedFolder : selectedFolder.id}
                setItemLoadingState={setItemLoadingState}
            />
        </>
    );
};
