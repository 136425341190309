import { atom } from "jotai";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { recursiveOpenStateFolder, sortFolderTree, updateParents } from "../../helpers/TreeFolderHelper";
import { removeFolders } from "../../helpers/ContentManagerFolderHelper";
import { IContentFolderUploadModel, ISubFolderModel } from "../../models/contentManager/ContentManagerApiModels";
import { foldersTreeAtom, selectedFolderIdAtom } from "../ContentManager";
import { localeAtom } from "../user";

export const addFoldersAtom = atom(null, (get, set, folders: ITreeItem[]) => {
    const foldersTree = get(foldersTreeAtom);

    // Check if folder already exists
    const newFolders = folders.filter((f) => !foldersTree.find((n) => n.id === f.id));

    let sortedFolders = sortFolderTree([...foldersTree, ...newFolders], get(localeAtom));
    sortedFolders = updateParents(sortedFolders, newFolders);

    set(foldersTreeAtom, sortedFolders);
    return sortedFolders;
});

export const removeFoldersAtom = atom(null, (get, set, ids: string[], parentId: string | null) => {
    const currentTree = [...get(foldersTreeAtom)];

    const newTree = removeFolders(ids, parentId, currentTree);

    set(foldersTreeAtom, newTree);
    return newTree;
});

export const moveFolderAtom = atom(null, (get, set, folderId: string, destinationId: string | null) => {
    let foldersTree = [...get(foldersTreeAtom)];
    const destination = destinationId === null ? null : foldersTree.find((f) => f.id === destinationId);
    const movedFolder = foldersTree.find((f) => f.id === folderId);

    if (!movedFolder) {
        return;
    }

    foldersTree = set(removeFoldersAtom, [folderId], movedFolder.parentId);

    if (destination === undefined) {
        return;
    }

    // If the parent hasn't loaded its children, we mark the parent as having children
    // since the moved folder will be loaded when first loading the children
    if (destination && !destination.areChildrenLoaded) {
        // If it was already marked, we don't need to do anything
        if (destination.hasChildren) {
            return;
        }

        const destinationIndex = foldersTree.findIndex((f) => f.id === destinationId);

        const newTree = [
            ...foldersTree.slice(0, destinationIndex),
            { ...foldersTree[destinationIndex], hasChildren: true },
            ...foldersTree.slice(destinationIndex + 1),
        ];

        set(foldersTreeAtom, newTree);
        return;
    }

    // if the parent has already loaded its children, we simply add the folder and reorder it
    set(addFoldersAtom, [
        {
            ...movedFolder,
            parentId: destinationId,
            level: destination ? destination.level + 1 : 0,
            path: `${destination?.path ?? ""}/${movedFolder.name}`,
            areChildrenLoaded: false,
            isOpen: false,
        },
    ]);
});

export const copyFolderAtom = atom(null, (get, set, newFolder: ISubFolderModel, destinationId: string | null) => {
    const foldersTree = [...get(foldersTreeAtom)];
    const destination = destinationId === null ? null : foldersTree.find((f) => f.id === destinationId);

    if (destination === undefined) {
        return;
    }

    set(addFoldersAtom, [
        {
            areChildrenLoaded: false,
            hasChildren: newFolder.hasChildren,
            id: newFolder.id,
            isLoadingChildren: false,
            isOpen: false,
            level: destination ? destination.level + 1 : 0,
            name: newFolder.name,
            parentId: destinationId,
            path: `${destination?.path ?? ""}/${newFolder.name}`,
        },
    ]);
});

export const handleUploadSummaryAtom = atom(
    null,
    (get, set, affectedFolders: IContentFolderUploadModel[], targetFolderId: string | null) => {
        const foldersTree = [...get(foldersTreeAtom)];
        const selectedFolderId = get(selectedFolderIdAtom);
        const newFolders: FoldersTree = [];

        for (const folder of affectedFolders) {
            // if existingFolder is null, it means the folder was created in the upload
            const existingFolder = foldersTree.find((n) => n && n.id === folder.contentFolderId);
            const existingFolderParent = foldersTree.find((n) => n && n.id === folder.parentContentFolderId);

            // The folder need to be added if any of these conditions is true:
            // folder doesnt exist AND is at root level
            // folder doesnt exist AND the parent is in the tree AND its children are already loaded
            // folder doesnt exist AND the parent is in the tree AND the parent doesnt have any children
            if (
                !existingFolder &&
                (folder.parentContentFolderId === null ||
                    (existingFolderParent &&
                        (existingFolderParent.areChildrenLoaded || !existingFolderParent.hasChildren)))
            ) {
                newFolders.push({
                    areChildrenLoaded: false,
                    hasChildren: folder.hasChildren,
                    id: folder.contentFolderId,
                    name: folder.name,
                    parentId: targetFolderId ?? selectedFolderId,
                    isOpen: false,
                    level: existingFolderParent ? existingFolderParent.level + 1 : 0,
                    path: existingFolderParent ? `${existingFolderParent.path}/${folder.name}` : `/${folder.name}`,
                    isLoadingChildren: false,
                });
            }

            // if it exists, make sure the hasChildren property doesn't need to be updated.
            if (existingFolder && !existingFolder.hasChildren && folder.hasChildren) {
                existingFolder.hasChildren = true;
                existingFolder.areChildrenLoaded = false;
            }
        }

        let sortedFolders = sortFolderTree([...foldersTree, ...newFolders]);
        sortedFolders = updateParents(sortedFolders, newFolders);

        set(foldersTreeAtom, sortedFolders);
        return sortedFolders;
    },
);

export const updateFolderAtom = atom(null, (get, set, folder: ITreeItem) => {
    const foldersTree = [...get(foldersTreeAtom)];
    const folderIndex = foldersTree.findIndex((f) => f.id === folder.id);

    if (folderIndex !== -1) {
        foldersTree[folderIndex] = folder;
    }

    set(foldersTreeAtom, foldersTree);
    return foldersTree;
});

export const handleCollapseAtom = atom(null, (get, set, id: string, state: boolean, closeParents: boolean = false) => {
    const foldersTree = [...get(foldersTreeAtom)];
    recursiveOpenStateFolder(foldersTree, id, state, closeParents);

    set(foldersTreeAtom, foldersTree);
    return foldersTree;
});

export const setItemLoadingStateAtom = atom(null, (get, set, id: string, isLoading: boolean) => {
    const foldersTree = [...get(foldersTreeAtom)];
    const item = foldersTree.find((f) => f.id === id);
    if (item) {
        item.isLoadingChildren = isLoading;
    }

    set(foldersTreeAtom, foldersTree);
    return foldersTree;
});
