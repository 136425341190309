import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { addFoldersAtom, updateFolderAtom } from "../../../atoms/contentManager/foldersTree";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { createNewFolders } from "../../../helpers/ContentManagerFolderHelper";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { ITreeItem } from "../../../models/contentManager/FolderTree";
import { useContentFolderApi } from "../ContentFolderApiHook";

export const useSubFolders = () => {
    const addFolders = useSetAtom(addFoldersAtom);
    const updateFolder = useSetAtom(updateFolderAtom);

    const { projectId } = useProjectContext();
    const { getSubFolders: getContentSubFolders } = useContentFolderApi(projectId);

    const getSubFolders = useCallback(
        async (item: ITreeItem) => {
            try {
                const subFolders = await getContentSubFolders(item.id);
                const newFolders = createNewFolders(item, subFolders);

                // if item has children but none from api, it should update the tree to not show the accordeon button
                if (item.hasChildren && newFolders.length === 0) {
                    updateFolder({
                        ...item,
                        hasChildren: false,
                        areChildrenLoaded: false,
                        isLoadingChildren: false,
                    });
                } else {
                    addFolders(newFolders);
                }

                return newFolders;
            } catch (error) {
                defaultRequestErrorHandler(error);
                return [];
            }
        },
        [addFolders, getContentSubFolders, updateFolder],
    );

    return { getSubFolders };
};
