import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { atom, Setter, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../formValidators/OrganizationFormValidator";
import { useTabs } from "../../hooks/TabsHook";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { EditOrganizationViewTabsOverview } from "./tabs/EditOrganizationViewTabsOverview";
import { EditOrganizationViewTabsProjects } from "./tabs/EditOrganizationViewTabsProjects";
import { TabContent } from "../tabs/TabContent";
import { UsersView } from "../users/UsersView";
import { PermissionsTabs } from "../permissions/PermissionsTabs";
import { PermissionGroupPaths } from "../../PathConstants";
import { buildLicense, buildOverview, buildPermissions, buildProjects, buildUsers } from "../../helpers/EntityHelper";
import { EditOrganizationViewTabsLicense } from "./tabs/EditOrganizationViewTabsLicense";
import { useTabFirstActivation } from "../../hooks/Tabs";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

/**
 * the list of tabs in the edit organization view
 */
const editOrganizationViewTabs = ["projects", "users", "overview", "permissions", "license"] as const;

/**
 * the list of tabs in the edit organization view as Type
 */
type EditOrganizationTabType = (typeof editOrganizationViewTabs)[number];

const defaultTab = "projects";
const activeTabAtom = atom<string>(defaultTab);

/**
 * The Edit Organization View Tabs
 */
export const EditOrganizationViewTabs = (): JSX.Element => {
    const { t } = useTranslation();
    const setActiveTabAtom = useSetAtom(activeTabAtom);
    const [activeTab, setActiveTab] = useTabs<EditOrganizationTabType>(editOrganizationViewTabs, defaultTab);

    useEffect(() => {
        setActiveTabAtom(activeTab);
    }, [activeTab, setActiveTabAtom]);

    // using useAtomCallback to check if the tab is active allows for the error handler not to be redefined
    // every time the tab changes which would cause the table to refetch the data
    const isTabActive = useAtomCallback(
        useCallback((get, _: Setter, tab: EditOrganizationTabType) => get(activeTabAtom) === tab, []),
    );

    const {
        entityProps: { entity: organization },
        dirtyProps: { isDirty },
        errorProps: { errors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const tabs: INavMenuItemProps[] = useMemo(
        () => [
            buildProjects(false, false, setActiveTab),
            buildUsers(false, false, setActiveTab),
            buildPermissions(false, false, setActiveTab),
            buildLicense(false, false, setActiveTab),
            buildOverview(!!errors.description, isDirty(["description"]), setActiveTab),
        ],
        [errors.description, isDirty, setActiveTab],
    );

    const filteredTabs = useMemo(() => tabs.filter((tab) => canEdit || tab.name !== "permissions"), [canEdit, tabs]);

    // We only load the tables if we are inside their tab otherwise if there is something stopping us from loading the data,
    // We would get an error message for every table.
    const { tabActivatedFirstTime: projectTabActivatedFirstTime } = useTabFirstActivation({
        active: activeTab === "projects",
    });
    const { tabActivatedFirstTime: usersTabActivatedFirstTime } = useTabFirstActivation({
        active: activeTab === "users",
    });
    const { tabActivatedFirstTime: permissionTabActivatedFirstTime } = useTabFirstActivation({
        active: activeTab === "permissions",
    });

    // handle error only if the current tab is active
    const usersViewErrorHandler = useCallback(
        (e: Error) => {
            if (isTabActive("users")) {
                defaultRequestErrorHandler(e);
            }
        },
        [isTabActive],
    );

    const projectsViewErrorHandler = useCallback(
        (e: Error) => {
            if (isTabActive("projects")) {
                defaultRequestErrorHandler(e);
            }
        },
        [isTabActive],
    );

    const permissionsViewErrorHandler = useCallback(
        (e: Error) => {
            if (isTabActive("permissions")) {
                defaultRequestErrorHandler(e);
            }
        },
        [isTabActive],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={filteredTabs}>
            <TabContent
                tabId="projects"
                className="projects"
                title={t("Common.Projects")}
                testSelectorValue="tabPaneProjects"
            >
                {projectTabActivatedFirstTime && (
                    <EditOrganizationViewTabsProjects
                        key={organization.organizationId}
                        customOnLoadError={projectsViewErrorHandler}
                    />
                )}
            </TabContent>
            <TabContent tabId="users" className="users" title={t("Common.Users")}>
                {usersTabActivatedFirstTime && (
                    <UsersView
                        entityName={organization.name}
                        readOnly={!canEdit}
                        organizationId={organization.organizationId}
                        canDelete={canEdit}
                        key={organization.organizationId}
                        customOnLoadError={usersViewErrorHandler}
                    />
                )}
            </TabContent>
            <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")} hasNavTabs>
                {permissionTabActivatedFirstTime && (
                    <PermissionsTabs
                        organizationId={organization.organizationId}
                        groupLink={(groupId) =>
                            PermissionGroupPaths.link.edit.organization(organization.organizationId, groupId)
                        }
                        key={organization.organizationId}
                        customOnLoadError={permissionsViewErrorHandler}
                    />
                )}
            </TabContent>
            <TabContent tabId="license" className="license" title={t("Common.License")}>
                <EditOrganizationViewTabsLicense />
            </TabContent>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <EditOrganizationViewTabsOverview />
            </TabContent>
        </NavMenu>
    );
};
