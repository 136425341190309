import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { iconSets } from "../icons/EntityIcon";
import { SelectComponent, SelectComponentOptions } from "./SelectComponent";
import { useDataSetApi } from "../../hooks/dataSet/DataSetApiHook";
import { useEditEntityContext } from "../../contexts/EditEntityContext";

interface SelectDataSetsProps {
    onChange: (val: string[]) => void;
    selectedValues: string[];
    projectId: string;
    disabled?: boolean;
}

export const SelectDataSets: React.FC<SelectDataSetsProps> = ({
    onChange,
    selectedValues,
    projectId,
    disabled,
}: SelectDataSetsProps) => {
    const [listedDataSets, setListedDataSets] = useState<SelectComponentOptions<string>[]>([]);
    const [listedDataSetsWithRestrictedAccess, setListedDataSetsWithRestrictedAccess] = useState<
        SelectComponentOptions<string>[]
    >([]);
    const [getDataSetComplete, setGetDataSetComplete] = useState(false);
    const [subscribedRefresh, setSubscribedRefresh] = useState(false);
    const { getDataSets } = useDataSetApi(projectId);
    const { t } = useTranslation();

    const editEntityContext = useEditEntityContext();

    const getListedDataSets = useCallback(async () => {
        try {
            const dataSets = await getDataSets();

            setListedDataSets(
                dataSets.map(({ name, dataSetId, type }) => {
                    return {
                        label: name,
                        value: dataSetId,
                        icon: iconSets.get(type),
                        testSelectorValue: name,
                    };
                }),
            );
            setGetDataSetComplete(true);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [getDataSets]);

    const refreshList = useCallback(() => {
        void getListedDataSets();
    }, [getListedDataSets]);

    useEffect(() => {
        if (editEntityContext && !subscribedRefresh) {
            setSubscribedRefresh(true);
            editEntityContext.refreshProps.subscribeRefresh(refreshList);
        }
    }, [editEntityContext, refreshList, subscribedRefresh]);

    useEffect(() => {
        getListedDataSets();
    }, [getListedDataSets]);

    useEffect(() => {
        if (getDataSetComplete) {
            const missingDatasets = selectedValues.reduce<SelectComponentOptions<string>[]>((acc, selectedValue) => {
                if (!listedDataSets.find((ds) => ds.value === selectedValue)) {
                    acc.push({
                        label: t("Common.RestrictedAccess"),
                        value: selectedValue,
                    });
                }

                return acc;
            }, []);
            setListedDataSetsWithRestrictedAccess([...listedDataSets, ...missingDatasets]);
        }
    }, [getDataSetComplete, listedDataSets, selectedValues, t]);

    return (
        <>
            <LocalizedLabel for="select-datasets" text="Common.DataSets" />
            <SelectComponent
                id="select-datasets"
                disabled={disabled}
                multiple
                onChange={onChange}
                options={listedDataSetsWithRestrictedAccess}
                size="large"
                searchable={true}
                testSelectorValue="datasetsDropdown"
                value={selectedValues}
            />
        </>
    );
};
