import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, InputGroup, InputProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { LocalizedLabel } from "../LocalizedLabel";
import { copiedDefaultTimeout } from "../../../Constants";
import { IValidatedInputProps, ValidatedInput } from "./ValidatedInput";

import "./InputWithCopy.scss";

interface IInputWithCopyProps extends IValidatedInputProps {
    value: string;
    name: string;
    label?: string;
    displayedValueWhileCopying: string;
    testSelectorValue?: string;
    onChange?: Pick<InputProps, "onChange">["onChange"];
    size?: string;
}

export const InputWithCopy = ({
    value,
    label,
    name,
    displayedValueWhileCopying,
    testSelectorValue,
    onChange,
    size = "md",
    ...props
}: IInputWithCopyProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => setCopied(false), copiedDefaultTimeout);
            return () => clearTimeout(timeout);
        }
    }, [copied]);

    const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (copied || !onChange) {
            return;
        }

        onChange(event);
    };

    return (
        <>
            {label && <LocalizedLabel for="input-with-copy" text={label} />}
            <InputGroup className="input-with-copy">
                <div className="input-with-copy__input-wrapper">
                    <ValidatedInput
                        name={name}
                        className="input-with-copy__input"
                        bsSize={size}
                        type="text"
                        value={copied ? t(displayedValueWhileCopying) : value}
                        readOnly={copied}
                        {...setSelector(testSelectorValue)}
                        onChange={onChangeInternal}
                        {...props}
                    />
                </div>
                <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                    <Button className="btn-copy" color="secondary" size={size} outline disabled={!value}>
                        <i className="far fa-files" />
                    </Button>
                </CopyToClipboard>
            </InputGroup>
        </>
    );
};
