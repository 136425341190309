import { useParams } from "react-router-dom";
import { ContentManagerEntityType } from "../../models/EntityType";

type ContentEntityParams = { itemId: string; type: ContentManagerEntityType };
type ContentEntityParamsResult = { contentId: string; contentType: ContentManagerEntityType };

export const useContentEntityParams = (): ContentEntityParamsResult => {
    const { itemId: contentId, type: contentType } = useParams() as ContentEntityParams;

    return {
        contentId,
        contentType,
    };
};
