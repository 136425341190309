import { useMemo } from "react";
import { PermissionGroupPaths } from "../../PathConstants";
import { PermissionGroupForm } from "../../components/permissions/PermissionGroupForm";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { NotificationService } from "../../services/NotificationService";
import { useDefaultTableCreateHelper } from "../table/DefaultTableCreateHelper";
import { useNavigation } from "../NavigationHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";
import { TableCreateHelper } from "../../components/table/Table";
import { useContentGroupApi } from "../contentManager/ContentGroupApiHook";
import { AddContentPermissionGroupForm } from "../../components/permissions/AddContentPermissionGroupForm";
import { ContentManagerEntityType } from "../../models/EntityType";

interface ProjectCreatePermissionGroupHookProps {
    canManagePermissions: boolean;
    contentId?: string;
    contentType?: ContentManagerEntityType;
    projectId: string;
}

interface ProjectCreatePermissionGroupHookResult {
    createHelper?: TableCreateHelper;
}

export const useCreatePermissionGroup = ({
    canManagePermissions,
    projectId,
    contentId,
    contentType,
}: ProjectCreatePermissionGroupHookProps): ProjectCreatePermissionGroupHookResult => {
    const project = useProjectContext();
    const { navigate } = useNavigation();
    const { createGroup: createProjectGroup } = useProjectGroupApi({ projectId });
    const { addGroup } = useContentGroupApi({ projectId, contentId: contentId! });

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: PermissionGroupForm,
        onCreated: ({ name, groupId }: IPermissionsGroupModel) => {
            NotificationService.addSuccessNotification({
                messageKey: "PermissionsGroupView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(PermissionGroupPaths.link.edit.project(project.organizationName, project.name, groupId));
        },
        onCreate: (fields) => createProjectGroup(fields),
        formProps: {
            projectId,
        },
        initialEntity: {
            name: "",
            description: null,
            userIds: [] as string[],
        },
        modalProps: {
            className: "create-permission-group-modal",
            titleKey: "CreatePermissionGroup.Title",
            unsavedWarningBody: "PermissionGroup.UnsavedWarningBody",
            size: "lg",
            expandable: true,
        },
    });

    const contentGroupCreateHelper = useDefaultTableCreateHelper({
        formComponent: AddContentPermissionGroupForm,
        onCreated: ({ name, groupId }: IPermissionsGroupModel) => {
            NotificationService.addSuccessNotification({
                messageKey: "AddPermissionsGroupView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(
                PermissionGroupPaths.link.edit.content(
                    project.organizationName,
                    project.name,
                    contentId!,
                    contentType!,
                    groupId,
                ),
            );
        },
        onCreate: ({ groupId }) => addGroup(groupId),
        formProps: {
            projectId,
            contentId,
        },
        initialEntity: {
            name: "",
            groupId: "",
        },
        modalProps: {
            className: "add-permission-group-modal",
            titleKey: "AddPermissionGroup.Title",
            unsavedWarningBody: "AddPermissionGroup.UnsavedWarningBody",
            size: "lg",
            expandable: true,
            createLabel: "Common.Add",
        },
        buttonTextKey: "Common.Add",
    });

    const createHelper = useMemo(() => {
        if (project.projectId && canManagePermissions) {
            return contentId ? contentGroupCreateHelper : defaultCreateHelper;
        }
    }, [project.projectId, canManagePermissions, contentId, contentGroupCreateHelper, defaultCreateHelper]);

    return {
        createHelper,
    };
};
