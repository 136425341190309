import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import {
    IContentEntityModel,
    ICreateFolderModel,
    ISubFolderModel,
    IUpdateContentEntityModel,
} from "../../models/contentManager/ContentManagerApiModels";
import { ISubFoldersWithPathResult } from "../../models/contentManager/ISubFoldersWithPathResult";
import { IContentHistoryModel, IHistoryModel } from "../../models/history/IHistoryModel";
import { IEntityResult } from "../../models/IEntityResult";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { contentManagerSearchParamPath } from "./ContentManagerNavigationHook";

interface ContentFolderApiHookReturn {
    create: (createFolderModel: ICreateFolderModel) => Promise<IContentEntityModel>;
    get: (folderId: string) => Promise<IContentEntityModel>;
    getSubFolders: (folderId: string | null) => Promise<ISubFolderModel[]>;
    getSubFoldersWithPath: (path: string) => Promise<ISubFoldersWithPathResult>;
    nameIsUnique: (name: string, parentFolderId: string | null) => Promise<boolean>;
    update: (folderId: string, updateFolderModel: IUpdateContentEntityModel) => Promise<IContentEntityModel>;
    getHistory: (id: string) => Promise<IContentHistoryModel[]>;
    getHistoryRecords: (
        folderId: string,
        updatedEntityDate?: string,
        offset?: number,
    ) => Promise<IEntityResult<IHistoryModel>>;
}

export const useContentFolderApi = (projectId: string): ContentFolderApiHookReturn => {
    const contentFolderUrl = useCallback(
        (folderId?: string) => `/_api/project/${projectId}/folder${folderId ? "/" + folderId : ""}`,
        [projectId],
    );

    const { httpPost, httpGetJson, httpPut } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const create = useCallback(
        (createFolderModel: ICreateFolderModel) =>
            cancellableRequest<IContentEntityModel, ICreateFolderModel>(
                { url: contentFolderUrl(), body: createFolderModel },
                httpPost,
            ),
        [cancellableRequest, httpPost, contentFolderUrl],
    );

    const get = useCallback(
        (folderId: string) => cancellableRequest<IContentEntityModel>({ url: contentFolderUrl(folderId) }, httpGetJson),
        [contentFolderUrl, cancellableRequest, httpGetJson],
    );

    const getSubFolders = useCallback(
        (folderId: string | null) =>
            cancellableRequest<ISubFolderModel[]>(
                { url: `${contentFolderUrl()}/subfolders${folderId ? "/" + folderId : ""}` },
                httpGetJson,
            ),
        [contentFolderUrl, cancellableRequest, httpGetJson],
    );

    const getSubFoldersWithPath = useCallback(
        (path: string) =>
            cancellableRequest<ISubFoldersWithPathResult>(
                {
                    url: `${contentFolderUrl()}/subfolderswithpath?${contentManagerSearchParamPath}=${encodeURIComponent(
                        path,
                    )}`,
                },
                httpGetJson,
            ),
        [contentFolderUrl, cancellableRequest, httpGetJson],
    );

    const nameIsUnique = useCallback(
        (name: string, parentFolderId: string | null) => {
            const queryParams: Record<string, string> = {
                name,
            };

            if (parentFolderId) {
                queryParams.parentFolderId = parentFolderId;
            }

            return cancellableRequest<boolean>({ url: `${contentFolderUrl()}/nameisunique`, queryParams }, httpGetJson);
        },
        [httpGetJson, cancellableRequest, contentFolderUrl],
    );

    const update = useCallback(
        (folderId: string, updateFolderModel: IUpdateContentEntityModel) =>
            cancellableRequest<IContentEntityModel, IUpdateContentEntityModel>(
                {
                    url: contentFolderUrl(folderId),
                    body: updateFolderModel,
                },
                httpPut,
            ),
        [httpPut, cancellableRequest, contentFolderUrl],
    );

    const getHistory = useCallback(
        (id: string) =>
            cancellableRequest<IContentHistoryModel[]>(
                {
                    url: `${contentFolderUrl(id)}/detailedhistory`,
                },
                httpGetJson,
            ),
        [cancellableRequest, contentFolderUrl, httpGetJson],
    );

    const getHistoryRecords = useCallback(
        (folderId: string, updatedEntityDate?: string, offset?: number) => {
            const filters: Record<string, string> = {};

            if (updatedEntityDate) {
                filters.lastUpdatedDate = updatedEntityDate;
            }

            return cancellableRequest<IEntityResult<IHistoryModel>>(
                {
                    url: `${contentFolderUrl(folderId)}/history`,
                    queryParams: normalizeQueryParams({
                        filters,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [contentFolderUrl, httpGetJson, cancellableRequest],
    );

    return {
        create,
        get,
        getSubFolders,
        getSubFoldersWithPath,
        nameIsUnique,
        update,
        getHistory,
        getHistoryRecords,
    };
};
