import React from "react";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { IVersioningModel } from "../../models/versioning/VersioningModels";

export const VersionNumber: React.FC<IVersioningModel> = ({ version, isCurrent }) => {
    const { t } = useTranslation();

    return (
        <div>
            <span>{version}</span>
            {isCurrent && <Badge color="primary">{t("Common.Current")}</Badge>}
        </div>
    );
};
