import React, { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useModalContext } from "../../contexts/ModalContext";
import { TranslateModal } from "../../components/contentManager/TranslateModal";
import { useProjectContext } from "../../contexts/ProjectContext";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { TranslateModalFooterPrimaryAction } from "../../components/contentManager/TranslateModalFooterPrimaryAction";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useOpenItem } from "./OpenItemHook";
import { useDownloadItem } from "./DownloadItemHook";
import { loadingModalStateAtom, translatableExtensionsAtom } from "../../atoms/ContentManager";
import { useFeatureCheck } from "../license/LicenseCheckHook";

export const useTranslateFile = () => {
    const { projectId } = useProjectContext();
    const { showModal, closeModal } = useModalContext();
    const { openFile } = useOpenItem();
    const { downloadFile } = useDownloadItem();
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const translatableExtensions = useAtomValue(translatableExtensionsAtom);
    const { checkFeature } = useFeatureCheck();

    const onTranslateAsyncAction = useCallback(
        (action: "Open" | "Download") => async (fileId: string, fileName: string, languageCode: string) => {
            try {
                setLoadingModalState({
                    type: "OPEN_MODAL",
                    payload: {
                        label: "ContentManager.File.TranslateLoading",
                    },
                });
                if (action === "Open") {
                    await openFile(fileId, fileName, languageCode);
                } else {
                    await downloadFile(fileId, languageCode);
                }
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                setLoadingModalState({ type: "CLOSE_MODAL" });
            }
        },
        [downloadFile, openFile, setLoadingModalState],
    );

    const translate = useCallback(
        (item: IContentEntityModel) => {
            showModal({
                contentToDisplay: TranslateModal,
                entity: {
                    language: null,
                },
                formPropsToFlow: {
                    projectId,
                },
                modalProps: {
                    onClose: closeModal,
                    className: "translate-modal",
                    titleKey: "Common.Translate",
                    size: "md",
                    primaryActionButton: (
                        <TranslateModalFooterPrimaryAction
                            item={item}
                            onItemOpen={onTranslateAsyncAction("Open")}
                            onItemDownload={onTranslateAsyncAction("Download")}
                        />
                    ),
                },
                onCreated: closeModal,
                allowCreateWhenNotDirty: false,
            });
        },
        [closeModal, onTranslateAsyncAction, projectId, showModal],
    );

    const canTranslateItem = useCallback(
        (item: IContentEntityModel) => {
            if (item.type === "Folder" || !item.size || !checkFeature("contentFeature", "aiDocumentTranslation")) {
                return false;
            }

            const fileNameSplit = item.name.split(".");
            if (fileNameSplit.length <= 1) {
                return false;
            }

            return translatableExtensions!.includes(`.${fileNameSplit.pop()!.toLowerCase()}`);
        },
        [translatableExtensions, checkFeature],
    );

    return {
        translate,
        canTranslateItem,
    };
};
