import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useVersionCommentValidation } from "../../hooks/versioning/VersionCommentValidation";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { EntityForm } from "../forms/EntityForm";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { FormattedDate } from "../date/FormattedDate";

import "./VersionCommentForm.scss";

interface IRestoreVersionCommentFormProps {
    initialValue: { comment: string };
    onFormChange: (formData: IEntityFormData<{ comment: string }>) => void;
    aboveCommentInputLabelKey: string;
    underCommentInputLabelKey?: string;
    version: number;
    createdOn: string;
    entityName: string;
}

export const RestoreVersionCommentForm = ({
    initialValue,
    onFormChange,
    aboveCommentInputLabelKey,
    underCommentInputLabelKey,
    version,
    createdOn,
    entityName,
}: IRestoreVersionCommentFormProps) => {
    const { setSelector } = useTestSelector();
    const { t } = useTranslation();

    const { data, errors, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useVersionCommentValidation,
        isDirty: ({ comment }) => comment !== initialValue.comment,
    });

    return (
        <EntityForm name="edit-version-comment" className="version-comment-form">
            <div className="version-restore">
                <span className="version-restore__version-name">{entityName}</span>
                <div className="version-restore__version-details">
                    <span>
                        {t("Common.Version")}: {version} |
                    </span>
                    <span>
                        {t("Common.CreatedOn")}: <FormattedDate date={createdOn} format="DateTime" />
                    </span>
                </div>
            </div>
            <Label for="restore-version-comment">{t(aboveCommentInputLabelKey)}</Label>
            <ValidatedInput
                className="comment-modal-input"
                autoFocus={true}
                autoComplete="off"
                name="restore-version-comment"
                type="textarea"
                {...setSelector("commentTextArea")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("comment", e.target.value)}
                error={errors.comment}
                value={data.comment}
            />
            {underCommentInputLabelKey && <span>{t(underCommentInputLabelKey)}</span>}
        </EntityForm>
    );
};
