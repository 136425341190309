import { useCallback, useMemo, useState } from "react";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { useProjectApi } from "../project/ProjectApiHook";
import { IProjectModel } from "../../models/project/IProjectModel";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { useProjectHub } from "../hubs/ProjectHub";
import { useNotificationHubContext } from "../../contexts/NotificationHubContext";
import { useInviteProjectOwnerModal } from "../user/InviteProjectOwnerModalHook";
import { useDefaultTableFilterHelper } from "../table/DefaultTableFilterHelper";
import { CreateProjectForm } from "../../components/projects/CreateProjectForm";
import { useDefaultTableCreateHelper } from "../table/DefaultTableCreateHelper";
import { ITableDeleteHelper, TableCreateHelper } from "../../components/table/Table";
import { useOrganizationApi } from "./OrganizationApiHook";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";

interface OrganizationProjectsPermissionKeys {
    projectsWriteKey: string;
    projectsDeleteKey: string;
}

interface OrganizationProjectsReturn {
    isAllowed: (permissionKey: string) => boolean;
    createHelper: TableCreateHelper | undefined;
    deleteHelper: ITableDeleteHelper<IProjectModel> | undefined;
    filterHelper: ReturnType<typeof useDefaultTableFilterHelper>;
    loadOrganizationProjects: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IProjectModel>>;
    permissionKeys: OrganizationProjectsPermissionKeys;
    setShowLoadingModal: (show: boolean) => void;
    showInviteModal: (project: IProjectModel) => void;
    showLoadingModal: boolean;
    triggerRefresh: boolean;
}

export const useOrganizationProjects = (organizationId: string): OrganizationProjectsReturn => {
    const { create, removeRecords } = useProjectApi();
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const { showInviteModal } = useInviteProjectOwnerModal();
    const { refreshTable } = useTableRefreshContext();

    const projectCompleted = useCallback(() => {
        setTriggerRefresh(false);
        setShowLoadingModal(false);
        setTriggerRefresh(true);
    }, []);

    useProjectHub({ projectCompleted });

    const { getProjectRecords } = useOrganizationApi();
    const { subscribeProjectCreation } = useNotificationHubContext();

    const onProjectCreated = useCallback(
        ({ projectId }: IProjectModel) => {
            subscribeProjectCreation(projectId);
            setShowLoadingModal(true);
            refreshTable("users");
        },
        [subscribeProjectCreation, refreshTable],
    );

    const loadOrganizationProjects = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            setTriggerRefresh(false);
            return getProjectRecords(organizationId, filterValue, sortFields, offset);
        },
        [organizationId, getProjectRecords],
    );

    const projectsWriteKey = usePermissionKey({ permission: PermissionKeys.project.write, objectId: organizationId });
    const projectsDeleteKey = usePermissionKey({ permission: PermissionKeys.project.delete, objectId: organizationId });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [projectsWriteKey, projectsDeleteKey] }),
        [projectsDeleteKey, projectsWriteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const filterHelper = useDefaultTableFilterHelper("CommonOrganizationProjects.FilterPlaceHolder");

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: CreateProjectForm,
        onCreate: create,
        onCreated: onProjectCreated,
        initialEntity: { name: "", description: "", organizationId },
        formProps: { organizationId },
        modalProps: {
            className: "createProjectModal",
            titleKey: "CreateProjectModal.Title",
            titleIconClassName: "fal fa-suitcase",
            unsavedWarningBody: "Project.UnsavedWarningBody",
            size: "md",
            expandable: false,
        },
    });

    const createHelper = useMemo(() => {
        if (!isAllowed(projectsWriteKey)) {
            return;
        }

        return defaultCreateHelper;
    }, [defaultCreateHelper, isAllowed, projectsWriteKey]);

    const deleteHelper = useMemo(() => {
        if (!isAllowed(projectsDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "Project.DeleteTitle",
            confirmationBodyMessageKey: "Project.DeleteBody",
            deleteRecords: removeRecords,
            notificationMessageKey: "Project",
        };
    }, [removeRecords, isAllowed, projectsDeleteKey]);

    return {
        isAllowed,
        permissionKeys: { projectsWriteKey, projectsDeleteKey },
        createHelper,
        deleteHelper,
        filterHelper,
        loadOrganizationProjects,
        triggerRefresh,
        showLoadingModal,
        setShowLoadingModal,
        showInviteModal,
    };
};
