import React from "react";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { UserGroupOption } from "./UserGroupOption";

import "./SelectUserGroup.scss";

interface ISelectUserGroupProps {
    availableGroups: SelectComponentOptions<string>[];
    onChange: (value: string) => void;
    value?: string;
    className?: string;
    labelKey: string;
    labelRequired?: boolean;
}

export const SelectUserGroup: React.FC<ISelectUserGroupProps> = ({
    onChange,
    availableGroups,
    value,
    className,
    labelKey,
    labelRequired = false,
}) => {
    if (availableGroups.length === 1) {
        const [group] = availableGroups;
        return (
            <div className="user-group-placeholder__wrapper">
                {group.componentIcon}
                <div className="user-group-placeholder__text">{group.label}</div>
            </div>
        );
    }

    return (
        <div>
            <LocalizedLabel
                for="select-user-group"
                text={labelKey}
                required={labelRequired}
                aria-required={labelRequired ? "true" : "false"}
            />
            <SelectComponent
                id="select-user-group"
                value={value}
                onChange={onChange}
                size="large"
                options={availableGroups}
                className={className}
                customOption={UserGroupOption}
            />
        </div>
    );
};
