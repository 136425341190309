/* eslint-disable no-console */
import { UserManager, UserManagerSettings, User, UserProfile } from "oidc-client-ts";
import { applicationPaths, applicationName } from "./ApiAuthorizationConstants";
import { extractErrorMessage, extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";

/**
 * The ISignInResponse interface.
 */
interface ISignInResponse {
    status: string;
    state?: IAuthorizationState;
    message?: string;
}

/**
 * The ISubscription interface.
 */
interface ISubscription {
    callback: () => void;
    subscription: number;
}

/**
 * The IAuthorizationState interface.
 */
export interface IAuthorizationState {
    returnUrl?: string;
}

/**
 * The claims interface.
 */
export interface IClaims {
    [keys: string]: string | undefined;
    sub: string | undefined;
    locale: string | undefined;
}

/**
 * The AuthorizeService class.
 */
export class AuthorizeService {
    private _callbacks: ISubscription[] = [];
    private _nextSubscriptionId: number = 0;
    private _user?: User | null = null;
    private _userManager?: UserManager;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    private _popUpDisabled: boolean;

    /**
     * Create an instance of AuthorizeService.
     * @param popUpDisabled The pop up disabled flag.
     */
    constructor(popUpDisabled: boolean = true) {
        this._popUpDisabled = popUpDisabled;
    }

    public static get instance(): AuthorizeService {
        return authService;
    }

    public async isAuthenticated(): Promise<boolean> {
        const user = await this.getUser();
        return !!user;
    }

    /**
     * This method returns the cached user info if exist, otherwise from the server.
     */
    public async getUser(): Promise<UserProfile | null> {
        if (this._user && this._user.profile) {
            return this._user && this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        const user = await this._userManager!.getUser();

        return user && user.profile;
    }

    /**
     * This method ensure we always have the latest claims from the server.
     */
    public async getClaimsFromServer(): Promise<IClaims | null> {
        await this.ensureUserManagerInitialized();

        const userInfoEndpointURL = await this._userManager!.metadataService.getUserInfoEndpoint();
        const userAccessToken = await authService.getAccessToken();

        if (!userAccessToken) {
            return null;
        }

        let claims: IClaims | undefined = undefined;

        try {
            claims = await this.getClaims(userInfoEndpointURL, userAccessToken);
        } catch {
            // The user is connected to the app but the token has expired.
            // At the moment, there is a bug with the refresh token.
            // The only way to bypass this for now is to return undefined.
            // The authentication will resume.
        }

        return Promise.resolve({
            sub: claims?.sub,
            locale: claims?.locale,
            name: claims?.name,
        });
    }

    public async getAccessToken(): Promise<string | null> {
        await this.ensureUserManagerInitialized();
        const user = await this._userManager!.getUser();

        return user && user.access_token;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    public async signIn(state: IAuthorizationState): Promise<ISignInResponse> {
        await this.ensureUserManagerInitialized();
        try {
            const silentUser = await this._userManager!.signinSilent();
            this.updateState(silentUser);

            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
                    );
                }

                const popUpUser = await this._userManager!.signinPopup();
                this.updateState(popUpUser);

                return this.success(state);
            } catch (popUpError) {
                const popUpErrorMessage = extractErrorMessage(popUpError);

                if (popUpErrorMessage === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    const searchParams = new URLSearchParams(window.location.search);
                    const returnUrl = searchParams.get("returnUrl");

                    await this._userManager!.signinRedirect({
                        redirectMethod: "replace",
                        state: {
                            returnUrl,
                        },
                    });

                    return this.redirect();
                } catch (redirectError) {
                    console.log("Redirect authentication error: ", redirectError);

                    return this.error(extractErrorMessageOrEmptyString(redirectError));
                }
            }
        }
    }

    public async completeSignIn(url: string): Promise<ISignInResponse> {
        try {
            await this.ensureUserManagerInitialized();

            const user = (await this._userManager!.signinCallback(url)) || null;
            this.updateState(user);

            return {
                status: AuthenticationResultStatus.success,
                state: user?.state as IAuthorizationState,
            };
        } catch (error) {
            console.log("There was an error signing in: ", error);

            return this.error("There was an error signing in.");
        }
    }

    public async refreshToken() {
        try {
            await this._userManager!.signinSilent();
        } catch (error) {
            console.error("Token refresh error:", error);

            return this.error("There was an error in token refresh.");
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    public async signOut(state: IAuthorizationState): Promise<ISignInResponse> {
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
                );
            }

            await this._userManager!.signoutPopup();
            this.updateState();

            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);

            try {
                await this._userManager!.signoutRedirect();

                return this.redirect();
            } catch (redirectSignOutError) {
                console.log("Redirect signout error: ", redirectSignOutError);

                return this.error(redirectSignOutError as string);
            }
        }
    }

    public async completeSignOut(url: string): Promise<ISignInResponse> {
        await this.ensureUserManagerInitialized();
        try {
            await this._userManager!.signoutCallback(url);
            this.updateState(null);

            return {
                status: AuthenticationResultStatus.success,
            };
        } catch (error) {
            const errorMessage = extractErrorMessageOrEmptyString(error);
            console.log(`There was an error trying to log out '${errorMessage}'.`);

            return this.error(errorMessage);
        }
    }

    public subscribe(callback: () => void): number {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });

        return this._nextSubscriptionId - 1;
    }

    public unsubscribe(subscriptionId: number): void {
        const subscriptionIndex = this._callbacks
            .map((element, index) =>
                element.subscription === subscriptionId ? { found: true, index } : { found: false },
            )
            .filter((element) => element.found);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    public async getClaims<T>(url: string, userAccessToken: string): Promise<T> {
        const headers = new Headers({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${userAccessToken}`,
        });
        const response = await fetch(url, { headers, method: "GET" });

        if (response.ok) {
            return (await response.json()) as T;
        }

        throw new Error();
    }

    private updateState(user?: User | null): void {
        this._user = user;
        this.notifySubscribers();
    }

    private notifySubscribers(): void {
        this._callbacks.forEach((callback) => callback.callback());
    }

    private error(message: string): ISignInResponse {
        return {
            message,
            status: AuthenticationResultStatus.fail,
        };
    }

    private success(state: IAuthorizationState): ISignInResponse {
        return {
            state,
            status: AuthenticationResultStatus.success,
        };
    }

    private redirect(): ISignInResponse {
        return {
            status: AuthenticationResultStatus.redirect,
        };
    }

    private async ensureUserManagerInitialized(): Promise<void> {
        if (this._userManager !== undefined) {
            return;
        }

        const response = await fetch(applicationPaths.apiAuthorizationClientConfigurationUrl);
        if (!response.ok) {
            throw new Error(`Could not load settings for '${applicationName}'`);
        }

        const settings = (await response.json()) as UserManagerSettings;
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.revokeTokensOnSignout = true;

        this._userManager = new UserManager(settings);

        this._userManager.events.addUserSignedOut(
            () =>
                void (async () => {
                    await this._userManager!.removeUser();
                    this.updateState();
                })(),
        );
    }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    redirect: "redirect",
    success: "success",
    fail: "fail",
};
