import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import { Moment } from "moment";
import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { LocalizedLabel } from "./LocalizedLabel";
import { ValidatedDateTime } from "./inputs/ValidatedDateTime";
import { RadioComponentGroup } from "../radio/RadioComponentGroup";
import { NullableValue } from "../../models/NullableValue";
import { useUserContext } from "../../contexts/UserContext";
import { ITimeZoneModel } from "../../models/account/ITimeZoneModel";
import { TimeZoneExpirationDateType, ExpirationDateTypeTranslationMap } from "../../models/DateWithTimeZone";

import "./DateWithTimezoneSelect.scss";

interface IDateWithTimeZoneProps {
    dateError?: string | JSX.Element;
    defaultState: {
        date: moment.Moment;
        defaultTimeZoneId?: string;
        timeZoneExpirationDateType: TimeZoneExpirationDateType;
    };
    dateFormat?: string | false;
    timeFormat?: string | false;
    dateTestSelectorValue?: string;
    timeZoneTestSelectorValue?: string;
    onDateChange: (value: DateChangeValue) => void;
}

export interface TimeZoneValue {
    timeZoneId: string;
    offset: number;
}

export type DateChangeValue = null | {
    date: moment.Moment | string;
    timeZone: TimeZoneValue;
};

const buildOptions = (options: ITimeZoneModel[]) => {
    return options.map<SelectComponentOptions<string>>((item) => ({
        label: item.value.displayName,
        value: item.key,
    }));
};

export const getDefaultTimeZone = (
    defaultTimeZoneId: string | undefined,
    userTimeZoneId: NullableValue<string>,
    timeZones: ITimeZoneModel[],
) => {
    if (defaultTimeZoneId) {
        const defaultTimeZone = timeZones.find((x) => x.key === defaultTimeZoneId);
        if (defaultTimeZone) {
            return {
                timeZoneId: defaultTimeZone.key,
                offset: defaultTimeZone.value.offset,
            };
        }
    }

    if (userTimeZoneId) {
        const userTimeZone = timeZones.find((x) => x.key === userTimeZoneId);
        if (userTimeZone) {
            return {
                timeZoneId: userTimeZone.key,
                offset: userTimeZone.value.offset,
            };
        }
    }

    const browserTimeZoneFromMoment = moment.tz.guess();

    const browserTimeZone = timeZones.find((x) => x.value.offset === moment.tz(browserTimeZoneFromMoment).utcOffset());
    if (browserTimeZone) {
        return {
            timeZoneId: browserTimeZone.key,
            offset: browserTimeZone.value.offset,
        };
    }

    return {
        timeZoneId: browserTimeZoneFromMoment,
        offset: moment.tz(browserTimeZoneFromMoment).utcOffset(),
    };
};

const expirationDateTypeOptions = [
    {
        label: ExpirationDateTypeTranslationMap[TimeZoneExpirationDateType.Never],
        value: TimeZoneExpirationDateType.Never,
        testSelectorValue: "neverRadioButton",
    },
    {
        label: ExpirationDateTypeTranslationMap[TimeZoneExpirationDateType.Custom],
        value: TimeZoneExpirationDateType.Custom,
        testSelectorValue: "customRadioButton",
    },
];

export const DateWithTimeZoneSelect: React.FC<IDateWithTimeZoneProps> = (props: IDateWithTimeZoneProps) => {
    const { t } = useTranslation();

    const {
        dateError,
        dateFormat,
        timeFormat,
        onDateChange,
        defaultState: { date, timeZoneExpirationDateType, defaultTimeZoneId: defaultTimezoneId },
        dateTestSelectorValue,
        timeZoneTestSelectorValue,
    } = props;
    const { userTimeZone, availableTimeZones } = useUserContext();

    const timeZonesOptions = useMemo(() => buildOptions(availableTimeZones), [availableTimeZones]);
    const [expirationDateType, setExpirationDateType] = useState(timeZoneExpirationDateType);
    const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZoneValue>(
        getDefaultTimeZone(defaultTimezoneId, userTimeZone, availableTimeZones),
    );
    const [selectedDate, setSelectedDate] = useState<moment.Moment | string>(date);

    const onTimeZoneChange = (timeZoneId: string) => {
        const timeZone = availableTimeZones.find((x) => x.key === timeZoneId);
        if (timeZone) {
            setSelectedTimeZone({
                timeZoneId: timeZone.key,
                offset: timeZone.value.offset,
            });
        }
    };

    useEffect(() => {
        if (expirationDateType === TimeZoneExpirationDateType.Never) {
            onDateChange(null);
            return;
        }

        onDateChange({
            date: selectedDate,
            timeZone: selectedTimeZone,
        });
    }, [onDateChange, selectedDate, selectedTimeZone, expirationDateType]);

    return (
        <div className="select-date-timezone">
            <div>{t("Common.ExpirationDate")}</div>
            <div
                className={classNames("select-date-timezone__is-never", {
                    "add-margin": !!dateError,
                })}
            >
                <RadioComponentGroup
                    selectedValue={expirationDateType}
                    options={expirationDateTypeOptions}
                    onChange={(value) => {
                        setExpirationDateType(value);
                    }}
                />
            </div>
            {expirationDateType === TimeZoneExpirationDateType.Custom && (
                <div className="select-date-timezone__date-wrapper">
                    <div className="select-date-timezone__date">
                        <LocalizedLabel
                            check
                            className="select-expiration__date-label"
                            for="date-with-timezone-date-picker"
                            text="Common.ExpirationDate"
                            hidden
                        />
                        <ValidatedDateTime
                            id="date-with-timezone-date-picker"
                            closeOnSelect
                            key="expirationdate"
                            value={selectedDate}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            error={dateError}
                            onChange={setSelectedDate}
                            testSelectorValue={dateTestSelectorValue}
                            isValidDate={(currentDate: Moment | string) => {
                                const today: Moment | string = moment();
                                return moment(currentDate).isAfter(today);
                            }}
                        />
                    </div>
                    <div className="select-date-timezone__timezone">
                        <div className="select-date-timezone__timezone-label-wrapper">
                            <LocalizedLabel
                                check
                                className="select-date-timezone__timezone-label"
                                for="date-timezone-select"
                                text="Common.Timezone"
                                hidden
                            />
                        </div>
                        <SelectComponent
                            onChange={onTimeZoneChange}
                            id="date-timezone-select"
                            className={classNames("select-date-timezone__timezone-select")}
                            options={timeZonesOptions}
                            value={selectedTimeZone.timeZoneId}
                            testSelectorValue={timeZoneTestSelectorValue}
                            size="large"
                        />
                    </div>
                    <i id="select-date-timezone__timezone-tooltip" className="fa fa-question-circle text-blue" />
                    <UncontrolledTooltip placement="top" target="select-date-timezone__timezone-tooltip">
                        {t("Common.TimeZoneTooltip")}
                    </UncontrolledTooltip>
                </div>
            )}
        </div>
    );
};
