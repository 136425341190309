import React, { useMemo } from "react";
import classNames from "classnames";
import { Label } from "reactstrap";
import { PermissionAccess } from "../../models/permissions/PermissionAccess";
import { SelectComponent, SelectComponentOptions } from "./SelectComponent";
import { FormFeedbackError } from "../forms/feedbacks/FormFeedbackError";
import { useTranslateSelectComponentOptions } from "../../hooks/TranslateSelectComponentOptionsHook";

interface ISelectPermissionProps {
    readonly?: boolean;
    value: PermissionAccess;
    onChange: (value?: PermissionAccess) => void;
    label: string;
    error?: string | JSX.Element;
    inherited?: boolean;
}

export const selectPermissionsOptions: SelectComponentOptions<PermissionAccess>[] = [
    {
        value: PermissionAccess.Allow,
        label: "Permissions.State.Allow",
        icon: "fal fa-check",
        className: "text-success",
    },
    {
        value: PermissionAccess.Deny,
        label: "Permissions.State.Deny",
        icon: "fal fa-ban",
        className: "text-danger",
    },
    {
        value: PermissionAccess.NotSet,
        label: "Permissions.State.NotSet",
    },
];

const inheritedOffset = selectPermissionsOptions.length;

export const SelectPermission: React.FC<ISelectPermissionProps> = ({
    label,
    readonly,
    value,
    onChange,
    error,
    inherited,
}) => {
    const currentOptions = useMemo(() => {
        const currentOption = selectPermissionsOptions.find((o) => o.value === value);

        return inherited && currentOption
            ? [
                  ...selectPermissionsOptions,
                  {
                      value: (currentOption.value + inheritedOffset) as PermissionAccess,
                      label: `${currentOption.label}.Inherited`,
                      icon: currentOption.icon,
                      className: currentOption.className,
                  },
              ]
            : selectPermissionsOptions;
    }, [inherited, value]);

    const translatedOptions = useTranslateSelectComponentOptions(currentOptions);

    return (
        <>
            <Label for="select-permission">{label}</Label>

            <SelectComponent
                id="select-permission"
                options={translatedOptions}
                value={inherited ? value + inheritedOffset : value}
                disabled={readonly}
                onChange={onChange}
                className={classNames({ "is-invalid": !!error })}
                hideSelectedOptions={inherited}
            />
            {error && <FormFeedbackError error={error} />}
        </>
    );
};
