import { useCallback, useMemo } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { NativeTypes } from "react-dnd-html5-backend";
import {
    IContentEntityModel,
    ContentEntityType,
    ContentEntityIdType,
} from "../../models/contentManager/ContentManagerApiModels";
import { NotificationService } from "../../services/NotificationService";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import {
    ITableDeleteHelper,
    ITableDragAndDropHelper,
    ITableRowDragAndDropProps,
    TableCreateHelper,
} from "../../components/table/Table";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { useContentEntityApi } from "./ContentEntityApiHook";
import { useDefaultTableCreateHelper } from "../table/DefaultTableCreateHelper";
import { CreateContentFolderForm } from "../../components/contentManager/CreateContentFolderForm";
import { useContentFolderApi } from "./ContentFolderApiHook";
import { useContentManagerDropHandler } from "./ContentManagerDropHandlerHook";
import { loadingModalStateAtom, selectedFolderIdAtom } from "../../atoms/ContentManager";
import { useUpload } from "./UploadHook";
import { addFoldersAtom, handleUploadSummaryAtom, removeFoldersAtom } from "../../atoms/contentManager/foldersTree";
import { useFolderTreeAccessor } from "./folderTree/folderTreeAccessorHook";

interface IContentManagerTableHelperHookProps {
    projectId: string;
    tableId: string;
}

interface IContentManagerTableHelperHookResult {
    deleteHelper: ITableDeleteHelper<IContentEntityModel> | undefined;
    uploadHelper: (fileList: File[], targetFolderId?: string) => Promise<void>;
    createHelper: TableCreateHelper | undefined;
    onCreated: (item: IContentEntityModel) => void;
    dragAndDropHelper: ITableDragAndDropHelper;
    rowDragAndDropProvider: (item: IContentEntityModel) => ITableRowDragAndDropProps;
}

const divideIdsPerContentType = (ids: string[], contentEntities: IContentEntityModel[]): ContentEntityIdType[] => {
    return ids.map((id) => {
        const index = contentEntities.findIndex((c) => c.id === id);
        return {
            id,
            type: contentEntities[index].type === "Folder" ? ContentEntityType.Folder : ContentEntityType.File,
        };
    });
};

export const useContentManagerTableHelper = ({
    projectId,
    tableId,
}: IContentManagerTableHelperHookProps): IContentManagerTableHelperHookResult => {
    const { refreshTable } = useTableRefreshContext();
    const { removeRecords } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });
    const { create: createFolder } = useContentFolderApi(projectId);
    const removeFolders = useSetAtom(removeFoldersAtom);
    const addFolders = useSetAtom(addFoldersAtom);
    const handleUploadSummary = useSetAtom(handleUploadSummaryAtom);
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const { uploadFiles, onCancel, handleUploadSummaryNotification, validateFiles } = useUpload(projectId);

    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const { getFoldersTree } = useFolderTreeAccessor();

    const contentReadKey = usePermissionKey({ permission: PermissionKeys.contentManager.read, projectId });
    const contentWriteKey = usePermissionKey({ permission: PermissionKeys.contentManager.write, projectId });
    const contentDeleteKey = usePermissionKey({ permission: PermissionKeys.contentManager.delete, projectId });
    const permissionsObject = useMemo(
        () => ({ permissionKeys: [contentReadKey, contentWriteKey, contentDeleteKey] }),
        [contentDeleteKey, contentReadKey, contentWriteKey],
    );
    const { isAllowed } = usePermissionCheck(permissionsObject);

    const deleteRecords = useCallback(
        (ids: string[], records: IContentEntityModel[]) => {
            const entitiesToDelete = divideIdsPerContentType(ids, records);

            return removeRecords(entitiesToDelete);
        },
        [removeRecords],
    );

    const uploadHelper = useCallback(
        async (fileList: File[], targetFolderId?: string) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }

            const validateResult = validateFiles(fileList);

            if (!validateResult) {
                return;
            }

            const { errorInvalidCharacterCount, validFiles } = validateResult;

            setLoadingModalState({
                type: "OPEN_MODAL",
                payload: { label: "Upload.Loading", onCancel },
            });

            try {
                const target = targetFolderId ?? selectedFolderId;
                const summary = await uploadFiles(validFiles, target);

                if (isAllowed(contentReadKey)) {
                    handleUploadSummary(summary.payload.affectedFolders, target);
                    refreshTable(tableId);
                }

                handleUploadSummaryNotification(summary, errorInvalidCharacterCount);
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                setLoadingModalState({ type: "CLOSE_MODAL" });
            }
        },
        [
            isAllowed,
            contentWriteKey,
            validateFiles,
            setLoadingModalState,
            onCancel,
            selectedFolderId,
            uploadFiles,
            contentReadKey,
            handleUploadSummary,
            refreshTable,
            tableId,
            handleUploadSummaryNotification,
        ],
    );

    const deleteHelper = useMemo<ITableDeleteHelper<IContentEntityModel> | undefined>(() => {
        if (!isAllowed(contentDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "Common.Delete.DeleteTitle",
            confirmationBodyMessageKey: "Common.Delete.DeleteMessage",
            deleteRecords,
            notificationMessageKey: "ContentManager",
            onDeleted: (deletedIds) => {
                removeFolders(deletedIds, selectedFolderId);
            },
        };
    }, [contentDeleteKey, deleteRecords, isAllowed, removeFolders, selectedFolderId]);

    const onCreated = useCallback(
        (item: IContentEntityModel) => {
            NotificationService.addSuccessNotification({
                messageKey: "ContentManager.Folder.CreationSuccess",
                messageKeyParams: { name: item.name },
            });

            if (isAllowed(contentReadKey)) {
                const parentFolder = getFoldersTree().find((n) => n && n.id === selectedFolderId);

                addFolders([
                    {
                        areChildrenLoaded: false,
                        hasChildren: false,
                        id: item.id,
                        name: item.name,
                        parentId: selectedFolderId,
                        isOpen: false,
                        level: parentFolder ? parentFolder.level + 1 : 0,
                        path: parentFolder ? `${parentFolder.path}/${item.name}` : `/${item.name}`,
                        isLoadingChildren: false,
                    },
                ]);
            }
        },
        [isAllowed, contentReadKey, getFoldersTree, addFolders, selectedFolderId],
    );

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: CreateContentFolderForm,
        onCreated: onCreated,
        onCreate: ({ name }) => createFolder({ name, parentFolderId: selectedFolderId }),
        formProps: {},
        initialEntity: {
            name: "",
            projectId,
            parentFolderId: selectedFolderId,
        },
        modalProps: {
            className: "createContentFolderModal",
            titleKey: "ContentManager.Folder.CreationTitle",
            unsavedWarningBody: "ContentManager.Folder.UnsavedWarningBody",
            size: "md",
            expandable: false,
        },
        buttonTextKey: "ContentManager.Folder",
    });

    const createHelper = useMemo(() => {
        if (!isAllowed(contentWriteKey)) {
            return;
        }

        return defaultCreateHelper;
    }, [defaultCreateHelper, contentWriteKey, isAllowed]);

    const { handleFileDrop, handleMoveDrop } = useContentManagerDropHandler({
        handleUpload: uploadHelper,
        tableId,
    });

    const rowDragAndDropProvider = useCallback(
        (item: IContentEntityModel): ITableRowDragAndDropProps => {
            const allowed = isAllowed(contentWriteKey);
            const type = "ContentEntity";
            return {
                dropProps: {
                    enabled: item.type === "Folder" && allowed,
                    supportedDrops: [NativeTypes.FILE, type],
                    onDrop: (dropValue: DataTransfer | IContentEntityModel[], itemType) => {
                        itemType === NativeTypes.FILE
                            ? void handleFileDrop(dropValue as DataTransfer, item.id)
                            : handleMoveDrop(dropValue as IContentEntityModel[], item.id);
                    },
                },
                dragProps: {
                    enabled: allowed,
                    itemType: type,
                },
            };
        },
        [isAllowed, contentWriteKey, handleFileDrop, handleMoveDrop],
    );

    const dragAndDropHelper: ITableDragAndDropHelper = useMemo(
        () => ({
            enabled: isAllowed(contentWriteKey),
            onDrop: (dropValue: DataTransfer) => handleFileDrop(dropValue),
            supportedDrops: [NativeTypes.FILE],
        }),
        [contentWriteKey, handleFileDrop, isAllowed],
    );

    return {
        deleteHelper,
        uploadHelper,
        createHelper,
        onCreated,
        dragAndDropHelper,
        rowDragAndDropProvider,
    };
};
