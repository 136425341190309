import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { projectPaths, paths } from "../../PathConstants";
import { useFeatureCheck } from "../../hooks/license/LicenseCheckHook";
import { featuresStateAtom } from "../../hooks/license/LicenseFeatureHook";
import { ProjectLoading } from "./ProjectLoading";
import { useAtomValue } from "jotai";
import { NotificationService } from "../../services/NotificationService";

/**
 * The project dashboard redirect component.
 */
export const ProjectDatasetsRedirect: React.FC = () => {
    const { organizationName, projectName } = useParams<{ organizationName: string; projectName: string }>();
    const { checkFeature } = useFeatureCheck();
    const featuresState = useAtomValue(featuresStateAtom);

    // Before redirecting, we check if we have access to the feature. If no feature is enabled, we redirect to the home page.
    const redirectMemoized = useMemo(() => {
        if (!featuresState.features || featuresState.isLoadingFeatures) {
            return null;
        }
        if (checkFeature("dataSetsFeature", "enabled")) {
            return projectPaths.link.dataSets(organizationName!, projectName!);
        }

        if (checkFeature("templatesFeature", "enabled")) {
            return projectPaths.link.templates(organizationName!, projectName!);
        }

        if (checkFeature("contentFeature", "enabled")) {
            return projectPaths.link.contentManager(organizationName!, projectName!);
        }

        NotificationService.addErrorNotification({ messageKey: "Check.LicenseFeatureMissing" });
        return paths.home;
    }, [checkFeature, featuresState.features, featuresState.isLoadingFeatures, organizationName, projectName]);

    return redirectMemoized === null ? <ProjectLoading /> : <Navigate to={redirectMemoized} replace={true} />;
};
