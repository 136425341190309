import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { ITableDeleteHelper, Table } from "../table/Table";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useGroups } from "../../hooks/permission/GroupsHook";
import { PermissionGroupNameColumnContent } from "./PermissionGroupNameColumnContent";
import { TabPanel } from "../tabs/TabPanel";
import { useCreatePermissionGroup } from "../../hooks/permission/CreatePermissionGroup";
import { ITableRowItem } from "../table/TableRow";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { ITableContextModel } from "../table/TableContext";
import { AlertPopover } from "../language/AlertPopover";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { ContentManagerEntityType, EntityType } from "../../models/EntityType";
import {
    GroupScope,
    IPermissionsGroupFilter,
    groupFilterProvider,
    partialPermissionGroupColumnDefs,
} from "./PermissionsGroupView";

import "./PermissionsGroupView.scss";

interface IPermissionsGroupViewProps {
    projectId: string;
    contentId?: string;
    contentType?: ContentManagerEntityType;
    groupLink: (groupId: string) => string;
}

type DeleteOverrideProps = { selectedItems: IPermissionsGroupModel[] };
const deleteHelperDeleteOverride = ({ selectedItems }: DeleteOverrideProps) => {
    const systemGroup = selectedItems.find((l) => l.isSystem);
    if (systemGroup) {
        return (
            <AlertPopover
                messageKey="Group.DefaultDeleteWarning"
                messageParams={{ name: systemGroup.name }}
                alertId={systemGroup.groupId}
            />
        );
    }

    return null;
};

export const ProjectPermissionsGroupView: React.FC<IPermissionsGroupViewProps> = ({
    projectId,
    contentId,
    groupLink,
    contentType,
}: IPermissionsGroupViewProps) => {
    const { t } = useTranslation();
    const { getGroups, removeGroups, removeSingleGroup } = useGroups({ projectId, contentId });
    const scope: GroupScope = useMemo(() => (contentId ? "Content" : "Project"), [contentId]);

    const tableId = useMemo(
        () => `${scope}-${contentId || projectId}-permissions-group`,
        [projectId, contentId, scope],
    );

    const columnDefs: IEntityTableColumnDef[] = useMemo(() => {
        const getNameColumnRenderContent = ({ name, groupId }: IPermissionsGroupModel) => (
            <PermissionGroupNameColumnContent name={name} groupId={groupId} groupLink={groupLink} />
        );

        return [
            {
                type: "JSX",
                content: getNameColumnRenderContent,
                fieldName: "name",
                shouldTruncateText: true,
                displayName: "Common.Name",
                className: "name",
                sortField: {
                    name: "Name",
                    order: SortOrder.Asc,
                },
            },
            ...partialPermissionGroupColumnDefs,
        ];
    }, [groupLink]);

    const managePermissionKey = usePermissionKey({
        permission: contentId
            ? PermissionKeys.contentManager.managePermissions
            : PermissionKeys.project.managePermissions,
        objectId: contentId,
        projectId,
    });

    const permissionKeys = useMemo(() => ({ permissionKeys: [managePermissionKey] }), [managePermissionKey]);

    const { isAllowed } = usePermissionCheck(permissionKeys);

    const canManagePermissions = useMemo(() => isAllowed(managePermissionKey), [managePermissionKey, isAllowed]);
    const { createHelper } = useCreatePermissionGroup({ canManagePermissions, projectId, contentId, contentType });

    const deleteHelper = useMemo((): ITableDeleteHelper<IPermissionsGroupModel> | undefined => {
        if (canManagePermissions) {
            return {
                confirmationTitleMessageKey: contentId ? "ContentGroup.RemoveTitle" : "CommonGroup.DeleteTitle",
                confirmationBodyMessageKey: contentId ? "ContentGroup.RemoveBody" : "CommonGroup.DeleteBody",
                deleteRecords: removeGroups,
                notificationMessageKey: contentId ? "ContentPermissionsGroupView" : "PermissionsGroupView",
                singleDelete: removeSingleGroup,
                deleteOverride: !contentId ? deleteHelperDeleteOverride : undefined,
                entityType: "Permissions.Group" as EntityType,
                deleteLabel: t(contentId ? "Common.Remove" : "Common.Delete"),
                buttonTextKey: contentId ? "Common.Remove" : "Common.Delete",
                buttonIcon: classNames("fal", { "fa-minus": contentId, "fa-trash-alt": !contentId }),
            };
        }
    }, [canManagePermissions, contentId, removeGroups, removeSingleGroup, t]);

    const groupsFilterHelper = useMemo(() => groupFilterProvider(scope), [scope]);

    const actionProvider = useCallback(
        (
            item: IPermissionsGroupModel,
            context: ITableContextModel<
                IPermissionsGroupModel,
                ITableRowItem<IPermissionsGroupModel>,
                IPermissionsGroupFilter
            >,
        ): ITableRowActionProps[] => {
            const canEdit = canManagePermissions && (!!contentId || !item.isSystem);
            const actions: ITableRowActionProps[] = [
                {
                    iconClassName: canEdit ? "fal fa-pencil" : "fal fa-eye",
                    label: canEdit ? "Common.Edit" : "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: groupLink(item.groupId),
                },
            ];

            if (canEdit) {
                actions.push({
                    iconClassName: contentId ? "fal fa-minus" : "fal fa-trash-alt",
                    label: contentId ? "Common.Remove" : "Common.Delete",
                    testSelectorValue: contentId ? "removeItem" : "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.groupId]),
                    style: { color: colorStyles.red },
                    separated: true,
                });
            }

            return actions;
        },
        [canManagePermissions, contentId, groupLink],
    );

    const noResultsCTAProps = useMemo(() => {
        if (contentId) {
            return {
                createTitle: "Common.Add",
                description: "ContentGroup.NoResultsCTADescription",
                title: "ContentGroup.Title",
            };
        }
    }, [contentId]);

    return (
        <TabPanel labelby="group-id" tabId="group">
            <Table
                className="permissions-table"
                columnDefs={columnDefs}
                getRecords={getGroups}
                keyExtractor={(item) => item.groupId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="Loading.Groups"
                filterHelper={groupsFilterHelper}
                tableId={tableId}
                counterPosition={createHelper ? "End" : "Start"}
                createHelper={createHelper}
                selectable={canManagePermissions}
                actionProvider={actionProvider}
                deleteHelper={deleteHelper}
                noResultsCTAProps={noResultsCTAProps}
            />
        </TabPanel>
    );
};
