import React from "react";
import { FeatureItem } from "./FeatureItem";

interface IGlobalFeatureProps {
    id: string;
    featureName: string;
}

export const GlobalFeature: React.FC<IGlobalFeatureProps> = ({ featureName, id }) => {
    return <FeatureItem id={id} featureName={featureName} expandable={false} enabled={true} />;
};
