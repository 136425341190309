import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNavigation } from "../NavigationHook";
import { templatePaths } from "../../PathConstants";
import { extractErrorMessage, defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { NotificationService } from "../../services/NotificationService";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useTemplateApi } from "./TemplateApiHook";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";

interface EditTemplatePermissionKeys {
    templateWriteKey: string;
    templateDeleteKey: string;
    languageReadKey: string;
}

interface EditTemplateHookReturn {
    isAllowed: (permissionKey: string) => boolean;
    permissionKeys: EditTemplatePermissionKeys;
    loadTemplate: () => Promise<ITemplateModel>;
    nameIsUnique: (name: string) => Promise<boolean>;
    removeTemplate: (templateName: string) => Promise<void>;
    saveTemplate: (template: ITemplateModel) => Promise<ITemplateModel>;
    getDependencies: () => Promise<MultipleDependenciesResult>;
}

export const useEditTemplate = (): EditTemplateHookReturn => {
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const { templateId } = useParams<{ templateId: string }>() as Record<string, string>;
    const { get, nameIsUnique, remove, update, getMultipleDependencies } = useTemplateApi(projectId);

    const { replace } = useNavigation();

    const saveTemplate = useCallback(
        async (template: ITemplateModel): Promise<ITemplateModel> => {
            // error is handled by onSave method in EditEntityWrapper
            const result = await update(template);
            NotificationService.addSuccessNotification({
                messageKey: "Template.Save.Success",
            });
            return result;
        },
        [update],
    );

    const removeTemplate = useCallback(
        async (templateName: string): Promise<void> => {
            try {
                await remove(templateId);
                NotificationService.addSuccessNotification({
                    messageKey: "Template.Delete.Success",
                });
                replace(templatePaths.link.base(organizationName, projectName), { state: { deleted: true } });
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [remove, replace, templateId, organizationName, projectName],
    );

    const loadTemplate = useCallback(async (): Promise<ITemplateModel> => {
        try {
            return await get(templateId);
        } catch (error) {
            NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
            replace(templatePaths.link.base(organizationName, projectName), { state: { loadError: true } });
            return {} as ITemplateModel;
        }
    }, [get, replace, templateId, organizationName, projectName]);

    const getDependencies = useCallback(async (): Promise<MultipleDependenciesResult> => {
        try {
            return await getMultipleDependencies([templateId], "usedBy");
        } catch (error) {
            NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
            return {} as MultipleDependenciesResult;
        }
    }, [getMultipleDependencies, templateId]);

    const templateWriteKey = usePermissionKey({
        permission: PermissionKeys.template.write,
        objectId: templateId,
        projectId,
    });

    const templateDeleteKey = usePermissionKey({
        permission: PermissionKeys.template.delete,
        objectId: templateId,
        projectId,
    });

    const languageReadKey = usePermissionKey({
        permission: PermissionKeys.language.read,
        projectId,
    });

    const permissionKeys = useMemo(
        () => ({
            permissionKeys: [templateWriteKey, templateDeleteKey, languageReadKey],
        }),
        [templateWriteKey, templateDeleteKey, languageReadKey],
    );

    const { isAllowed } = usePermissionCheck(permissionKeys);

    return {
        getDependencies,
        isAllowed,
        loadTemplate,
        permissionKeys: { templateWriteKey, templateDeleteKey, languageReadKey },
        nameIsUnique,
        saveTemplate,
        removeTemplate,
    };
};
