import React, { useState } from "react";
import { Alert, FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EntityForm } from "../../forms/EntityForm";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";
import { ShareLink } from "../../../models/contentManager/ContentManagerApiModels";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { behaviorTranslationMap } from "../../../helpers/ContentFileShareHelper";

import "./CreatedContentFileShareForm.scss";

interface ContentFileCreatedShareFormProps {
    shareLinks: ShareLink[];
}

export const CreatedContentFileShareForm = ({ shareLinks }: ContentFileCreatedShareFormProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const [showAlert, setShowAlert] = useState(true);

    return (
        <div className="created-content-file-share-form">
            <Alert isOpen={showAlert} toggle={() => setShowAlert(false)}>
                <i className="fas fa-circle-check" />
                <span>{t("Share.CreationSuccess")}</span>
            </Alert>
            <EntityForm>
                <FormGroup {...setSelector("behaviorsFromGroup")}>
                    {shareLinks.map((shareLink) => (
                        <FormGroup key={shareLink.url}>
                            <ReadOnlyInputWithCopy
                                value={shareLink.url}
                                label={behaviorTranslationMap[shareLink.behavior]}
                                testSelectorValue={`behavior-${shareLink.behavior}`}
                                displayedValueWhileCopying="Share.LinkCopied"
                                size="lg"
                            />
                        </FormGroup>
                    ))}
                </FormGroup>
            </EntityForm>
        </div>
    );
};
