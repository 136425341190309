import { useCallback } from "react";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";
import { useOrganizationGroupApi } from "../organization/OrganizationGroupApiHook";
import { usePermissionGroupApi } from "./PermissionGroupApiHook";
import { IPermissionsGroupFilter } from "../../components/permissions/PermissionsGroupView";
import { useContentGroupApi } from "../contentManager/ContentGroupApiHook";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";

interface IUseGroupsProps {
    contentId?: string;
    organizationId?: string;
    projectId?: string;
}

interface IUseGroupsReturn {
    getGroups: (
        filterValue?: IPermissionsGroupFilter,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IPermissionsGroupModel>>;
    removeGroups: (groupIds: string[]) => Promise<IDeleteErrorResult[]>;
    removeSingleGroup: (groupId: string) => Promise<void>;
}

export const useGroups = ({ organizationId, projectId, contentId }: IUseGroupsProps): IUseGroupsReturn => {
    const {
        getRecords: getContentGroupRecords,
        removeGroups: removeContentGroups,
        removeSingleGroup: removeSingleContentGroups,
    } = useContentGroupApi({ contentId: contentId!, projectId: projectId! });
    const {
        getRecords: getProjectGroupRecords,
        removeGroups: removeProjectGroups,
        removeSingleGroup: removeSingleProjectGroup,
    } = useProjectGroupApi({
        projectId: projectId!,
    });
    const { getRecords: getOrganizationGroupRecords } = useOrganizationGroupApi();
    const { getRecords: getPermissionGroupRecords } = usePermissionGroupApi();

    const getGroups = useCallback(
        (filterValue?: IPermissionsGroupFilter, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue.filterText;
                filters.Description = filterValue.filterText;
                if (filterValue.filterType.length > 0) {
                    filters.IsSystem = JSON.stringify(filterValue.filterType);
                }
            }

            if (projectId) {
                if (contentId) {
                    return getContentGroupRecords(filters, sortFields, offset);
                }

                return getProjectGroupRecords(filters, sortFields, offset);
            }

            if (organizationId) {
                return getOrganizationGroupRecords(organizationId, filters, sortFields, offset);
            }

            return getPermissionGroupRecords(filters, sortFields, offset);
        },
        [
            contentId,
            projectId,
            organizationId,
            getContentGroupRecords,
            getProjectGroupRecords,
            getOrganizationGroupRecords,
            getPermissionGroupRecords,
        ],
    );

    const removeGroups = useCallback(
        (groupId: string[]) => {
            if (projectId) {
                if (contentId) {
                    return removeContentGroups(groupId);
                }

                return removeProjectGroups(groupId);
            }

            throw new Error("Not implemented");
        },
        [contentId, projectId, removeContentGroups, removeProjectGroups],
    );

    const removeSingleGroup = useCallback(
        (groupId: string) => {
            if (projectId) {
                if (contentId) {
                    return removeSingleContentGroups(groupId);
                }

                return removeSingleProjectGroup(groupId);
            }

            throw new Error("Not implemented");
        },
        [contentId, projectId, removeSingleContentGroups, removeSingleProjectGroup],
    );

    return {
        getGroups,
        removeGroups,
        removeSingleGroup,
    };
};
