import React, { useCallback } from "react";
import { FormGroup } from "reactstrap";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { PermissionGroupFormValidator } from "../../../formValidators/PermissionGroupFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { ValidatedInputWithStatus } from "../../forms/inputs/ValidatedInputWithStatus";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { IEditPermissionGroupModel } from "../../../models/permissions/IEditPermissionGroupModel";

import "./ManageGroupSettings.scss";

export const ManageGroupSettings: React.FC = () => {
    const {
        entityProps: {
            entity: { name, description },
            setEntityProperties,
            formValidator,
        },
        errorProps: { errors, setErrors },
        validateNameProps: { checkNameIsUnique, isValidatingName },
        dirtyProps: { isDirty },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IEditPermissionGroupModel, PermissionGroupFormValidator<IEditPermissionGroupModel>>();

    const onDescriptionChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const newDescription = event.target.value;
            let errorMessage = "";

            try {
                setEntityProperties({ description: newDescription });
                await formValidator.validateDescription(newDescription);
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({ description: errorMessage });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEntityProperties({ name: event.target.value });
        checkNameIsUnique(event.target.value);
    };

    const isInvalid = !isValidatingName && !!errors.name;

    return (
        <div className="manage-group-settings">
            <FormGroup className="entity-name">
                <LocalizedLabel for="edit-group-name" text="Common.Name" />
                <ValidatedInputWithStatus
                    id="edit-group-name"
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    invalid={isInvalid}
                    onChange={onNameChange}
                    error={errors.name}
                    isBusy={isValidatingName}
                    value={name}
                    hideStatus={!isDirty(["name"])}
                    disabled={!canEdit}
                />
            </FormGroup>
            <FormGroup className="entity-description">
                <LocalizedLabel for="edit-group-description" text="Common.Description" />
                <ValidatedInput
                    id="edit-group-description"
                    name="edit-group-description"
                    type="textarea"
                    value={description}
                    onChange={onDescriptionChange}
                    error={errors.description}
                    disabled={!canEdit}
                />
            </FormGroup>
        </div>
    );
};
