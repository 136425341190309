import React from "react";
import classNames from "classnames";
import { MultiValueGenericProps, GroupBase, MultiValueProps, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface IMultiValueLabelProps
    extends MultiValueGenericProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    data: SelectComponentOptions<any>;
    customMultiValueLabel?: (props: MultiValueProps) => JSX.Element | null;
}

export const MultiValueLabel: React.FC<IMultiValueLabelProps> = ({
    customMultiValueLabel,
    innerProps,
    children,
    ...props
}) => {
    const { setSelector } = useTestSelector();
    const { data } = props;

    const { className, ...rest } = innerProps;

    return (
        <components.MultiValueLabel
            {...props}
            innerProps={{ className: classNames(className, data.className, "multi-value-label"), ...rest, ...setSelector("selectedItemLabel") }}
        >
            {customMultiValueLabel ? customMultiValueLabel(props as MultiValueProps) : children}
        </components.MultiValueLabel>
    );
};
