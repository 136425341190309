import React, { useCallback, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { selectedFolderIdAtom } from "../../atoms/ContentManager";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { useContentManagerNavigationContext } from "../../contexts/ContentManagerNavigationContext";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentManagerTableExtraActions } from "../../hooks/contentManager/ContentManagerTableExtraActionsHook";
import { Table } from "../table/Table";
import { ISortField } from "../../models/ISortField";
import { useContentEntityApi } from "../../hooks/contentManager/ContentEntityApiHook";
import { useContentManagerTableHelper } from "../../hooks/contentManager/ContentManagerTableHelperHook";
import { useContentManagerTableActionProvider } from "../../hooks/contentManager/ContentManagerTableActionProviderHook";
import { ContentManagerTableId } from "../../models/contentManager/constant";
import { useContentManagerTableColumnDefs } from "../../hooks/contentManager/ContentManagerTableColumnDefsHook";
import { useContentManagerTableHandleRefresh } from "../../hooks/contentManager/ContentManagerTableHandleRefreshHook";
import { ITreeItem } from "../../models/contentManager/FolderTree";
import { useOpenItem } from "../../hooks/contentManager/OpenItemHook";
import { ContentItemIcon } from "./ContentItemIcon";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useContentManagerEmptyFolderDisplay } from "../../hooks/contentManager/ContentManagerEmptyFolderDisplayHook";
import { Button } from "../buttons/Button";

const keyExtractor = (item: IContentEntityModel) => item.id;
const nameExtractor = (item: IContentEntityModel) => item.name;

/**
 * The content manager content view component.
 */
export const ContentManagerContentView = () => {
    const { onLoadError, handleSelectedPath } = useContentManagerNavigationContext();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const { projectId } = useProjectContext();
    const [selectedItems, setSelectedItems] = useState<IContentEntityModel[]>([]);
    const contentWriteKey = usePermissionKey({ permission: PermissionKeys.contentManager.write, projectId });
    const contentDeleteKey = usePermissionKey({ permission: PermissionKeys.contentManager.delete, projectId });
    const contentReadKey = usePermissionKey({ permission: PermissionKeys.contentManager.read, projectId });
    const { setSelector } = useTestSelector();

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [contentWriteKey, contentDeleteKey, contentReadKey] }),
        [contentDeleteKey, contentWriteKey, contentReadKey],
    );

    const { getFolderContentRecords } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });

    const { bulkActionDownload, bulkActionMove, bulkActionCopy, uploadFolderAction, uploadFilesAction } =
        useContentManagerTableExtraActions({ projectId, selectedItems, tableId: ContentManagerTableId });

    const { createHelper, deleteHelper, dragAndDropHelper, rowDragAndDropProvider } = useContentManagerTableHelper({
        projectId,
        tableId: ContentManagerTableId,
    });

    const { openFile, getWopiFileInfo } = useOpenItem();

    const { actionProvider } = useContentManagerTableActionProvider({
        projectId,
        selectedItems,
        tableId: ContentManagerTableId,
    });

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const getRecords = useCallback(
        (_: undefined, sortFields?: ISortField[], offset?: number) => {
            return getFolderContentRecords(selectedFolderId, sortFields, offset);
        },
        [getFolderContentRecords, selectedFolderId],
    );

    const columnNameContent = useCallback(
        (item: IContentEntityModel) => {
            return (
                <div>
                    <ContentItemIcon name={item.name} type={item.type} />
                    <Button
                        className="text-truncate"
                        color="link"
                        ariaLabel={item.name}
                        onClick={() => {
                            if (item.type === "Folder") {
                                handleSelectedPath({ path: item.path } as ITreeItem);
                                return;
                            }
                            const wopiFileInfo = getWopiFileInfo(
                                item,
                                isAllowed(contentWriteKey),
                                isAllowed(contentReadKey),
                            );
                            openFile(item.id, item.name, undefined, wopiFileInfo?.action).catch(onLoadError);
                        }}
                        {...setSelector("cell-link")}
                    >
                        {item.name}
                    </Button>
                </div>
            );
        },
        [
            getWopiFileInfo,
            isAllowed,
            contentWriteKey,
            contentReadKey,
            openFile,
            setSelector,
            onLoadError,
            handleSelectedPath,
        ],
    );

    const columnDefs = useContentManagerTableColumnDefs({
        columnNameContent,
    });

    useContentManagerTableHandleRefresh({
        tableId: ContentManagerTableId,
        selectedFolderId,
    });

    const emptyFolderDisplayProps = useContentManagerEmptyFolderDisplay();

    return (
        <div className="dropzone-wrapper">
            <Table
                className="content-manager-table"
                columnDefs={columnDefs}
                actionProvider={actionProvider}
                rowDragAndDropProvider={rowDragAndDropProvider}
                getRecords={getRecords}
                keyExtractor={keyExtractor}
                nameExtractor={nameExtractor}
                loadingMessageKey="ContentManager.Loading"
                tableId={ContentManagerTableId}
                selectable={isAllowed(contentDeleteKey)}
                createHelper={createHelper}
                deleteHelper={deleteHelper}
                dragAndDropHelper={dragAndDropHelper}
                onSelectionChange={setSelectedItems}
                extraActions={[
                    { showWithSelectedItem: false, button: uploadFilesAction, id: "uploadFilesAction" },
                    { showWithSelectedItem: false, button: uploadFolderAction, id: "uploadFolderAction" },
                    { showWithSelectedItem: true, button: bulkActionCopy, id: "bulkActionCopy" },
                    { showWithSelectedItem: true, button: bulkActionMove, id: "bulkActionMove" },
                    { showWithSelectedItem: true, button: bulkActionDownload, id: "bulkActionDownload" },
                ]}
                noResultsCTAProps={emptyFolderDisplayProps}
                onLoadError={onLoadError}
            />
        </div>
    );
};
