import { useCallback } from "react";
import { IAddMemberResultModel } from "../../../models/permissions/IAddMemberResultModel";
import { ISortField } from "../../../models/ISortField";
import { IEntityResult } from "../../../models/IEntityResult";
import { IUserModel } from "../../../models/user/IUserModel";
import { IDeleteErrorResult } from "../../../models/IDeleteErrorResult";
import { useProjectGroupApi } from "../../project/ProjectGroupApiHook";
import { useOrganizationGroupApi } from "../../organization/OrganizationGroupApiHook";
import { usePermissionGroupApi } from "../PermissionGroupApiHook";
import { IUserGroupModel } from "../../../models/user/IUserGroupModel";
import { IPermissionsGroupWithAccessInfoModel } from "../../../models/permissions/IPermissionsGroupWithAccessInfoModel";
import { useContentGroupApi } from "../../contentManager/ContentGroupApiHook";
import { IUpdateContentGroupResult } from "../../../models/permissions/IUpdateContentGroupResult";

interface GroupPermissionHookProps {
    projectId?: string;
    organizationId?: string;
    contentId?: string;
}

interface GroupPermissionHookReturn {
    addMembers: (groupId: string, userIds: string[]) => Promise<IAddMemberResultModel>;
    getAllGroups: () => Promise<IUserGroupModel[]>;
    getMemberRecords: (
        groupId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserModel>>;
    getPermissionsGroup: (groupId: string) => Promise<IPermissionsGroupWithAccessInfoModel>;
    removeMembers: (groupId: string, ids: string[]) => Promise<IDeleteErrorResult[]>;
    nameIsUnique: (name: string) => Promise<boolean>;
    updateGroup: (
        model: IPermissionsGroupWithAccessInfoModel,
    ) => Promise<IPermissionsGroupWithAccessInfoModel | IUpdateContentGroupResult>;
}

export const useGroupPermission = ({
    contentId,
    projectId,
    organizationId,
}: GroupPermissionHookProps): GroupPermissionHookReturn => {
    const {
        addMembers: addProjectGroupMembers,
        getAllGroups: getAllProjectGroups,
        getById: getProjectGroupById,
        getMemberRecords: getProjectGroupMemberRecords,
        removeMembers: removeProjectGroupMembers,
        nameIsUnique: nameIsUniqueInProject,
        updateGroup: updateProjectGroup,
    } = useProjectGroupApi({ projectId: projectId! });
    const {
        addMembers: addOrganizationGroupMembers,
        getAllGroups: getAllOrganizationGroups,
        getById: getOrganizationGroupById,
        getMemberRecords: getOrganizationGroupMemberRecords,
        removeMembers: removeOrganizationGroupMembers,
    } = useOrganizationGroupApi();

    const {
        addMembers: addPermissionGroupMembers,
        getAllGroups: getAllPermissionsGroups,
        getById: getPermissionGroupById,
        getMemberRecords: getPermissionGroupMemberRecords,
        removeMembers: removePermissionGroupMembers,
    } = usePermissionGroupApi();
    const {
        getAvailableGroups: getAvailableContentGroups,
        getById: getContentGroupById,
        updateGroup: updateContentGroup,
    } = useContentGroupApi({
        contentId: contentId!,
        projectId: projectId!,
    });

    const addMembers = useCallback(
        (groupId: string, userIds: string[]): Promise<IAddMemberResultModel> => {
            if (projectId) {
                return addProjectGroupMembers(groupId, userIds);
            }

            if (organizationId) {
                return addOrganizationGroupMembers(organizationId, groupId, userIds);
            }

            return addPermissionGroupMembers(groupId, userIds);
        },
        [projectId, organizationId, addProjectGroupMembers, addOrganizationGroupMembers, addPermissionGroupMembers],
    );

    const getAllGroups = useCallback(() => {
        if (contentId) {
            return getAvailableContentGroups();
        }

        if (projectId) {
            return getAllProjectGroups();
        }

        if (organizationId) {
            return getAllOrganizationGroups(organizationId);
        }

        return getAllPermissionsGroups();
    }, [
        contentId,
        projectId,
        organizationId,
        getAllPermissionsGroups,
        getAvailableContentGroups,
        getAllProjectGroups,
        getAllOrganizationGroups,
    ]);

    const getPermissionsGroup = useCallback(
        (groupId: string) => {
            if (contentId) {
                return getContentGroupById(groupId);
            }

            if (projectId) {
                return getProjectGroupById(groupId);
            }

            if (organizationId) {
                return getOrganizationGroupById(organizationId, groupId);
            }

            return getPermissionGroupById(groupId);
        },
        [
            contentId,
            projectId,
            organizationId,
            getPermissionGroupById,
            getContentGroupById,
            getProjectGroupById,
            getOrganizationGroupById,
        ],
    );

    const getMemberRecords = useCallback(
        (groupId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            if (projectId) {
                return getProjectGroupMemberRecords(groupId, filterValue, sortFields, offset);
            }

            if (organizationId) {
                return getOrganizationGroupMemberRecords(organizationId, groupId, filterValue, sortFields, offset);
            }

            return getPermissionGroupMemberRecords(groupId, filterValue, sortFields, offset);
        },
        [
            projectId,
            organizationId,
            getProjectGroupMemberRecords,
            getOrganizationGroupMemberRecords,
            getPermissionGroupMemberRecords,
        ],
    );

    const removeMembers = useCallback(
        (groupId: string, ids: string[]) => {
            if (projectId) {
                return removeProjectGroupMembers(groupId, ids);
            }

            if (organizationId) {
                return removeOrganizationGroupMembers(organizationId, groupId, ids);
            }

            return removePermissionGroupMembers(groupId, ids);
        },
        [
            projectId,
            organizationId,
            removeProjectGroupMembers,
            removeOrganizationGroupMembers,
            removePermissionGroupMembers,
        ],
    );

    const updateGroup = useCallback(
        (model: IPermissionsGroupWithAccessInfoModel) => {
            if (contentId) {
                return updateContentGroup(model);
            }
            if (projectId) {
                return updateProjectGroup(model);
            }

            throw new Error("No support for organization or application level");
        },
        [contentId, projectId, updateContentGroup, updateProjectGroup],
    );

    const nameIsUnique = useCallback(
        (name: string) => {
            if (projectId) {
                return nameIsUniqueInProject(name);
            }

            throw new Error("No support for organization or application level");
        },
        [nameIsUniqueInProject, projectId],
    );

    return {
        addMembers,
        getAllGroups,
        getMemberRecords,
        getPermissionsGroup,
        removeMembers,
        updateGroup,
        nameIsUnique,
    };
};
