import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import {
    SearchQueryParamsName,
    contentViewFolderTreeNeedRefreshAtom,
    foldersTreeAtom,
    loadingModalStateAtom,
    searchQueryAtom,
    selectedFolderIdAtom,
} from "../../atoms/ContentManager";
import { removeFolders as removeFoldersFromTree } from "../../helpers/ContentManagerFolderHelper";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { ITreeItem } from "../../models/contentManager/FolderTree";
import { getSelectedFolderFromPath } from "../../helpers/TreeFolderHelper";
import { useNavigation } from "../NavigationHook";
import { useModalContext } from "../../contexts/ModalContext";
import { useGetFoldersTreeFromPath } from "./GetFoldersTreeFromPathHook";
import { handleCollapseAtom, setItemLoadingStateAtom } from "../../atoms/contentManager/foldersTree";
import { useSubFolders } from "./folderTree/subFoldersHook";
import { useFolderTreeAccessor } from "./folderTree/folderTreeAccessorHook";
import { contentManagerPaths } from "../../PathConstants";

export const contentManagerSearchParamPath = "path";

export type BeforeNavigate = (() => Promise<boolean>) | null;

/**
 * The content manager context return props interface.
 */
interface ContentManagerNavigationReturnProps {
    handleSelectedPath: (item: ITreeItem | null) => void;
    setBeforeNavigate: (fn: BeforeNavigate) => void;
    onLoadError: (error: unknown) => void;
}

export const useContentManagerNavigation = (): ContentManagerNavigationReturnProps => {
    const { getFoldersTree } = useFolderTreeAccessor();
    const [selectedFolderId, setSelectedFolderId] = useAtom(selectedFolderIdAtom);
    const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);

    const setFoldersTree = useSetAtom(foldersTreeAtom);
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const handleCollapse = useSetAtom(handleCollapseAtom);
    const setItemLoadingState = useSetAtom(setItemLoadingStateAtom);

    const setBeforeNavigateRef = useRef<(() => Promise<boolean>) | null>(null);
    const [contentViewNeedRefresh, setContentViewNeedRefresh] = useAtom(contentViewFolderTreeNeedRefreshAtom);

    const [searchParams] = useSearchParams();
    const searchPathOnLoad = searchParams.get(contentManagerSearchParamPath);
    const [searchPath, setSearchPath] = useState<string | null>(searchParams.get(contentManagerSearchParamPath));
    const location = useLocation();
    const { navigate } = useNavigation();
    const match = useMatch(contentManagerPaths.route.base);

    const { getSubFolders } = useSubFolders();
    const { loadFolderTree } = useGetFoldersTreeFromPath();

    const { closeModal, isDisplayed } = useModalContext();

    const loadSubFoldersFromPath = useCallback(
        async (path: string) => {
            const foldersTree = getFoldersTree();
            const {
                selectedFolderId: selectedFolderIdFromPath,
                selectedFolderName,
                parentFolderItem,
            } = getSelectedFolderFromPath(path, foldersTree);

            if (selectedFolderIdFromPath) {
                handleCollapse(selectedFolderIdFromPath, true, true);

                const selectedFolder = foldersTree.find((ft) => ft.id === selectedFolderIdFromPath);
                if (selectedFolder?.hasChildren && !selectedFolder?.areChildrenLoaded) {
                    setItemLoadingState(selectedFolderIdFromPath, true);
                    setSelectedFolderId(selectedFolderIdFromPath);

                    await getSubFolders(selectedFolder);
                    setItemLoadingState(selectedFolderIdFromPath, false);
                } else {
                    setSelectedFolderId(selectedFolderIdFromPath);
                }
            } else {
                const newFolders = await getSubFolders(parentFolderItem!);
                setSelectedFolderId(newFolders.filter((nf: ITreeItem) => nf.name === selectedFolderName)[0].id);
                handleCollapse(parentFolderItem!.id, true, true);
            }
        },
        [getFoldersTree, getSubFolders, handleCollapse, setItemLoadingState, setSelectedFolderId],
    );

    const handleSelectedPath = useCallback(
        (item: ITreeItem | null) => {
            // Rely on the fact that the path is always available instead of item id like not present on breadcrumb / left menu or back and forward buttons.
            if (setBeforeNavigateRef.current) {
                void setBeforeNavigateRef.current().then((shouldNavigate) => {
                    if (shouldNavigate) {
                        searchParams.set(contentManagerSearchParamPath, item === null ? "/" : item.path);
                        searchParams.delete(SearchQueryParamsName);

                        navigate({
                            ...location,
                            search: searchParams.toString(),
                        });
                    }
                });
                return;
            }

            const pathParam = item === null ? "/" : item.path;

            if (
                searchParams.has(SearchQueryParamsName) ||
                searchParams.get(contentManagerSearchParamPath) !== pathParam
            ) {
                searchParams.set(contentManagerSearchParamPath, pathParam);
                searchParams.delete(SearchQueryParamsName);

                navigate({
                    ...location,
                    search: searchParams.toString(),
                });
            }
        },
        [location, navigate, searchParams],
    );

    const onLoadError = useCallback(
        (error: unknown) => {
            setFoldersTree((oldTree) => {
                const item = oldTree.find((x) => x.id === selectedFolderId);
                const newTree = removeFoldersFromTree([selectedFolderId!], item?.parentId ?? null, oldTree);

                return newTree;
            });
            handleSelectedPath(null);
            defaultRequestErrorHandler(error);
        },
        [handleSelectedPath, selectedFolderId, setFoldersTree],
    );

    // Aligns the search query atom with the URL search query.
    // used when loading the page with a search query in the URL.
    useEffect(() => {
        const searchParam = searchParams.get(SearchQueryParamsName) ?? "";

        if (searchParam !== searchQuery) {
            setSearchQuery(searchParam);
        }
    }, [location, searchParams, searchQuery, setSearchQuery]);

    useEffect(() => {
        if (!contentViewNeedRefresh) {
            return;
        }

        (async () => {
            const { currentFolderLocationId, foldersTree: loadedFoldersTree } = await loadFolderTree(
                searchPathOnLoad,
                onLoadError,
            );
            setSelectedFolderId(currentFolderLocationId);
            setFoldersTree(loadedFoldersTree);
            setContentViewNeedRefresh(false);
        })();
    }, [
        loadFolderTree,
        setFoldersTree,
        setSelectedFolderId,
        contentViewNeedRefresh,
        setContentViewNeedRefresh,
        searchPathOnLoad,
        onLoadError,
    ]);

    // handles navigation side effects when the 'path' searchQuery changes.
    useEffect(() => {
        if (!match) {
            return;
        }

        const currentSearchPath = searchParams.get(contentManagerSearchParamPath);

        // if the path is the same as the current one, we don't need to do anything
        if (currentSearchPath === searchPath) {
            return;
        }

        setSearchPath(currentSearchPath);

        // Ensure the create folder modal is closed when we navigate to another folder.
        if (isDisplayed) {
            closeModal();
        }
        setLoadingModalState({ type: "CLOSE_MODAL" });

        if (!currentSearchPath || currentSearchPath === "/") {
            // searchPath is null when we click on the Content menu / breadcrumb.
            setSelectedFolderId(null);
        } else {
            loadSubFoldersFromPath(currentSearchPath);
        }
    }, [
        searchParams,
        loadSubFoldersFromPath,
        searchPath,
        isDisplayed,
        closeModal,
        setSelectedFolderId,
        setLoadingModalState,
        match,
    ]);

    const setBeforeNavigate = useCallback((fn: (() => Promise<boolean>) | null) => {
        setBeforeNavigateRef.current = fn;
    }, []);

    return {
        handleSelectedPath,
        setBeforeNavigate,
        onLoadError,
    };
};
