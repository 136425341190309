import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSetAtom, useAtomValue } from "jotai";
import { IContentEntityModel, ContentEntityType } from "../../models/contentManager/ContentManagerApiModels";
import { NotificationService } from "../../services/NotificationService";
import { useTestSelector } from "../AutomatedTestsServiceHook";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { ITableContextModel } from "../../components/table/TableContext";
import { ITableRowItem } from "../../components/table/TableRow";
import { Button } from "../../components/buttons/Button";
import { FileDialogButton } from "../../components/buttons/FileDialogButton";
import { useContentManagerSelectAction } from "./ContentManagerSelectAction";
import { useContentManagerTableHelper } from "./ContentManagerTableHelperHook";
import { useCurrentFolderName } from "./CurrentFolderNameHook";
import { contentManagerSearchParamPath } from "./ContentManagerNavigationHook";
import { useContentManagerTableActionCallback } from "./ContentManagerTableActionCallbackHook";
import { selectedFolderIdAtom, loadingModalStateAtom } from "../../atoms/ContentManager";
import { useDownloadItem } from "./DownloadItemHook";
import { useContentManagerNavigationContext } from "../../contexts/ContentManagerNavigationContext";
import { extractErrorMessage } from "../../helpers/ErrorHelper";
import { useFolderTreeAccessor } from "./folderTree/folderTreeAccessorHook";

interface IContentManagerTableHookProps {
    projectId: string;
    selectedItems: IContentEntityModel[];
    tableId: string;
}

interface IContentManagerTableHookResult {
    bulkActionDownload: (
        tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => JSX.Element | undefined;
    bulkActionMove: (
        tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => JSX.Element | undefined;
    bulkActionCopy: (
        tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => JSX.Element | undefined;
    uploadFolderAction: (
        tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => JSX.Element | undefined;
    uploadFilesAction: (
        tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => JSX.Element | undefined;
}

export const useContentManagerTableExtraActions = ({
    projectId,
    selectedItems,
    tableId,
}: IContentManagerTableHookProps): IContentManagerTableHookResult => {
    const { t } = useTranslation();
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const { getFoldersTree } = useFolderTreeAccessor();
    const { setSelector } = useTestSelector();
    const { uploadHelper } = useContentManagerTableHelper({ projectId, tableId });
    const { onLoadError } = useContentManagerNavigationContext();
    const originFolderName = useCurrentFolderName(contentManagerSearchParamPath);

    const { onAction } = useContentManagerSelectAction({
        originFolderName,
        originFolderId: selectedFolderId,
        initialFoldersTree: getFoldersTree(),
        checkParentExistsOnError: true,
    });

    const contentWriteKey = usePermissionKey({ permission: PermissionKeys.contentManager.write, projectId });
    const contentDeleteKey = usePermissionKey({ permission: PermissionKeys.contentManager.delete, projectId });
    const permissionsObject = useMemo(
        () => ({ permissionKeys: [contentWriteKey, contentDeleteKey] }),
        [contentDeleteKey, contentWriteKey],
    );
    const { isAllowed } = usePermissionCheck(permissionsObject);
    const { onCopyDone, onMoveDone } = useContentManagerTableActionCallback(tableId);

    const onInvalidEntityType = useCallback(() => {
        NotificationService.addErrorNotification({
            message: t("Common.InvalidEntityType"),
        });
    }, [t]);

    const { downloadFile, downloadItems } = useDownloadItem();

    const onClickDownload = useCallback(async () => {
        if (selectedItems.length === 1 && selectedItems[0].type !== "Folder") {
            try {
                await downloadFile(selectedItems[0].id);
            } catch (e) {
                onLoadError(e);
            }
            return;
        }

        const selectedIds = selectedItems.map((item) => ({
            type: item.type === "Folder" ? ContentEntityType.Folder : ContentEntityType.File,
            id: item.id,
            name: item.name,
        }));

        const zipFileName =
            selectedIds.length === 1 && selectedIds[0].type === ContentEntityType.Folder
                ? `${selectedItems[0].name}.zip`
                : `Content_${moment().unix()}.zip`;

        setLoadingModalState({
            type: "OPEN_MODAL",
            payload: { label: "Common.Download.Loading" },
        });
        try {
            await downloadItems(selectedIds, zipFileName);
        } catch (e) {
            NotificationService.addErrorNotification({
                message: extractErrorMessage(e),
            });
        }
        setLoadingModalState({ type: "CLOSE_MODAL" });
    }, [downloadFile, downloadItems, onLoadError, selectedItems, setLoadingModalState]);

    const bulkActionDownload = useCallback(
        (tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }
            return (
                <Button
                    ariaLabel={t("Common.Download")}
                    icon="fal fa-arrow-down-to-line"
                    onClick={onClickDownload}
                    className="bulk-action-download"
                    color="secondary"
                    outline
                    hidden={tableContext.tableState.selectedItemIds.length < 1}
                    {...setSelector("downloadButton")}
                >
                    {t("Common.Download")}
                </Button>
            );
        },
        [onClickDownload, contentWriteKey, isAllowed, t, setSelector],
    );

    const bulkActionMove = useCallback(
        (tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }
            return (
                <Button
                    ariaLabel={t("Common.Move")}
                    icon="fal fa-arrow-right"
                    onClick={() => {
                        onAction("Move", selectedItems, onMoveDone);
                    }}
                    className="bulk-action-move"
                    color="secondary"
                    outline
                    hidden={tableContext.tableState.selectedItemIds.length < 1}
                    {...setSelector("moveToButton")}
                >
                    {t("Common.Move")}
                </Button>
            );
        },
        [isAllowed, contentWriteKey, t, setSelector, onAction, selectedItems, onMoveDone],
    );

    const bulkActionCopy = useCallback(
        (tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }
            return (
                <Button
                    ariaLabel={t("Common.Copy")}
                    icon="fal fa-files"
                    onClick={() => {
                        onAction("Copy", selectedItems, onCopyDone);
                    }}
                    className="bulk-action-copy"
                    color="secondary"
                    outline
                    hidden={tableContext.tableState.selectedItemIds.length < 1}
                    {...setSelector("copyToButton")}
                >
                    {t("Common.Copy")}
                </Button>
            );
        },
        [isAllowed, contentWriteKey, t, setSelector, onAction, onCopyDone, selectedItems],
    );

    const uploadFolderAction = useCallback(
        (tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }

            return (
                <FileDialogButton
                    className="upload-button"
                    onFilesLoaded={(files: FileList) => uploadHelper(Array.from(files))}
                    onInvalidFileType={onInvalidEntityType}
                    type="button"
                    color="secondary"
                    size="sm"
                    name="upload-folder-button"
                    webkitdirectory=""
                    outline
                    multiple={true}
                    hidden={tableContext.tableState.selectedItemIds.length > 0}
                    testSelectorValue="uploadFolderInput"
                    labelKey="Content.Upload.Folder"
                    aria-label={t("Content.Upload.Folder")}
                >
                    <i className="fas fa-file-import" />
                    {t("Content.Upload.Folder")}
                </FileDialogButton>
            );
        },
        [contentWriteKey, isAllowed, onInvalidEntityType, t, uploadHelper],
    );

    const uploadFilesAction = useCallback(
        (tableContext: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>) => {
            if (!isAllowed(contentWriteKey)) {
                return;
            }

            return (
                <FileDialogButton
                    className="upload-button"
                    onFilesLoaded={(files: FileList) => uploadHelper(Array.from(files))}
                    onInvalidFileType={onInvalidEntityType}
                    type="button"
                    color="secondary"
                    size="sm"
                    outline
                    multiple={true}
                    hidden={tableContext.tableState.selectedItemIds.length > 0}
                    testSelectorValue="uploadFileInput"
                    labelKey="Content.Upload.Files"
                    name="upload-file-button"
                    aria-label={t("Content.Upload.Files")}
                >
                    <i className="fas fa-file-import" />
                    {t("Content.Upload.Files")}
                </FileDialogButton>
            );
        },
        [contentWriteKey, isAllowed, onInvalidEntityType, t, uploadHelper],
    );

    return {
        bulkActionDownload,
        bulkActionMove,
        bulkActionCopy,
        uploadFolderAction,
        uploadFilesAction,
    };
};
