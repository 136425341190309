import React, { useMemo } from "react";
import { ITableFilterHelper, Table } from "../table/Table";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useGroups } from "../../hooks/permission/GroupsHook";
import { PermissionGroupNameColumnContent } from "./PermissionGroupNameColumnContent";
import { TabPanel } from "../tabs/TabPanel";
import { DefaultTableFilterWithOptions } from "../table/DefaultTableFilterWithOptions";
import { FilterOption } from "../../models/IFilterOptions";

import "./PermissionsGroupView.scss";

interface IPermissionsGroupViewProps {
    organizationId?: string;
    groupLink: (groupId: string) => string;
    customOnLoadError?: (error: Error) => void;
}

export interface IPermissionsGroupFilter {
    filterText: string;
    filterType: boolean[];
}

export const filterOptionsType: FilterOption<boolean>[] = [
    {
        labelKey: "PermissionGroup.Type.System",
        filterValue: true,
    },
    {
        labelKey: "PermissionGroup.Type.Custom",
        filterValue: false,
    },
];

export const defaultFilterValue: IPermissionsGroupFilter = {
    filterText: "",
    filterType: [],
};

export type GroupScope = "App" | "Organization" | "Project" | "Content";

export const groupFilterProvider = (scope: GroupScope): ITableFilterHelper<IPermissionsGroupFilter> => {
    return {
        filter: scope === "Project" || scope === "Content" ? filterWithGroupTypes : defaultFilter,
        defaultFilterValue: defaultFilterValue,
        isActive: (value) => !!value.filterText || value.filterType?.length > 0,
    };
};

const defaultFilter = (filterValue: IPermissionsGroupFilter, onChange: (value: IPermissionsGroupFilter) => void) => (
    <DefaultTableFilterWithOptions
        text={filterValue.filterText}
        onChange={({ filters, filterText }) => {
            onChange({
                filterType: [],
                filterText,
            });
        }}
        filterComponents={[]}
        filterPlaceHolder="PermissionsGroupView.FilterPlaceHolder"
    />
);

const filterWithGroupTypes = (
    filterValue: IPermissionsGroupFilter,
    onChange: (value: IPermissionsGroupFilter) => void,
) => (
    <DefaultTableFilterWithOptions
        text={filterValue.filterText}
        onChange={({ filters, filterText }) => {
            onChange({
                filterType: filters[0],
                filterText,
            });
        }}
        filterComponents={[
            {
                defaultCheckboxLabel: "Common.Type",
                filtersOptions: filterOptionsType,
                testSelectorCheckboxFilterValue: "type",
                activeFilter: filterValue.filterType,
            },
        ]}
        filterPlaceHolder="PermissionsGroupView.FilterPlaceHolder"
    />
);

export const getUserCountColumnRenderContent = ({ userCount }: IPermissionsGroupModel) => {
    return (
        <div>
            <i className="fal fa-users" />
            <span>{userCount}</span>
        </div>
    );
};

export const partialPermissionGroupColumnDefs: IEntityTableColumnDef[] = [
    {
        type: "Text",
        fieldName: "description",
        displayName: "Common.Description",
        sortField: {
            name: "Description",
            order: SortOrder.Asc,
        },
        shouldTruncateText: true,
        testSelectorColumnName: "description",
    },
    {
        type: "ResourceKey",
        displayName: "Common.Type",
        fieldName: "isSystem",
        content: ({ isSystem }: IPermissionsGroupModel) =>
            isSystem ? "PermissionGroup.Type.System" : "PermissionGroup.Type.Custom",
    },
    {
        type: "JSX",
        content: getUserCountColumnRenderContent,
        className: "members center-cell",
        fieldName: "members",
        displayName: "Common.Members",
        testSelectorColumnName: "userCount",
    },
];

export const PermissionsGroupView: React.FC<IPermissionsGroupViewProps> = ({
    organizationId,
    groupLink,
    customOnLoadError,
}: IPermissionsGroupViewProps) => {
    const { getGroups } = useGroups({ organizationId });
    const groupScope = useMemo<GroupScope>(() => (organizationId ? "Organization" : "App"), [organizationId]);
    const tableId = useMemo(
        () =>
            groupScope === "Organization"
                ? `${groupScope}-${organizationId!}-permissions-group`
                : `${groupScope}-permissions-group`,
        [groupScope, organizationId],
    );
    const groupsFilterHelper = useMemo(() => groupFilterProvider(groupScope), [groupScope]);
    const getNameColumnRenderContent = ({ name, groupId }: IPermissionsGroupModel) => (
        <PermissionGroupNameColumnContent name={name} groupId={groupId} groupLink={groupLink} />
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            content: getNameColumnRenderContent,
            fieldName: "name",
            displayName: "Common.Name",
            className: "name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
        },
        ...partialPermissionGroupColumnDefs,
    ];

    return (
        <>
            <TabPanel labelby="group-id" tabId="group">
                <Table
                    className="permissions-table"
                    columnDefs={columnDefs}
                    getRecords={getGroups}
                    keyExtractor={(item) => item.groupId}
                    nameExtractor={(item) => item.name}
                    loadingMessageKey="Loading.Permissions"
                    filterHelper={groupsFilterHelper}
                    tableId={tableId}
                    counterPosition={"Start"}
                    onLoadError={customOnLoadError}
                />
            </TabPanel>
        </>
    );
};
