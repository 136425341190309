import React from "react";
import { DropdownIndicatorProps, GroupBase, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

export const DropdownIndicator: React.FC<
    DropdownIndicatorProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>>
> = (props) => {
    const { setSelector } = useTestSelector();

    return (
        <components.DropdownIndicator {...props}>
            <svg
                {...setSelector("dropdownToggle")}
                height="16"
                width="16"
                viewBox="0 0 320 512"
                aria-hidden="true"
                focusable="false"
            >
                <path
                    fill="%23495057"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                />
            </svg>
        </components.DropdownIndicator>
    );
};
