import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSetAtom } from "jotai";
import {
    ContentEntityType,
    IContentEntityModel,
    ISubFolderModel,
} from "../../models/contentManager/ContentManagerApiModels";
import { ContentManagerActionType } from "./SelectFolderModalHook";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { BulkActionSummaryCancelResult, BulkOperationSummary } from "../../models/BulkOperationModel";
import { handleBulkOperationCancelNotification, handleBulkOperationNotification } from "../../helpers/BulkActionHelper";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useContentEntityApi } from "./ContentEntityApiHook";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { useModalContext } from "../../contexts/ModalContext";
import { SelectFolderModal } from "../../components/contentManager/SelectFolderModal";
import { useProjectContext } from "../../contexts/ProjectContext";
import { loadingModalStateAtom } from "../../atoms/ContentManager";

interface ContentManagerSelectActionProps {
    originFolderName: string | null;
    originFolderId: string | null;
    initialFoldersTree: FoldersTree;
    checkParentExistsOnError: boolean;
}

const mapItemsToContentEntityIdType = (items: IContentEntityModel[]) =>
    items.map((item) => ({
        id: item.id,
        type: item.type === "Folder" ? ContentEntityType.Folder : ContentEntityType.File,
    }));

export const useContentManagerSelectAction = ({
    originFolderName,
    originFolderId,
    initialFoldersTree,
    checkParentExistsOnError,
}: ContentManagerSelectActionProps) => {
    const { t } = useTranslation();
    const { projectId } = useProjectContext();
    const { move, copy } = useContentEntityApi({ projectId, checkParentExistsOnError });
    const { maxFolderDepth } = useAppConfigContext();
    const { showModal, closeModal } = useModalContext();
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const abortRef = useRef<AbortController>();

    const handleAbortController = useCallback(() => {
        abortRef.current = new AbortController();

        return abortRef.current;
    }, []);

    const handleNotification = useCallback(
        (
            result: BulkOperationSummary,
            destination: ITreeItem | null,
            selectedItemsForAction: IContentEntityModel[],
            action: ContentManagerActionType,
        ) => {
            const notificationParams: Record<string, string> = {
                successCount: result.successCount.toString(),
                destinationFolder: destination?.name ?? t("Common.Content"),
                maxFolderDepth: maxFolderDepth.toString(),
                itemName: selectedItemsForAction[0].name,
                originFolder: originFolderName ?? t("Common.Content"),
            };

            action === "Move"
                ? handleBulkOperationNotification(result, "ContentManager.Move", notificationParams)
                : handleBulkOperationCancelNotification(
                      result as BulkActionSummaryCancelResult<ISubFolderModel[]>,
                      "ContentManager.Copy",
                      notificationParams,
                  );
        },
        [originFolderName, t, maxFolderDepth],
    );

    const onCopy = useCallback(
        async (
            destination: ITreeItem | null,
            selectedItemsForAction: IContentEntityModel[],
            action: ContentManagerActionType,
        ) => {
            const targetedEntities = mapItemsToContentEntityIdType(selectedItemsForAction);
            const destinationId = destination ? destination.id : null;
            closeModal();
            setLoadingModalState({
                type: "OPEN_MODAL",
                payload: { label: "ContentManager.Copy.Loading", onCancel: () => abortRef.current?.abort() },
            });

            try {
                const summary = await copy({ targetedEntities, destinationId }, handleAbortController());
                handleNotification(summary, destination, selectedItemsForAction, action);
                return summary;
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                setLoadingModalState({ type: "CLOSE_MODAL" });
            }
        },
        [closeModal, setLoadingModalState, copy, handleAbortController, handleNotification],
    );

    const onMove = useCallback(
        async (
            destination: ITreeItem | null,
            selectedItemsForAction: IContentEntityModel[],
            action: ContentManagerActionType,
        ) => {
            const targetedEntities = mapItemsToContentEntityIdType(selectedItemsForAction);
            const destinationId = destination ? destination.id : null;

            try {
                const summary = await move({ targetedEntities, destinationId });

                handleNotification(summary, destination, selectedItemsForAction, action);
                return summary;
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                closeModal();
            }
        },
        [move, handleNotification, closeModal],
    );

    const canConfirm = useCallback(
        (
            selectedItemsForAction: IContentEntityModel[],
            action: ContentManagerActionType,
            destination?: ITreeItem | null,
        ) => {
            if (destination === undefined) {
                return false;
            }

            const destinationId = destination ? destination.id : null;
            const childrenOfCurrentFolder = selectedItemsForAction.some((item) =>
                item.type === "Folder" ? !!destination?.path.startsWith(item.path) : false,
            );

            if (action !== "Move") {
                return !childrenOfCurrentFolder;
            }

            return !(
                (
                    originFolderId === destinationId || // destination folder is current location
                    selectedItemsForAction.every((item) => item.id === destinationId) || // destination folder is current items if item is a folder
                    childrenOfCurrentFolder
                ) // destination folder is a subfolder of current items, if one of the item is a folder
            );
        },
        [originFolderId],
    );

    type ActionReturnType<T extends ContentManagerActionType> = T extends "Move"
        ? Awaited<ReturnType<typeof move>> | undefined
        : Awaited<ReturnType<typeof copy>> | undefined;

    const onAction = <T extends ContentManagerActionType>(
        action: T,
        items: IContentEntityModel[],
        callback?: (summary: ActionReturnType<T>, destination: ITreeItem | null, actionCancelled: boolean) => void,
    ) => {
        showModal({
            contentToDisplay: SelectFolderModal,
            entity: null,
            formPropsToFlow: {
                foldersTree: initialFoldersTree,
                projectId: projectId,
                canConfirm: (destination?: ITreeItem) => canConfirm(items, action, destination),
                itemIds: items.map((item) => item.id),
                action,
            },
            modalProps: {
                onClose: () => {
                    closeModal();
                },
                className: "select-folder-modal",
                titleKey: `ContentManager.${action}.Title`,
                titleParams: { count: items.length },
                createLabel: `Common.${action}`,
            },
            onCreate: async (destination: ITreeItem | null) => {
                const act = action === "Move" ? onMove : onCopy;
                const actionResult = await act(destination, items, action);
                callback?.(actionResult as ActionReturnType<T>, destination, false);
            },
            onCreated: () => {
                closeModal();
            },
            allowCreateWhenNotDirty: true,
        });
    };

    return {
        onAction,
    };
};
