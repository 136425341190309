import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { IUserWithGroupsAndInvitationStatusModel } from "../../models/user/IUserWithGroupsAndInvitationStatusModel";
import { IUserGroupModel } from "../../models/user/IUserGroupModel";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { CreateUsersInvitationModal, IOnCreatedUserInvitationParams } from "./CreateUsersInvitationModal";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { UserInvitationStatus } from "../../models/user/UserInvitationStatus";
import { LabelCircle } from "../labelCircle/LabelCircle";
import { IResendUserInvitationRequest } from "../../models/user/invitations/IResendUserInvitationRequest";
import { NotificationService } from "../../services/NotificationService";
import { ITableDeleteHelper, Table, TableCreateHelper } from "../table/Table";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { UserAvatar } from "../userGroups/UserAvatar";
import { useManageUsers } from "../../hooks/user/ManageUsersHook";
import { useUserContext } from "../../contexts/UserContext";
import { paths } from "../../PathConstants";
import { useNavigation } from "../../hooks/NavigationHook";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { useUserApi } from "../../hooks/user/UserApiHook";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

import "./UsersView.scss";

const supportedInvitationLanguages = ["en", "fr"];

const columnDefs: IEntityTableColumnDef[] = [
    {
        type: "JSX",
        content: ({ name, firstName, lastName, email, initials }: IUserWithGroupsAndInvitationStatusModel) => (
            <div className={classNames("name")}>
                <div className="user__avatar">
                    <UserAvatar name={firstName && lastName ? name : email} parentType="list" initials={initials} />
                </div>
                <div className="user__info text-truncate">
                    <div className="user__name text-truncate">{name}</div>
                    <div className="user__email text-truncate">{email}</div>
                </div>
            </div>
        ),
        fieldName: "email",
        displayName: "Common.Name",
        shouldTruncateText: true,
        className: "name",
        sortField: {
            name: "Name",
            order: SortOrder.Asc,
        },
    },
    {
        type: "Text",
        content: ({ groups }: IUserWithGroupsAndInvitationStatusModel) =>
            groups.map((g: IUserGroupModel) => g.name).join(", "),
        fieldName: "groups",
        displayName: "Common.Group_plural",
        shouldTruncateText: true,
    },
    {
        type: "JSX",
        content: ({ invitationStatus }: IUserWithGroupsAndInvitationStatusModel) => {
            if (invitationStatus === UserInvitationStatus.Pending) {
                return (
                    <div>
                        <LabelCircle color={"gray"} />
                        <Trans i18nKey={"InvitationStatus.Pending"} />
                    </div>
                );
            }

            if (invitationStatus === UserInvitationStatus.Accepted) {
                return (
                    <div>
                        <LabelCircle color={"green"} />
                        <Trans i18nKey={"InvitationStatus.Accepted"} />
                    </div>
                );
            }

            return <></>;
        },
        fieldName: "invitationStatus",
        displayName: "Common.InvitationStatus",
        shouldTruncateText: true,
        className: "invitation-status",
        sortField: {
            name: "InvitationStatus",
            order: SortOrder.Asc,
        },
    },
];

interface IUserViewProps {
    organizationId?: string;
    projectId?: string;
    readOnly?: boolean;
    canDelete?: boolean;
    entityName?: string;
    customOnLoadError?: (error: Error) => void;
}

/**
 * The users view component.
 */
export const UsersView: React.FC<IUserViewProps> = ({
    organizationId,
    projectId,
    readOnly,
    canDelete,
    entityName,
    customOnLoadError,
}: IUserViewProps) => {
    const { getUsers, removeUsers, getUserScope } = useManageUsers({ organizationId: organizationId, projectId });
    const { t } = useTranslation();
    const { userId: currentUserId } = useUserContext();
    const { replace } = useNavigation();
    const { refreshTable } = useTableRefreshContext();
    const { resendInvite } = useUserApi();

    const filterHelper = useDefaultTableFilterHelper("UsersView.FilterPlaceHolder");

    const resendUserInvite = useCallback(
        async (userInvitationResendRequest: IResendUserInvitationRequest): Promise<void> => {
            try {
                const sentCount = await resendInvite(userInvitationResendRequest);

                if (sentCount === 1) {
                    NotificationService.addSuccessNotification({
                        messageKey: "UsersView.InvitationResendSuccess",
                    });
                }
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [resendInvite],
    );

    const onCreated = useCallback(
        ({ emailsCount, sentCount }: IOnCreatedUserInvitationParams) => {
            if (emailsCount === sentCount) {
                NotificationService.addSuccessNotification({
                    messageKey: "CreateUsersInvitationModal.AllSent",
                });
            } else if (sentCount > 0) {
                NotificationService.addWarningNotification({
                    messageKey: "CreateUsersInvitationModal.PartiallySent",
                });
            } else {
                NotificationService.addErrorNotification({
                    messageKey: "CreateUsersInvitationModal.NoneSent",
                });
            }

            refreshTable("permissions-group");
        },
        [refreshTable],
    );

    const actionProviderCallback = useCallback(
        (
            { invitationStatus, userId }: IUserWithGroupsAndInvitationStatusModel,
            context: ITableContextModel<
                IUserWithGroupsAndInvitationStatusModel,
                ITableRowItem<IUserWithGroupsAndInvitationStatusModel>,
                string
            >,
        ): ITableRowActionProps[] => [
            {
                iconClassName: "fal fa-paper-plane",
                label: "UsersView.ResendInvitation",
                onClick: () => void resendUserInvite({ userId }),
                disabled: invitationStatus !== UserInvitationStatus.Pending,
            },
            {
                iconClassName: "fal fa-minus",
                label: "Common.Remove",
                testSelectorValue: "deleteItem",
                onClick: () => context.onSetItemsForDeletion([userId]),
                transform: (actionProps) => {
                    if (canDelete && !readOnly) {
                        return actionProps;
                    }

                    return null;
                },
                separated: true,
            },
        ],
        [canDelete, readOnly, resendUserInvite],
    );

    const actionProvider = useMemo(() => {
        if (readOnly) {
            return;
        }

        return actionProviderCallback;
    }, [actionProviderCallback, readOnly]);

    const createHelper: TableCreateHelper | undefined = useMemo(() => {
        if (readOnly) {
            return;
        }

        return {
            mode: "table",
            modalComponent: CreateUsersInvitationModal,
            modalComponentProps: {
                supportedLanguages: supportedInvitationLanguages,
                organizationId,
                projectId,
            },
            buttonTextKey: !projectId ? "Administration.InviteAdministrator" : "UsersView.InviteUsers",
            onCreated,
        };
    }, [onCreated, projectId, readOnly, organizationId]);

    const deleteRedirect = useCallback(
        (recordIds?: string[]) => {
            if (recordIds?.includes(currentUserId)) {
                replace(paths.home);
            }

            refreshTable("permissions-group");
        },
        [currentUserId, refreshTable, replace],
    );

    const deleteHelper: ITableDeleteHelper<IUserWithGroupsAndInvitationStatusModel> | undefined = useMemo(() => {
        if (readOnly || !canDelete || !entityName) {
            return;
        }

        const scope = getUserScope();

        return {
            deleteRecords: removeUsers,
            confirmationTitleMessageKey: "UsersView.DeleteTitle",
            confirmationBodyMessageKey: `${scope}UsersView.DeleteConfirmation`,
            confirmationBodyMessageKeyParams: { entityName },
            notificationMessageKey: `${scope}UsersView`,
            notificationMessageKeyParams: { entityName },
            buttonTextKey: "Common.Remove",
            buttonIcon: "fas fa-minus",
            deleteLabel: t("Common.Remove"),
            onDeleted: deleteRedirect,
        };
    }, [readOnly, canDelete, entityName, getUserScope, removeUsers, t, deleteRedirect]);

    return (
        <Table
            className="user-table"
            columnDefs={columnDefs}
            getRecords={getUsers}
            keyExtractor={(item) => item.userId}
            loadingMessageKey="Loading.Users"
            createHelper={createHelper}
            filterHelper={filterHelper}
            actionProvider={actionProvider}
            tableId="users"
            deleteHelper={deleteHelper}
            selectable={!!deleteHelper}
            nameExtractor={(item) => (item.firstName && item.lastName ? item.name : item.email)}
            counterPosition={createHelper ? "End" : "Start"}
            onLoadError={customOnLoadError}
        />
    );
};
