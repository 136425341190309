import { useMemo } from "react";
import { useAtomValue } from "jotai";
import ContentManagerIcon from "../../assets/img/icons/ContentManager.svg";
import { selectedFolderIdAtom } from "../../atoms/ContentManager";

export const useContentManagerEmptyFolderDisplay = () => {
    const currentFolderId = useAtomValue(selectedFolderIdAtom);

    const emptyResultProps = useMemo(() => {
        if (!currentFolderId) {
            return {
                title: "Common.ContentManager",
                description: "ContentManager.EntityDescription",
                logo: {
                    imgSrc: ContentManagerIcon,
                    imgAlt: "",
                },
                noCreateAction: true,
                keepTableHeader: true,
            };
        }

        return {
            title: "",
            description: "ContentManager.EmptyFolder",
            noCreateAction: true,
            keepTableHeader: true,
        };
    }, [currentFolderId]);

    return emptyResultProps;
};
