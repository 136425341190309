import { useCallback } from "react";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { IServiceHookFilter } from "../../models/serviceHooks/IServiceHookFilter";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { IServiceHookExecutionModel } from "../../models/serviceHooks/IServiceHookExecutionModel";
import { useHttpRequest } from "../HttpRequestHook";
import { useCancellableRequest } from "../CancellableRequestHook";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { SortOrder } from "../../models/SortOrder";

interface ServiceHookApiHookReturn {
    get: (serviceHookId: string) => Promise<IServiceHookModel>;
    getRecords: (
        filterValue?: IServiceHookFilter,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IServiceHookModel>>;
    getExecutionRecords: (
        serviceHookId: string,
        filterValue?: never,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IServiceHookExecutionModel>>;
    create: (dataSet: IServiceHookFormFields) => Promise<IServiceHookModel>;
    nameIsUnique: (name: string) => Promise<boolean>;
    remove: (dataSetId: string) => Promise<void>;
    removeRecords: (dataSetIds: string[]) => Promise<IDeleteErrorResult[]>;
    update: (dataSetId: string, dataSet: Partial<IServiceHookModel>) => Promise<IServiceHookModel>;
}

const baseUrl = (projectId: string, serviceId?: string) =>
    `/_api/projects/${projectId}/servicehooks${serviceId ? "/" + serviceId : ""}`;

export const useServiceHookApi = (projectId: string): ServiceHookApiHookReturn => {
    const { httpGetJson, httpPost, httpPut, httpDelete, httpDeleteResponseJson } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const create = useCallback(
        (dataSet: IServiceHookFormFields): Promise<IServiceHookModel> =>
            cancellableRequest<IServiceHookModel, IServiceHookFormFields>(
                { url: baseUrl(projectId), body: dataSet },
                httpPost,
            ),
        [projectId, cancellableRequest, httpPost],
    );

    const remove = useCallback(
        (serviceId: string): Promise<void> => cancellableRequest({ url: baseUrl(projectId, serviceId) }, httpDelete),
        [projectId, cancellableRequest, httpDelete],
    );

    const removeRecords = useCallback(
        (serviceIds: string[]): Promise<IDeleteErrorResult[]> =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(projectId), body: serviceIds },
                httpDeleteResponseJson,
            ),
        [projectId, cancellableRequest, httpDeleteResponseJson],
    );

    const getExecutionRecords = useCallback(
        (serviceHookId: string, _?: never, sortFields?: ISortField[], offset?: number) => {
            let sort;
            if (sortFields && !sortFields?.find((sf) => sf.name === "QueuedDate")) {
                const baseSort = { name: "QueuedDate", order: SortOrder.Desc };

                sort = [...sortFields, baseSort];
            } else {
                sort = sortFields;
            }

            return cancellableRequest<IEntityResult<IServiceHookExecutionModel>>(
                {
                    url: `${baseUrl(projectId, serviceHookId)}/executions`,
                    queryParams: normalizeQueryParams(
                        {
                            sortFields: sort,
                            offset,
                        },
                        false,
                    ),
                },
                httpGetJson,
            );
        },
        [projectId, httpGetJson, cancellableRequest],
    );

    const get = useCallback(
        (serviceHookId: string) =>
            cancellableRequest<IServiceHookModel>(
                {
                    url: baseUrl(projectId, serviceHookId),
                },
                httpGetJson,
            ),
        [projectId, cancellableRequest, httpGetJson],
    );

    const getRecords = useCallback(
        (filterValue?: IServiceHookFilter, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                const { filterText, filterState } = filterValue;
                if (filterText) {
                    filters.Name = filterText;
                    filters.EventType = filterText;
                }

                if (filterState.length > 0) {
                    filters.State = JSON.stringify(filterState);
                }
            }

            return cancellableRequest<IEntityResult<IServiceHookModel>>(
                {
                    url: baseUrl(projectId),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [projectId, httpGetJson, cancellableRequest],
    );

    const nameIsUnique = useCallback(
        (name: string): Promise<boolean> =>
            cancellableRequest<boolean>(
                { url: `${baseUrl(projectId)}/nameisunique`, queryParams: { name } },
                httpGetJson,
            ),
        [projectId, cancellableRequest, httpGetJson],
    );

    const update = useCallback(
        (serviceHookId: string, serviceHook: Partial<IServiceHookModel>): Promise<IServiceHookModel> =>
            cancellableRequest<IServiceHookModel, Partial<IServiceHookModel>>(
                { url: baseUrl(projectId, serviceHookId), body: serviceHook },
                httpPut,
            ),
        [projectId, cancellableRequest, httpPut],
    );

    return {
        create,
        remove,
        removeRecords,
        getExecutionRecords,
        get,
        getRecords,
        nameIsUnique,
        update,
    };
};
