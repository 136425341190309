import React from "react";
import * as Yup from "yup";
import * as YupType from "yup/lib/types";
import { NullableValue } from "../models/NullableValue";
import { ValidatorError } from "./ValidatorError";

export const nameMaxLength: number = 450;
export const descriptionMaxLength: number = 1000;

/**
 * The Yup schema for description property.
 */
export const descriptionSchema = (): Yup.StringSchema<
    NullableValue<string> | undefined,
    YupType.AnyObject,
    NullableValue<string> | undefined
> =>
    Yup.string()
        .nullable(true)
        .max(descriptionMaxLength, ({ max }) => (
            <ValidatorError messageKey="Validation.DescriptionMaxLength" options={{ max }} />
        ));
