import React from "react";
import { ClearIndicatorProps, GroupBase, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";

export const ClearIndicator: React.FC<
    ClearIndicatorProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>>
> = (props) => {
    return (
        <components.ClearIndicator {...props} className="clear-indicator">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                    d="M1.71094 0.914062L6 5.22656L10.2891 0.9375C10.5 0.703125 10.8516 0.703125 11.0859 0.9375C11.2969 1.14844 11.2969 1.5 11.0859 1.71094L6.77344 6.02344L11.0625 10.3125C11.2969 10.5234 11.2969 10.875 11.0625 11.0859C10.8516 11.3203 10.5 11.3203 10.2891 11.0859L5.97656 6.79688L1.6875 11.0859C1.47656 11.3203 1.125 11.3203 0.914062 11.0859C0.679688 10.875 0.679688 10.5234 0.914062 10.2891L5.20312 6L0.914062 1.71094C0.679688 1.5 0.679688 1.14844 0.914062 0.914062C1.125 0.703125 1.47656 0.703125 1.71094 0.914062Z"
                    fill="#495057"
                />
            </svg>
        </components.ClearIndicator>
    );
};
