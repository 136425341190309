import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useContentEntityParams } from "../../../hooks/contentManager/ContentEntityParamsHook";
import { useEditContentItem } from "../../../hooks/contentManager/DetailsView/EditContentItemHook";
import { useCurrentEntitiesNameContext } from "../../../contexts/CurrentEntitiesNameContext";

export const EditItemDetailsWrapper: React.FC = () => {
    const { contentId, contentType } = useContentEntityParams();

    const editContentItem = useEditContentItem({
        itemId: contentId,
        itemType: contentType,
    });

    const { setEntityName } = useCurrentEntitiesNameContext();

    useEffect(() => {
        if (editContentItem.preLoadedItem) {
            const { type, name, id } = editContentItem.preLoadedItem;
            setEntityName(type, name, id);
        }
    }, [editContentItem.preLoadedItem, setEntityName]);

    return <Outlet context={editContentItem} />;
};
