import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";

interface IFeatureItemProps extends PropsWithChildren {
    id: string;
    enabled: boolean;
    featureName: string;
    expandable?: boolean;
}

export const FeatureItem: React.FC<IFeatureItemProps> = ({ id, enabled, featureName, children, expandable = true }) => {
    return (
        <AccordionItem>
            <AccordionHeader targetId={id} className={classNames({ disabled: !enabled, "hide-arrow": !expandable })}>
                <i className={classNames({ "fa fa-check": enabled, "fal fa-ban": !enabled })} />
                <span>{featureName}</span>
            </AccordionHeader>
            <AccordionBody accordionId={id}>{children}</AccordionBody>
        </AccordionItem>
    );
};
