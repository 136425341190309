import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDialogContext } from "../../contexts/DialogContext";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../formValidators/OrganizationFormValidator";
import { IOrganizationModel, OrganizationLicenseState } from "../../models/organization/IOrganizationModel";
import { useOrganizationApi } from "./OrganizationApiHook";
import { ConfirmationDialogBody } from "../../components/dialogs/ConfirmationDialogBody";
import { NotificationService } from "../../services/NotificationService";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { useLicenseFeatures } from "../license/LicenseFeatureHook";

export const useLicenseActivation = () => {
    const { show } = useDialogContext();
    const {
        entityProps: {
            entity: { licenseKey, organizationId },
            setEntityProperties,
            setInitialEntityProperties,
            initialEntity,
        },
        dirtyProps: { isDirty },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const { activateLicense } = useOrganizationApi();
    const { setLicenseFeatures } = useLicenseFeatures();

    const [isActivating, setIsActivating] = useState(false);

    const currentOrgId = useRef<string>(organizationId);

    useEffect(() => {
        currentOrgId.current = organizationId;
        setIsActivating(false);
    }, [organizationId]);

    const onActivation = async () => {
        setIsActivating(true);
        try {
            const { organization, success } = await activateLicense(organizationId, licenseKey!);

            if (currentOrgId.current !== organization.organizationId) {
                return;
            }

            if (!success) {
                NotificationService.addErrorNotification({
                    messageKey: "Licensing.ActivationFailed",
                });
            } else if (organization.licenseState === OrganizationLicenseState.Active) {
                NotificationService.addSuccessNotification({
                    messageKey: "Licensing.ActivationSuccess",
                });
            }
            await setLicenseFeatures(organizationId);
            setEntityProperties(organization);
            setInitialEntityProperties(organization);
        } catch (error) {
            if (currentOrgId.current === organizationId) {
                NotificationService.addErrorNotification({
                    message: extractErrorMessageOrEmptyString(error),
                });
            }
        } finally {
            setIsActivating(false);
        }
    };

    const content = useMemo(() => <ConfirmationDialogBody messageKeys={["Organization.UnsavedWarningBody"]} />, []);

    const tryActivateLicense = () => {
        if (!isDirty()) {
            onActivation();
            return;
        }

        show({
            type: "Warning",
            content,
            onProceed: () => {
                setEntityProperties(initialEntity);
                onActivation();
                return Promise.resolve();
            },
        });
    };

    return { tryActivateLicense, isActivating };
};
