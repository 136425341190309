import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { IGroupPermissionModel } from "../models/permissions/IGroupPermissionModel";
import { extractErrorMessageOrEmptyString } from "../helpers/ErrorHelper";
import { NotificationService } from "../services/NotificationService";
import { IEditPermissionGroupModel } from "../models/permissions/IEditPermissionGroupModel";
import { PermissionGroupFormValidator } from "./PermissionGroupFormValidator";

interface IContentPermissionGroupFormValidatorProps {
    validateGroupPermissionCallback: (groupPermissions: IGroupPermissionModel[]) => Promise<Record<string, string>>;
}

export const isContentPermissionGroupFormValidator = (
    formValidator: PermissionGroupFormValidator<IEditPermissionGroupModel>,
): formValidator is ContentPermissionGroupFormValidator => {
    return formValidator instanceof ContentPermissionGroupFormValidator;
};

export class ContentPermissionGroupFormValidator extends PermissionGroupFormValidator<IEditPermissionGroupModel> {
    protected contentSchema = this.schema.concat(
        Yup.object().shape({
            groupPermissions: Yup.array().test("inherited-value-validation", async (groupPermissions) => {
                const [prevValue, prevPermissionCheck] = this.lastPermissionCheck;

                if (isEqual(prevValue, groupPermissions)) {
                    return prevPermissionCheck;
                }

                this.lastPermissionCheck = [
                    groupPermissions as IGroupPermissionModel[],
                    (async () => {
                        try {
                            const result = await this.validateGroupPermissionCall(
                                groupPermissions as IGroupPermissionModel[],
                            );
                            return isEmpty(result) || new Yup.ValidationError(JSON.stringify(result));
                        } catch (e) {
                            NotificationService.addErrorNotification({
                                message: extractErrorMessageOrEmptyString(e),
                            });
                            return false;
                        }
                    })(),
                ];

                const [, newPermissionCheck] = this.lastPermissionCheck;

                return newPermissionCheck;
            }),
        }),
    );

    private validateGroupPermissionCall: (groupPermissions: IGroupPermissionModel[]) => Promise<Record<string, string>>;
    private lastPermissionCheck: [IGroupPermissionModel[] | undefined, Promise<boolean | Yup.ValidationError>] = [
        [],
        Promise.resolve(false),
    ];

    constructor({ validateGroupPermissionCallback }: IContentPermissionGroupFormValidatorProps) {
        super();

        this.validateGroupPermissionCall = validateGroupPermissionCallback;
    }

    public validateGroupPermissions(
        groupPermissions: IGroupPermissionModel[],
    ): Promise<IGroupPermissionModel[] | undefined> {
        return this.contentSchema.fields.groupPermissions.validate(groupPermissions);
    }

    public isValid(data: IEditPermissionGroupModel): Promise<boolean> {
        return this.contentSchema.isValid(data);
    }
}
