import * as Yup from "yup";
import { IAddContentPermissionGroupFormFieldsModel } from "../models/permissions/IAddContentPermissionGroupFormFieldsModel";
import { FormValidator } from "./FormValidator";

export class AddContentPermissionGroupFormValidator extends FormValidator<IAddContentPermissionGroupFormFieldsModel> {
    protected schema = Yup.object().shape({
        groupId: Yup.string().required("Common.Required"),
    });

    public validateGroupId(groupId: string): Promise<string> {
        return this.schema.fields.groupId.validate(groupId);
    }
}
