import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Virtuoso } from "react-virtuoso";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { FolderTreeItem } from "./FolderTreeItem";
import { FluentFolderIcon } from "../../icons/FluentFolderIcon";
import { FoldersTree as IFoldersTree, ITreeItem } from "../../../models/contentManager/FolderTree";
import { shouldDisplayItem } from "../../../helpers/TreeFolderHelper";

interface IContentManagerTreeMenuProps {
    handleTreeItem: (item: ITreeItem | null) => void;
    foldersTree: IFoldersTree;
    selectedFolderId: string | null | undefined;
    getSubFolders: (item: ITreeItem) => Promise<IFoldersTree>;
    handleCollapse: (id: string, state: boolean) => void;
    setItemLoadingState: (id: string, state: boolean) => void;
}

/**
 * The content manager tree menu component.
 */
export const FoldersTree: React.FC<IContentManagerTreeMenuProps> = ({
    handleTreeItem,
    foldersTree,
    selectedFolderId,
    getSubFolders,
    handleCollapse,
    setItemLoadingState,
}: IContentManagerTreeMenuProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const itemContent = (index: number, folderTree: IFoldersTree) => {
        const item = folderTree[index];
        return (
            <FolderTreeItem
                key={item.id}
                item={item}
                getSubFolders={getSubFolders}
                handleCollapse={handleCollapse}
                handleTreeItem={handleTreeItem}
                selectedFolderId={selectedFolderId}
                setItemLoadingState={setItemLoadingState}
            />
        );
    };

    const filteredItem = foldersTree.filter((item) => shouldDisplayItem(item, foldersTree));

    const onRootFolderClicked = () => handleTreeItem(null);

    return (
        <div className="content-manager-tree-menu">
            <div
                className={classNames("tree-menu-item tree-root", {
                    active: selectedFolderId === null,
                })}
                {...setSelector("folder-Files")}
            >
                <button className="tree-menu-item-name-container" onClick={onRootFolderClicked}>
                    <FluentFolderIcon />
                    <span className="tree-menu-item-name">{t("ContentManager.Files")}</span>
                </button>
            </div>
            <div className="virtuoso-container">
                <Virtuoso
                    style={{ height: "100%" }}
                    totalCount={filteredItem.length}
                    itemContent={(index: number) => itemContent(index, filteredItem)}
                />
            </div>
        </div>
    );
};
