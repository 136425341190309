import { atom, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useOrganizationApi } from "../organization/OrganizationApiHook";
import { ILicenseFeatures } from "../../models/license/ILicenseFeatureModel";

const licenseFeaturesAtom = atom<ILicenseFeatures | null>(null);
const loadingFeaturesAtom = atom<boolean>(false);

export const featuresStateAtom = atom<{
    features: ILicenseFeatures | null;
    isLoadingFeatures: boolean;
}>((get) => ({
    features: get(licenseFeaturesAtom),
    isLoadingFeatures: get(loadingFeaturesAtom),
}));

export const useLicenseFeatures = (organizationId?: string) => {
    const setLicenseFeaturesAtom = useSetAtom(licenseFeaturesAtom);
    const setIsLoadingFeatures = useSetAtom(loadingFeaturesAtom);
    const { getLicenseFeatures } = useOrganizationApi();

    const setLicenseFeatures = useCallback(
        async (id: string) => {
            try {
                setIsLoadingFeatures(true);
                const apiLicense = await getLicenseFeatures(id);
                if (Object.keys(apiLicense).length === 0) {
                    setLicenseFeaturesAtom(null);
                    return;
                }

                setLicenseFeaturesAtom(apiLicense);
            } catch (error) {
                setLicenseFeaturesAtom(null);
            } finally {
                setIsLoadingFeatures(false);
            }
        },
        [getLicenseFeatures, setIsLoadingFeatures, setLicenseFeaturesAtom],
    );

    useEffect(() => {
        if (!organizationId) {
            return;
        }

        void setLicenseFeatures(organizationId);
    }, [organizationId, setLicenseFeatures]);

    return { setLicenseFeatures };
};
