import React from "react";
import classNames from "classnames";
import { MultiValueGenericProps, GroupBase, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface IMultiValueContainerProps
    extends MultiValueGenericProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    data: SelectComponentOptions<any>;
}

export const MultiValueContainer: React.FC<IMultiValueContainerProps> = ({ children, innerProps, ...props }) => {
    const { setSelector } = useTestSelector();
    const { data } = props;
    const { className, ...rest } = innerProps;

    return (
        <components.MultiValueContainer
            {...props}
            innerProps={{ className: classNames(className, "multi-value-container"), ...rest, ...setSelector(`dropdownSelectedItem-${data.testSelectorValue ?? ""}`) }}
        >
            {data.icon && (
                <span className="icon-container">
                    <i className={data.icon} />
                </span>
            )}
            {!!data.componentIcon && data.componentIcon}
            {children}
        </components.MultiValueContainer>
    );
};
