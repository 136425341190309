import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import classNames from "classnames";
import { Button } from "../../buttons/Button";
import { InputWithCopy } from "../../forms/inputs/InputWithCopy";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";
import { FormattedDate } from "../../date/FormattedDate";
import {
    IOrganizationModel,
    OrganizationLicenseState,
    OrganizationLicenseStateTranslationMap,
} from "../../../models/organization/IOrganizationModel";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../../formValidators/OrganizationFormValidator";
import { useLicenseActivation } from "../../../hooks/organization/LicenseActivationHook";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";

import "./LicenseStatusCard.scss";

const getLicenseStateClassname = (licenseState: OrganizationLicenseState) => {
    switch (licenseState) {
        case OrganizationLicenseState.Expired:
        case OrganizationLicenseState.Inactive:
            return "invalid";
        case OrganizationLicenseState.Active:
            return "active";
        default:
            return "";
    }
};

export const LicenseStatusCard: React.FC = () => {
    const { t } = useTranslation();
    const {
        entityProps: { entity: organization, formValidator, setEntityProperties },
        permissionsProps: { canEdit },
        errorProps: {
            setErrors,
            errors: { licenseKey: activationKeyError },
        },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const { isActivating, tryActivateLicense } = useLicenseActivation();

    useEffect(() => {
        let cancel = false;
        const checkLicenseKey = async () => {
            let errorMessage: string;

            try {
                await formValidator.validateActivationKey(organization.licenseKey);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            if (!cancel) {
                setErrors({ licenseKey: errorMessage });
            }
        };

        void checkLicenseKey();

        return () => {
            cancel = true;
        };
    }, [formValidator, organization.licenseKey, setErrors]);

    const onActivationKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntityProperties({ licenseKey: event.target.value });
    };

    return (
        <Card className="license-status">
            <CardHeader className="entity-license-status">
                <span>{t("License.State")}</span>
                <span className={classNames("license-state", getLicenseStateClassname(organization.licenseState))}>
                    {t(OrganizationLicenseStateTranslationMap[organization.licenseState])}
                </span>
            </CardHeader>
            <CardBody>
                <FormGroup className="entity-license-status__body">
                    <div>
                        <ReadOnlyInputWithCopy
                            label="Organization.OrganizationId"
                            displayedValueWhileCopying={"Organization.OrganizationId.Copied"}
                            value={organization.organizationId}
                        />
                    </div>
                    <FormGroup>
                        <LocalizedLabel for="edit-organization-license-key" text="Licensing.ActivationKey" />
                        <div className="entity-license-status__activate">
                            <div className="entity-license-status__activate__input">
                                <InputWithCopy
                                    id="edit-organization-license-key"
                                    name="license-key"
                                    type="textfield"
                                    onChange={onActivationKeyChange}
                                    error={activationKeyError}
                                    value={organization.licenseKey ?? ""}
                                    disabled={!canEdit}
                                    displayedValueWhileCopying="Licensing.ActivationKeyCopied"
                                />
                            </div>
                            <Button
                                ariaLabel="activate-license-key"
                                color="secondary"
                                outline
                                disabled={!!activationKeyError || !organization.licenseKey}
                                onClick={tryActivateLicense}
                                loading={isActivating}
                                className="btn-activate"
                                size="lg"
                            >
                                {t("Common.Activate")}
                            </Button>
                        </div>
                    </FormGroup>
                </FormGroup>
                {organization.licenseState !== OrganizationLicenseState.None && (
                    <FormGroup className="entity-license-status__footer">
                        {organization.licenseActivatedDate && (
                            <div className="entity-license-status__footer__date">
                                <span className="last-activated">{t("License.LastActivated")}</span>
                                <FormattedDate date={organization.licenseActivatedDate} format="DateTime" />
                            </div>
                        )}
                        {organization.licenseExpirationDate && (
                            <div
                                className={classNames(
                                    "entity-license-status__footer__date",
                                    getLicenseStateClassname(organization.licenseState),
                                )}
                            >
                                <span className="expiration-date">{t("License.ExpirationDate")}</span>
                                <FormattedDate date={organization.licenseExpirationDate} format="Date" utc />
                            </div>
                        )}
                    </FormGroup>
                )}
            </CardBody>
        </Card>
    );
};
