import React, { useMemo } from "react";
import classNames from "classnames";
import { INavTabProps } from "../tabs/NavTab";
import { useTabs } from "../../hooks/TabsHook";
import { NavTabs } from "../tabs/NavTabs";
import { PermissionsGroupView } from "./PermissionsGroupView";
import { ProjectPermissionsGroupView } from "./ProjectPermissionsGroupView";
import { ContentManagerEntityType } from "../../models/EntityType";

const permissionTabs = ["group"] as const;

type PermissionsViewTabType = (typeof permissionTabs)[number];

interface IPermissionsTabsProps {
    organizationId?: string;
    projectId?: string;
    contentId?: string;
    contentType?: ContentManagerEntityType;
    groupLink: (groupId: string) => string;
    customOnLoadError?: (error: Error) => void;
}

const tabsBuilder: INavTabProps[] = [
    {
        displayName: "Common.Groups",
        name: "group",
        invalid: false,
        testSelectorValue: "groupsTab",
        onToggle: () => {
            return;
        },
        tabcontrol: "group",
        id: "group-id",
    },
];

export const PermissionsTabs: React.FC<IPermissionsTabsProps> = ({
    contentId,
    organizationId,
    projectId,
    groupLink,
    contentType,
    customOnLoadError,
}: IPermissionsTabsProps) => {
    const [activeTab] = useTabs<PermissionsViewTabType>(permissionTabs, "group");

    const groupView = useMemo(() => {
        if (projectId) {
            return (
                <ProjectPermissionsGroupView
                    contentId={contentId}
                    projectId={projectId}
                    groupLink={groupLink}
                    contentType={contentType}
                />
            );
        }

        return (
            <PermissionsGroupView
                organizationId={organizationId}
                groupLink={groupLink}
                customOnLoadError={customOnLoadError}
            />
        );
    }, [projectId, organizationId, contentId, contentType, customOnLoadError, groupLink]);

    return (
        <section className={classNames("edit-permissions-group tab-content-section")}>
            <NavTabs activeTab={activeTab} navTabs={tabsBuilder}>
                {groupView}
            </NavTabs>
        </section>
    );
};
