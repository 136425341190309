import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { IProjectModel } from "../../models/project/IProjectModel";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { LoadingModal } from "../modals/LoadingModal";
import ProjectIcon from "../../assets/img/icons/Project.svg";
import { projectPaths } from "../../PathConstants";
import { ProjectStatus } from "../../models/project/ProjectStatus";
import { SortOrder } from "../../models/SortOrder";
import { Table } from "../table/Table";
import { ProjectNameWithAvatar } from "../projects/ProjectNameWithAvatar";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { useOrganizationProjects } from "../../hooks/organization/OrganizationProjectsHook";

interface IOrganizationProjectsViewProps {
    organizationName: string;
    organizationId: string;
    customOnLoadError?: (error: Error) => void;
}

export const OrganizationProjectsView: React.FC<IOrganizationProjectsViewProps> = ({
    organizationName,
    organizationId,
    customOnLoadError,
}) => {
    const {
        createHelper,
        deleteHelper,
        isAllowed,
        filterHelper,
        loadOrganizationProjects,
        permissionKeys,
        setShowLoadingModal,
        showInviteModal,
        showLoadingModal,
        triggerRefresh,
    } = useOrganizationProjects(organizationId);

    const actionProvider = useCallback(
        (
            item: IProjectModel,
            context: ITableContextModel<IProjectModel, ITableRowItem<IProjectModel>, string>,
        ): ITableRowActionProps[] => {
            const projectWriteKey = formatPermissionKey(PermissionKeys.project.write, item.projectId);
            const projectDeleteKey = formatPermissionKey(PermissionKeys.project.delete, item.projectId);

            return [
                {
                    iconClassName: "fal fa-external-link-alt",
                    label: "Common.Open",
                    tag: Link,
                    to: projectPaths.link.dashboard(organizationName, item.name),
                    testSelectorValue: "openItem",
                    transform: (actionProps) => {
                        if (item.isProjectUser) {
                            return actionProps;
                        }

                        return null;
                    },
                },
                {
                    iconClassName: "fal fa-user-plus",
                    label: "UsersInvitation.InviteOwners",
                    onClick: () => showInviteModal(item),
                    permissions: [projectWriteKey],
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.projectId]),
                    permissions: [projectDeleteKey],
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [showInviteModal, organizationName],
    );

    const projectNameContent = useCallback(
        (project: IProjectModel) => (
            <ProjectNameWithAvatar
                project={project}
                organizationName={organizationName}
                onProjectStillAwaiting={() => setShowLoadingModal(true)}
            />
        ),
        [organizationName, setShowLoadingModal],
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "name",
            displayName: "Common.Name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
            content: projectNameContent,
            shouldTruncateText: true,
            testSelectorColumnName: "name",
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Common.Description",
            sortField: {
                name: "Description",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "description",
        },
    ];

    return (
        <>
            <LoadingModal
                show={showLoadingModal}
                onButtonClose={() => setShowLoadingModal(false)}
                labelKey="Project.CreateProject.Loading"
            />
            <Table
                className="organization-projects-table"
                actionProvider={actionProvider}
                columnDefs={columnDefs}
                getRecords={loadOrganizationProjects}
                keyExtractor={(item) => item.projectId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="Loading.Projects"
                rowDisabled={(item) => item.state === ProjectStatus.AwaitingCreation}
                createHelper={createHelper}
                deleteHelper={deleteHelper}
                filterHelper={filterHelper}
                selectable={isAllowed(permissionKeys.projectsDeleteKey)}
                triggerRefresh={triggerRefresh}
                tableId="projects"
                noResultsCTAProps={{
                    title: "Route.Projects",
                    description: "EntityDescription.Projects",
                    createTitle: "Common.Create",
                    logo: {
                        imgSrc: ProjectIcon,
                        imgAlt: "",
                    },
                }}
                counterPosition={createHelper ? "End" : "Start"}
                onLoadError={customOnLoadError}
            />
        </>
    );
};
