import React from "react";

import "./ContentInfoBox.scss";

interface IContentInfoBoxProps {
    message: string;
}

export const ContentInfoBox: React.FC<IContentInfoBoxProps> = ({ message }) => {
    return <div className="contentInfoBox-message">{message}</div>;
};
