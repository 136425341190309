import React, { useCallback, useState } from "react";
import { CurrentEntitiesNameContext } from "../../contexts/CurrentEntitiesNameContext";
import { EntityType } from "../../models/EntityType";

export const CurrentEntitiesNameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [entitiesName, setEntitiesName] = useState<Partial<Record<string, string>>>({});

    const setEntityName = useCallback((entityType: EntityType, entityName: string, entityId?: string) => {
        const key = entityId ? `${entityType}:${entityId}` : entityType;

        setEntitiesName((curr) => ({ ...curr, [key]: entityName }));
    }, []);

    const getEntityName = useCallback(
        (entityType: EntityType, entityId?: string) => {
            const key = entityId ? `${entityType}:${entityId}` : entityType;
            const entityName = entitiesName[key];

            if (entityName) {
                return entityName;
            }

            return entitiesName[entityType] ?? "";
        },
        [entitiesName],
    );

    return (
        <CurrentEntitiesNameContext.Provider value={{ getEntityName, setEntityName }}>
            {children}
        </CurrentEntitiesNameContext.Provider>
    );
};
