import { useCallback, useMemo } from "react";
import { NameValidator } from "../../formValidators/NameValidator";
import { CreateDataSetFormValidator } from "../../formValidators/CreateDataSetFormValidator";
import { IDataSetFormFields } from "../../models/dataSets/IDataSetFormField";
import { useDefaultEntityValidation } from "../validation/EntityValidationHook";
import { IValidationResult } from "../CreateModalEntityHook";
import { useDataSetApi } from "./DataSetApiHook";

export const useDataSetValidation = ({
    description,
    name,
    type,
    projectId,
}: IDataSetFormFields): IValidationResult<IDataSetFormFields, "name"> => {
    const { nameIsUnique } = useDataSetApi(projectId);
    const nameValidatorProvider = useCallback(() => {
        return new NameValidator({
            nameExistErrorKey: "DataSet.NameAlreadyExists",
            nameIsUnique,
        });
    }, [nameIsUnique]);

    const formValidationProvider = useCallback(() => new CreateDataSetFormValidator(), []);
    const fields = useMemo(() => ({ name, description, type, projectId }), [description, name, projectId, type]);
    const { valid, errors, nameValidationStatus, setError } = useDefaultEntityValidation({
        fields,
        nameValidatorProvider,
        formValidationProvider,
    });

    return {
        valid,
        errors,
        validationStatuses: { name: nameValidationStatus },
        setError,
    };
};
