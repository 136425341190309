import { useCallback } from "react";
import { BulkOperationSummaryWithPayload } from "../../models/BulkOperationModel";
import { ISubFolderModel } from "../../models/contentManager/ContentManagerApiModels";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { ITreeItem } from "../../models/contentManager/FolderTree";
import { useSetAtom } from "jotai";
import { copyFolderAtom, moveFolderAtom } from "../../atoms/contentManager/foldersTree";

export const useContentManagerTableActionCallback = (tableId: string) => {
    const { refreshTable } = useTableRefreshContext();
    const moveFolder = useSetAtom(moveFolderAtom);
    const copyFolder = useSetAtom(copyFolderAtom);

    const onCopyDone = useCallback(
        (
            summary: BulkOperationSummaryWithPayload<ISubFolderModel[]> | undefined,
            destination: ITreeItem | null | undefined,
            actionCancelled: boolean,
        ) => {
            if (actionCancelled || destination === undefined) {
                return;
            }

            const destinationId = destination ? destination.id : null;
            summary?.payload.forEach((item) => {
                copyFolder(item, destinationId);
            });
            refreshTable(tableId, true);
        },
        [copyFolder, refreshTable, tableId],
    );

    const onMoveDone = useCallback(
        (summary: BulkOperationSummaryWithPayload<string[]> | undefined, destination: ITreeItem | null | undefined) => {
            if (destination === undefined) {
                return;
            }
            const destinationId = destination ? destination.id : null;
            summary?.payload.forEach((id) => {
                moveFolder(id, destinationId);
            });
            refreshTable(tableId, true);
        },
        [moveFolder, refreshTable, tableId],
    );

    return {
        onCopyDone,
        onMoveDone,
    };
};
