import React, { useCallback } from "react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { EditContentPermissionsGroupViewTab } from "./EditContentPermissionsGroupViewTabs";
import { useEditPermissionsGroup } from "../../hooks/permission/EditPermissionsGroupHook";
import { LoadingScreen } from "../loading/LoadingScreen";
import { ContentPermissionGroupFormValidator } from "../../formValidators/ContentPermissionGroupFormValidator";
import { ReturnSection } from "../layout/ReturnSection";
import { PermissionsGroupTitle } from "./PermissionsGroupTitle";
import { NotificationService } from "../../services/NotificationService";
import { useProjectContext } from "../../contexts/ProjectContext";
import { PermissionGroupPaths } from "../../PathConstants";
import { useContentEntityParams } from "../../hooks/contentManager/ContentEntityParamsHook";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";
import { useContentGroupApi } from "../../hooks/contentManager/ContentGroupApiHook";
import { IGroupPermissionModel } from "../../models/permissions/IGroupPermissionModel";

export const EditPermissionsContentGroupView: React.FC = () => {
    const projectContext = useProjectContext();
    const { contentId, contentType } = useContentEntityParams();
    const { validatePermissions } = useContentGroupApi({ contentId, projectId: projectContext.projectId });

    const linkToGroupListProvider = useCallback(() => {
        return PermissionGroupPaths.link.base.content(
            projectContext.organizationName,
            projectContext.name,
            contentId,
            contentType,
        );
    }, [contentId, contentType, projectContext.name, projectContext.organizationName]);
    const {
        group,
        getGroup,
        projectId,
        linkToGroupList,
        nameIsUnique,
        saveGroup: updateGroup,
        canEdit,
        removeGroup,
    } = useEditPermissionsGroup({ contentId, linkToGroupListProvider });
    const { t } = useTranslation();

    const groupId = group?.groupId;
    const deleteHandler = useCallback(() => {
        if (!groupId || !projectId) {
            NotificationService.addErrorNotification({
                messageKey: "PermissionsGroupView.DeleteFail",
            });
            return Promise.resolve();
        }

        return removeGroup();
    }, [groupId, projectId, removeGroup]);

    const formValidatorProvider = useCallback(
        () =>
            new ContentPermissionGroupFormValidator({
                validateGroupPermissionCallback: (gp: IGroupPermissionModel[]) => validatePermissions(groupId!, gp),
            }),
        [groupId, validatePermissions],
    );

    if (!group) {
        return <LoadingScreen>{t("Loading.Group")}</LoadingScreen>;
    }

    return (
        <>
            <ReturnSection label={t("PermissionsGroup.ReturnToGroups")} route={linkToGroupList} />
            <EditEntityWrapper
                className="edit-group-view"
                canEdit={canEdit}
                canDelete={canEdit}
                delete={deleteHandler}
                loadedEntity={group}
                load={getGroup}
                entityType="Permissions.Group"
                deleteDialogProps={{
                    dialogBodyKey: "CommonGroup.DeleteBody",
                    dialogTitleKey: "CommonGroup.DeleteTitle",
                    entityCount: 1,
                }}
                formValidatorProvider={formValidatorProvider}
                loadingScreenKey="Loading.Group"
                nameIsUniqueProps={{
                    nameExistErrorKey: "PermissionGroupFormValidator.GroupExists",
                    nameIsUnique,
                }}
                promptDialogMessageKey="PermissionGroup.UnsavedWarningBody"
                update={updateGroup}
                propertiesComparator={{ groupPermissions: isEqual }}
                header={
                    <SectionHeader>
                        <PermissionsGroupTitle permissionsGroup={group} />
                        <SectionHeader.ActionGroup
                            withSaveButton={canEdit}
                            dropdownMenu={<DefaultEditDropdownMenu canDelete={canEdit} />}
                        />
                    </SectionHeader>
                }
            >
                <EditContentPermissionsGroupViewTab
                    projectId={projectContext.projectId}
                    contentId={contentId}
                    contentType={contentType}
                />
            </EditEntityWrapper>
        </>
    );
};
