import React from "react";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { ContentManagerContentView } from "./ContentManagerContentView";
import { EntityViewBody } from "../views/EntityViewBody";
import { searchStatusAtom } from "../../atoms/ContentManager";
import { ContentManagerSearchResultView } from "./ContentManagerSearchResultView";
import { ContentManagerSearchBar } from "./ContentManagerSearchBar";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { ContentManagerTreeView } from "./ContentManagerTreeView";

import "./ContentManagerView.scss";

/**
 * The content manager view component.
 */
export const ContentManagerView: React.FC = () => {
    const searchStatus = useAtomValue(searchStatusAtom);
    const { t } = useTranslation();

    return (
        <>
            <SectionHeader>
                <SectionHeader.Group>
                    <SectionHeader.Title>{t("Common.Content")}</SectionHeader.Title>
                </SectionHeader.Group>
                <SectionHeader.Group flexGrow>
                    <ContentManagerSearchBar />
                </SectionHeader.Group>
                <SectionHeader.ActionGroup />
            </SectionHeader>
            <EntityViewBody className="content-manager-view-body">
                <ContentManagerTreeView />
                {searchStatus ? <ContentManagerSearchResultView /> : <ContentManagerContentView />}
            </EntityViewBody>
        </>
    );
};
