import { useLocation } from "react-router-dom";
import { useNavigation } from "../NavigationHook";
import { useProjectApi } from "./ProjectApiHook";
import { useCallback, useEffect, useState } from "react";
import { pathKeys, projectPaths } from "../../PathConstants";

/**
 * Unchecked = Waiting for authorization to complete.
 * Checking = Currently fetching single project data to see if reroute is necessary
 * Proceed = Single project data has been fetched and user has been re-routed if necessary.
 */
type SingleProjectDataStatus = "Unchecked" | "Checking" | "Proceed";

export const useSingleProjectData = (ready: boolean, authorized: boolean): SingleProjectDataStatus => {
    const { pathname } = useLocation();
    const { replace } = useNavigation();
    const { getSingleProjectData } = useProjectApi();

    const [status, setStatus] = useState<SingleProjectDataStatus>("Unchecked");

    const handleSingleProjectData = useCallback(async () => {
        try {
            const model = await getSingleProjectData();
            if (model.projectName && model.organizationName) {
                replace(projectPaths.link.dashboard(model.organizationName, model.projectName));
            }
        } finally {
            setStatus("Proceed");
        }
    }, [getSingleProjectData, replace]);

    useEffect(() => {
        if (!ready || status !== "Unchecked") {
            return;
        }

        if (pathname === pathKeys.root && authorized) {
            handleSingleProjectData();
            setStatus("Checking");
        } else {
            setStatus("Proceed");
        }
    }, [handleSingleProjectData, pathname, ready, authorized, status]);

    return status;
};
