import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { buildOverview, buildHistory, buildPermissions } from "../../../helpers/EntityHelper";
import { useTabs } from "../../../hooks/TabsHook";
import { NavMenu } from "../../navbars/NavMenu";
import { INavMenuItemProps } from "../../navbars/NavMenuItem";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { EditItemOverview } from "./EditItemOverview";
import { TabContent } from "../../tabs/TabContent";
import { useContentFolderApi } from "../../../hooks/contentManager/ContentFolderApiHook";
import { HistoryTable } from "../../table/HistoryTable";
import { PermissionsTabs } from "../../permissions/PermissionsTabs";
import { PermissionGroupPaths } from "../../../PathConstants";
import { useFeatureCheck } from "../../../hooks/license/LicenseCheckHook";

import "./EditItemViewTabs.scss";

/**
 * the list of tabs in the edit folder view
 */
const editFolderViewTabs = ["overview", "history", "permissions"] as const;

/**
 * the list of tabs in the edit folder view as Type
 */
type EditFolderTabType = (typeof editFolderViewTabs)[number];

/**
 * The edit folder View tabs
 */
export const EditFolderViewTabs: React.FC = () => {
    const { projectId, organizationName, name } = useProjectContext();
    const [activeTab, setActiveTab] = useTabs<EditFolderTabType>(editFolderViewTabs, "overview");
    const {
        entityProps: { entity: editableFolder },
        dirtyProps: { isDirty },
        errorProps: { errors },
    } = useEditEntityContext<IContentEntityModel, ContentFormValidator>();
    const { t } = useTranslation();
    const { getHistoryRecords } = useContentFolderApi(projectId);
    const { checkFeature } = useFeatureCheck();
    const explicitPermissionsCheck = checkFeature("contentFeature", "explicitPermissionsManagement");

    const tabs: INavMenuItemProps[] = useMemo(
        () => [
            buildOverview(!!errors.description, isDirty(["description"]), setActiveTab),
            buildHistory(false, false, setActiveTab),
            ...(explicitPermissionsCheck ? [buildPermissions(false, false, setActiveTab)] : []),
        ],
        [setActiveTab, isDirty, errors.description, explicitPermissionsCheck],
    );

    const getRecords = useCallback(
        (updatedEntityDate?: string, offset?: number) =>
            getHistoryRecords(editableFolder.id, updatedEntityDate, offset),
        [editableFolder.id, getHistoryRecords],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <EditItemOverview />
            </TabContent>
            <TabContent tabId="history" className="history" title={t("Common.History")}>
                <HistoryTable
                    getRecords={getRecords}
                    updatedEntityDate={editableFolder.modifiedOn}
                    entityType="Folder"
                />
            </TabContent>
            {explicitPermissionsCheck && (
                <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")} hasNavTabs>
                    <PermissionsTabs
                        projectId={projectId}
                        contentId={editableFolder.id}
                        groupLink={(id) =>
                            PermissionGroupPaths.link.edit.content(
                                organizationName,
                                name,
                                editableFolder.id,
                                "Folder",
                                id,
                            )
                        }
                        contentType="Folder"
                    />
                </TabContent>
            )}
        </NavMenu>
    );
};
