import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import useResizeObserver from "@react-hook/resize-observer";
import ExperlogixDocumentsMenuLogoWithText from "../assets/img/icons/ExperlogixDocumentsText_Menu.svg";
import { DefaultNavbar } from "../components/navbars/DefaultNavbar";
import { Sidebar, sidebarWidthBreakpoint } from "../components/sidebar/Sidebar";
import { IRoute } from "../models/IRoute";
import { NotificationService } from "../services/NotificationService";
import { CookieHelper } from "../helpers/CookieHelper";
import { IMvcMessagesModel } from "../models/IMvcMessagesModel";
import { IMvcMessageModel } from "../models/IMvcMessageModel";
import { getItem, setItem } from "../helpers/LocalStoragHelper";
import { useUserContext } from "../contexts/UserContext";
import { FullscreenContext } from "../contexts/FullscreenContext";
import { SidePanelWithContext } from "../components/sidePanel/SidePanelWithContext";
import { useNavigationCloseHandler } from "../hooks/modals/NavigationCloseHandlerHook";
import { NotificationsContainer } from "../components/notifications/NotificationsContainer";
import { useNotificationServiceContext } from "../contexts/NotificationServiceContext";

/**
 * The sidebar layout props interface.
 */
interface ISidebarLayoutProps {
    routes: IRoute[];
    footerRoutes?: IRoute[];
    children?: React.ReactNode;
}

const mvcMessagesCookieName = "mvc.messages";

const handleMvcMessages = (): void => {
    const messagesFromMvc = CookieHelper.getValue(mvcMessagesCookieName);

    if (messagesFromMvc.length) {
        const messages: IMvcMessagesModel = JSON.parse(messagesFromMvc) as IMvcMessagesModel;
        for (const item of Object.values(messages) as IMvcMessageModel[]) {
            switch (item.type) {
                case "success":
                    NotificationService.addSuccessNotification({
                        message: item.message,
                    });
                    break;
                case "warning":
                    NotificationService.addWarningNotification({
                        message: item.message,
                    });
                    break;
                case "error":
                default:
                    NotificationService.addErrorNotification({
                        message: item.message,
                    });
                    break;
            }
        }
    }

    CookieHelper.remove(mvcMessagesCookieName);
};

/**
 * The sidebar layout component.
 */
export const SidebarLayout: React.FC<ISidebarLayoutProps> = ({ routes, footerRoutes, children }) => {
    const { userId } = useUserContext();
    const mainContent = useRef<HTMLDivElement | null>(null);
    const [contentContainerSize, setContentContainerSize] = useState<DOMRectReadOnly>();
    const [mainNavCollapsed, setMainNavCollapsed] = useState(
        () => window.innerWidth < sidebarWidthBreakpoint || (getItem<boolean>(userId, "mainNavCollapsed") ?? false),
    );
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const notifications = useNotificationServiceContext();
    const toggleFullscreen = useCallback(() => {
        setFullscreen(() => !fullscreen);
    }, [fullscreen]);

    useEffect(() => {
        handleMvcMessages();
    }, []);

    useNavigationCloseHandler({ close: () => setFullscreen(false), isOpen: fullscreen });

    useResizeObserver(mainContent, (entry) => {
        setContentContainerSize(entry.contentRect);
    });

    // toggles collapse between mini sidenav and normal
    const toggleSidenav = (): void => {
        setMainNavCollapsed(!mainNavCollapsed);
    };

    const collapseSidenav = (): void => {
        setMainNavCollapsed(true);
    };

    useEffect(() => {
        if (mainNavCollapsed) {
            document.body.classList.add("g-sidenav-hidden");
            document.body.classList.remove("g-sidenav-pinned");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }

        setItem<boolean>(userId, "mainNavCollapsed", mainNavCollapsed);
    }, [mainNavCollapsed, userId]);

    return (
        <FullscreenContext.Provider value={{ toggleFullscreen, fullscreen }}>
            {!fullscreen && (
                <Sidebar
                    routes={routes}
                    footerRoutes={footerRoutes}
                    toggleSidenav={toggleSidenav}
                    collapseSidenav={collapseSidenav}
                    sidenavOpen={mainNavCollapsed}
                    logo={{
                        innerLink: "/",
                        imgSrc: ExperlogixDocumentsMenuLogoWithText,
                        imgAlt: "Experlogixs Documents Logo",
                    }}
                />
            )}
            <div className="main-content" ref={mainContent}>
                {!fullscreen && <DefaultNavbar toggleSidenav={toggleSidenav} sidenavOpen={mainNavCollapsed} />}
                <NotificationsContainer ref={notifications} />
                <SidePanelWithContext containerSize={contentContainerSize} classname={classNames({ fullscreen })}>
                    {children}
                </SidePanelWithContext>
            </div>
        </FullscreenContext.Provider>
    );
};
