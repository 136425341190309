import React, { useCallback, useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { LocalizedLabel } from "../forms/LocalizedLabel";

import "./RadioComponentGroup.scss";

export type RadioComponentOptions<T> = {
    value: T;
    label: string;
    icon?: string;
    testSelectorValue?: string;
};

interface IRadioComponentProps {
    selectedValue: number;
    options: RadioComponentOptions<IRadioComponentProps["selectedValue"]>[];
    onChange: (val: IRadioComponentProps["selectedValue"]) => void;
    disabled?: boolean;
}

export const RadioComponentGroup: React.FC<IRadioComponentProps> = ({
    selectedValue,
    options,
    onChange,
    disabled = false
}: IRadioComponentProps) => {
    const changeHandler = useCallback(
        (newValue: IRadioComponentProps["selectedValue"]) => onChange(newValue),
        [onChange],
    );
    const { setSelector } = useTestSelector();

    const radioOptions = useMemo(() => {
        return options.map(({ value, label, icon, testSelectorValue }) => (
            <FormGroup check key={value}>
                <Input
                    name={label}
                    type="radio"
                    checked={selectedValue === value}
                    onChange={() => changeHandler(value)}
                    className="input-radio"
                    id={label}
                    disabled={disabled}
                    { ...setSelector(testSelectorValue) }
                />
                <LocalizedLabel for={label} check text={label} icon={icon} className={icon ? "label-with-icon" : ""} />
            </FormGroup>
        ));
    }, [changeHandler, disabled, options, selectedValue, setSelector]);

    return <div className="radio-component">{radioOptions}</div>;
};
