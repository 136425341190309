import React from "react";
import { LicenseFeaturesCard } from "../licensing/LicenseFeaturesCard";
import { LicenseStatusCard } from "../licensing/LicenseStatusCard";

import "./EditOrganizationViewTabsLicense.scss";

export const EditOrganizationViewTabsLicense: React.FC = () => (
    <>
        <LicenseStatusCard />
        <LicenseFeaturesCard />
    </>
);
