import React, { PropsWithChildren, useEffect, useRef } from "react";
import { NotificationServiceContext } from "../../contexts/NotificationServiceContext";
import { NotificationService } from "../../services/NotificationService";
import { NotificationsContainer } from "./NotificationsContainer";

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const notifications = useRef<NotificationsContainer | null>(null);

    useEffect(() => {
        NotificationService.onNewNotification((notificationOptions) => {
            notifications.current?.addNotification(notificationOptions);
        });

        return () => NotificationService.clearNotificationEventsSubscriptions();
    }, []);

    return <NotificationServiceContext.Provider value={notifications}>{children}</NotificationServiceContext.Provider>;
};
