import React from "react";
import * as Yup from "yup";
import { NullableValue } from "../models/NullableValue";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

/**
 * The editable template fields.
 */
export interface IEditableTemplateFields {
    description: NullableValue<string>;
}

/**
 * The template form validator class.
 */
export class TemplateFormValidator extends FormValidator<IEditableTemplateFields> {
    public static readonly templateDescriptionMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        description: Yup.string()
            .nullable(true)
            .max(TemplateFormValidator.templateDescriptionMaxLength, ({ max }) => {
                return <ValidatorError messageKey="Validation.DescriptionMaxLength" options={{ max }} />;
            }),
    });

    public validateDescription(description: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.description.validate(description);
    }
}
