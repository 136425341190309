import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Alert, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { ExpandableModal } from "./ExpandableModal";
import { ExpandCompressButton } from "../buttons/ExpandCompressButton";
import { Button } from "../buttons/Button";
import { useDialogContext } from "../../contexts/DialogContext";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useNavigationPromptDialog } from "../../hooks/navigation/NavigationPromptDialogHook";

/**
 * The create entity modal props interface.
 */
interface ICreateEntityModalProps {
    canCreate: boolean;
    className?: string;
    errorMessage?: string;
    show: boolean;
    isCreating: boolean;
    createLabel?: string;
    cancelLabel?: string;
    titleKey: string;
    modalName?: string;
    titleParams?: Record<string, string | number>;
    titleIconClassName?: string;
    expandable?: boolean;
    size?: string;
    unsavedWarningBody: string;
    unsavedWarningTitle?: string;
    isDirty?: boolean;
    readOnly?: boolean;
    primaryActionRemoved?: boolean;
    headerless?: boolean;
    onClose: () => void;
    onCreate?: () => Promise<void>;
    onErrorMessageToggle: () => void;
    onClosed?: () => void;
    children?: React.ReactNode;
    primaryActionButton?: JSX.Element;
}

/**
 * The create entity modal component.
 */
export const CreateEntityModal: React.FC<ICreateEntityModalProps> = ({
    canCreate,
    children,
    className,
    createLabel,
    cancelLabel,
    errorMessage,
    isCreating,
    isDirty,
    show,
    titleKey: title,
    modalName,
    titleParams,
    titleIconClassName,
    expandable,
    size,
    unsavedWarningBody,
    unsavedWarningTitle,
    primaryActionRemoved,
    onClose,
    onClosed,
    onCreate,
    onErrorMessageToggle,
    readOnly,
    headerless,
    primaryActionButton,
}: ICreateEntityModalProps) => {
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const { dialogState } = useDialogContext();
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const onCloseHandler = () => {
        if (isDirty && !readOnly) {
            setIsClosing(true);
        } else {
            onClose();
        }
    };

    useHotkeys(
        "ctrl+s, command+s",
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            onCreate?.();
        },
        {
            enabled: show && !dialogState.open && !readOnly,
            enableOnTags: ["INPUT", "SELECT"],
            filter: () => document.activeElement?.tagName !== "BUTTON",
            filterPreventDefault: false,
        },
        [show, dialogState.open, onCreate],
    );

    useHotkeys(
        "escape",
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (fullscreen) {
                setFullscreen(!fullscreen);
            } else {
                onCloseHandler();
            }
        },
        {
            enabled: show && !dialogState.open,
            enableOnTags: ["INPUT", "TEXTAREA", "SELECT"],
        },
        [show, fullscreen, dialogState.open, onCloseHandler],
    );

    const onConfirm = useCallback(() => {
        setIsClosing(false);
        onClose();
    }, [onClose]);

    const onCancel = useCallback(() => {
        setIsClosing(false);
    }, []);

    const blockerIsDirty = useMemo(() => {
        return !!isDirty && show;
    }, [isDirty, show]);

    useNavigationPromptDialog({
        componentId: modalName ?? title,
        messageKey: unsavedWarningBody,
        titleKey: unsavedWarningTitle,
        isDirty: blockerIsDirty,
        isLeaving: isClosing,
        onConfirm,
        onCancel,
    });

    const modalButtons = (
        <div className="modal-header-buttons">
            {expandable && (
                <ExpandCompressButton
                    iconType="far"
                    onToggle={(newValue) => setFullscreen(newValue)}
                    fullscreen={fullscreen}
                />
            )}
            <Button
                color="flat"
                className="close"
                onClick={onCloseHandler}
                description={t("Common.Close")}
                ariaLabel={t("Common.Close")}
                {...setSelector("closeButton")}
            >
                <i className="fal fa-times" />
            </Button>
        </div>
    );

    const renderPrimaryActionButton = () => {
        if (primaryActionRemoved) return;

        if (primaryActionButton) return primaryActionButton;

        return (
            <Button
                ariaLabel={t(createLabel ?? "Common.Create")}
                color="primary"
                disabled={!!readOnly || !canCreate}
                loading={isCreating}
                type="submit"
                onClick={onCreate}
                size="lg"
                {...setSelector("confirmButton")}
            >
                {t(createLabel ?? "Common.Create")}
            </Button>
        );
    };

    return (
        <ExpandableModal
            className={classNames(className, "create-entity-modal")}
            expanded={fullscreen}
            isOpen={show}
            fade={true}
            centered={true}
            onClosed={onClosed}
            size={size ?? "lg"}
            autoFocus={false}
            testSelectorValue="entityModal"
        >
            {!headerless && (
                <ModalHeader close={modalButtons}>
                    {titleIconClassName && <i className={titleIconClassName} />}
                    <span>{t(title, { ...titleParams })}</span>
                </ModalHeader>
            )}
            <ModalBody>
                <Alert color="danger" isOpen={!!errorMessage} toggle={onErrorMessageToggle}>
                    <i className="fas fa-times-circle" />
                    <span>{errorMessage}</span>
                </Alert>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={onCloseHandler}
                    outline
                    size="lg"
                    {...setSelector("cancelButton")}
                    ariaLabel={t(cancelLabel ?? "Common.Cancel")}
                >
                    {t(cancelLabel ?? "Common.Cancel")}
                </Button>
                {renderPrimaryActionButton()}
            </ModalFooter>
        </ExpandableModal>
    );
};
