import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ManageGroupPermissions } from "./groupTabs/ManageGroupPermissions";
import { TabContent } from "../tabs/TabContent";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { useTabs } from "../../hooks/TabsHook";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../models/permissions/IEditPermissionGroupModel";
import { useContentFileApi } from "../../hooks/contentManager/ContentFileApiHook";
import { useContentFolderApi } from "../../hooks/contentManager/ContentFolderApiHook";
import { ContentManagerEntityType } from "../../models/EntityType";
import { useNavigation } from "../../hooks/NavigationHook";
import { contentManagerSearchParamPath } from "../../hooks/contentManager/ContentManagerNavigationHook";

const editContentPermissionsGroupTabs = ["permissions"] as const;

type EditContentPermissionsGroupTabType = (typeof editContentPermissionsGroupTabs)[number];

interface IEditContentPermissionsGroupViewTab {
    contentId: string;
    projectId: string;
    contentType: ContentManagerEntityType;
}

export const EditContentPermissionsGroupViewTab: React.FC<IEditContentPermissionsGroupViewTab> = ({
    contentType,
    contentId,
    projectId,
}) => {
    const [contentPath, setContentPath] = useState("");
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useTabs<EditContentPermissionsGroupTabType>(
        editContentPermissionsGroupTabs,
        "permissions",
    );

    const {
        dirtyProps: { isDirty },
        refreshProps: { subscribeRefresh, unsubscribeRefresh },
        errorProps: {
            errors: { groupPermissions: groupPermissionsError },
        },
    } = useEditEntityContext<IEditPermissionGroupModel, any>();

    const { get: getContentFile } = useContentFileApi(projectId);
    const { get: getContentFolder } = useContentFolderApi(projectId);
    const getContentEntity = useMemo(
        () => (contentType === "Folder" ? getContentFolder : getContentFile),
        [contentType, getContentFile, getContentFolder],
    );

    const { replace } = useNavigation();
    const { hash } = useLocation();

    const updatePath = useCallback(() => {
        getContentEntity(contentId).then((contentEntityModel) => {
            setContentPath((cPath) => {
                if (!contentEntityModel.path) {
                    return cPath;
                }

                const dividedPath = contentEntityModel.path.split("/");
                dividedPath.pop();
                return dividedPath.join("/");
            });
        });
    }, [getContentEntity, contentId]);

    useEffect(() => {
        updatePath();
    }, [updatePath]);

    useEffect(() => {
        subscribeRefresh(updatePath);

        return () => unsubscribeRefresh(updatePath);
    }, [updatePath, subscribeRefresh, unsubscribeRefresh]);

    useEffect(() => {
        replace({
            search: `?${contentManagerSearchParamPath}=${contentPath}`,
            hash,
        });
    }, [contentPath, replace, hash]);

    const tabs: INavMenuItemProps[] = useMemo(
        () => [
            {
                displayName: "Common.Permissions",
                name: "permissions",
                testSelectorValue: "permissionsTab",
                onToggle: setActiveTab,
                icon: "fal fa-lock",
                dirty: isDirty(["groupPermissions"]),
                invalid: !!groupPermissionsError,
            },
        ],
        [groupPermissionsError, isDirty, setActiveTab],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")}>
                <ManageGroupPermissions />
            </TabContent>
        </NavMenu>
    );
};
