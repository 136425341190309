import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { useTestSelector } from "../AutomatedTestsServiceHook";
import { useLocaleFormat } from "../LocaleFormatHook";
import { FormattedDate } from "../../components/date/FormattedDate";

interface IRowData<TEntityModel> {
    item: TEntityModel;
    itemKey: string;
    rowDisabled: boolean;
}

export const useRenderItems = () => {
    const { setSelector } = useTestSelector();
    const { formatNumber } = useLocaleFormat();
    const { t } = useTranslation();

    const renderItems = <TEntityModel extends Record<string, any>>(
        columnDef: IEntityTableColumnDef,
        { item, itemKey, rowDisabled }: IRowData<TEntityModel>,
    ): JSX.Element | null => {
        switch (columnDef.type) {
            case "Button":
                return (
                    <Button
                        color="link"
                        className={classNames(
                            columnDef.fieldName,
                            columnDef.className,
                            columnDef.shouldTruncateText ? "text-truncate" : "",
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            columnDef.onClick(itemKey);
                        }}
                        {...setSelector("cell-link")}
                        description={columnDef.buttonDescription}
                        ariaLabel={`${columnDef.displayName}`}
                    >
                        {columnDef.content ? columnDef.content(item) : item[columnDef.fieldName]}
                    </Button>
                );
            case "Text":
                return <>{columnDef.content ? columnDef.content(item) : item[columnDef.fieldName]}</>;
            case "ResourceKey":
                return <>{t(columnDef.content ? columnDef.content(item) : item[columnDef.fieldName])}</>;
            case "Number":
                return <>{formatNumber(item[columnDef.fieldName] as number)}</>;
            case "Link":
                return rowDisabled || (columnDef.disabled && columnDef.disabled(item)) ? (
                    <>{item[columnDef.fieldName]}</>
                ) : (
                    <Link to={columnDef.url(item)} {...setSelector("cell-link")}>
                        {item[columnDef.fieldName]}
                    </Link>
                );
            case "DateTime":
                return (
                    <FormattedDate
                        format={columnDef.format ?? "DateTime"}
                        date={columnDef.content ? columnDef.content(item) : (item[columnDef.fieldName] as string)}
                    />
                );
            case "JSX":
                return columnDef.content(item) as JSX.Element;
            default:
                return null;
        }
    };

    return renderItems;
};
