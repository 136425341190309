export interface DateWithTimeZone {
    date: string;
    timeZoneId: string;
}

export enum TimeZoneExpirationDateType {
    Never = 0,
    Custom = 1,
}

export const ExpirationDateTypeTranslationMap = {
    [TimeZoneExpirationDateType.Never]: "Common.Never",
    [TimeZoneExpirationDateType.Custom]: "ExpirationDateType.Custom",
} as const;
