/**
 * The data set type.
 **/
export type DataSetType = "System.Form" | "System.Json" | "System.Xml";

/**
 * Gets the resource key based on the dataset type
 */
export enum DataSetTypeTranslationMap {
    "System.Form" = "Common.Form",
    "System.Xml" = "Common.Xml",
    "System.Json" = "Common.Json",
}
