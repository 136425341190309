import React from "react";
import { useTranslation } from "react-i18next";
import { ILicenseTemplateFeatures } from "../../../../models/license/ILicenseFeatureModel";
import { FeatureItem } from "./FeatureItem";
import { SubFeatureItem } from "./SubFeatureItem";
import { licenseFeaturesTranslationMap, licenseSubFeaturesTranslationMap } from "../../../../helpers/LicensingHelper";

export const TemplatesFeature: React.FC<ILicenseTemplateFeatures> = ({ enabled, word }) => {
    const { t } = useTranslation();

    return (
        <FeatureItem
            id={"templatesFeature"}
            enabled={enabled}
            featureName={t(licenseFeaturesTranslationMap["templatesFeature"])}
        >
            <SubFeatureItem enabled={word} name={licenseSubFeaturesTranslationMap.word} />
        </FeatureItem>
    );
};
