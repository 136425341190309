import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { FormGroup } from "reactstrap";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { EntityForm } from "../forms/EntityForm";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { IAddContentPermissionGroupFormFieldsModel } from "../../models/permissions/IAddContentPermissionGroupFormFieldsModel";
import { useContentGroupApi } from "../../hooks/contentManager/ContentGroupApiHook";
import { SelectUserGroup } from "../userGroups/SelectUserGroup";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useAddContentPermissionGroupValidation } from "../../hooks/permission/AddContentPermissionGroupValidation";
import { FormFeedbackError } from "../forms/feedbacks/FormFeedbackError";
import { UserGroupAvatar } from "../userGroups/UserGroupAvatar";

interface IAddContentPermissionGroupFormProps {
    contentId: string;
    initialValue: IAddContentPermissionGroupFormFieldsModel;
    projectId: string;
    onFormChange: (formData: IEntityFormData<IAddContentPermissionGroupFormFieldsModel>) => void;
}

export const AddContentPermissionGroupForm: React.FC<IAddContentPermissionGroupFormProps> = ({
    initialValue,
    onFormChange,
    contentId,
    projectId,
}) => {
    const { data, errors, onChange } = useCreateModalEntity<IAddContentPermissionGroupFormFieldsModel, string>({
        initialData: initialValue,
        useValidation: useAddContentPermissionGroupValidation,
        onFormChange,
    });
    const [listedGroups, setListedGroups] = useState<SelectComponentOptions<string>[]>([]);
    const { getAvailableGroups } = useContentGroupApi({ contentId, projectId });

    const getListedGroups = useCallback(async () => {
        try {
            const nonMemberGroups = await getAvailableGroups();
            setListedGroups(
                nonMemberGroups.map((userGroup) => {
                    return {
                        label: userGroup.name,
                        value: userGroup.groupId,
                        data: userGroup,
                        componentIcon: <UserGroupAvatar groupName={userGroup.name} parentType="dropdown" />,
                    };
                }),
            );
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [getAvailableGroups]);

    useEffect(() => {
        getListedGroups();
    }, [getListedGroups]);

    return (
        <EntityForm name="add-permission-group" className="permission-group-form">
            <FormGroup>
                <SelectUserGroup
                    className={classNames({ "is-invalid": errors.groupId })}
                    value={data.groupId}
                    onChange={(value) => onChange("groupId", value)}
                    availableGroups={listedGroups}
                    labelKey="Common.Group"
                    labelRequired
                />
                {errors.groupId && <FormFeedbackError error={errors.groupId} />}
            </FormGroup>
        </EntityForm>
    );
};
