import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
    IContentFileShareModel,
    ICreateContentFileShareModel,
    ContentFileShareBehaviors,
    IUpdateContentFileShareModel,
} from "../../../models/contentManager/ContentManagerApiModels";
import { useContentFileShareApi } from "./ContentFileShareApiHook";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useModalContext } from "../../../contexts/ModalContext";
import { IContentFileShareFormFields } from "../../../formValidators/ContentFileShareFormValidator";
import { CreateContentFileShareForm } from "../../../components/contentManager/editItemView/CreateContentFileShareForm";
import { EditContentFileShareForm } from "../../../components/contentManager/editItemView/EditContentFileShareForm";
import { getDefaultContentFileShareExpirationDate } from "../../../helpers/ContentFileShareHelper";
import { CreatedContentFileShareForm } from "../../../components/contentManager/editItemView/CreatedContentFileShareForm";
import { useUserContext } from "../../../contexts/UserContext";
import { getDefaultTimeZone } from "../../../components/forms/DateWithTimeZoneSelect";

export const useContentFileShare = () => {
    const { projectId } = useProjectContext();
    const { showModal, closeModal } = useModalContext();
    const { create, update } = useContentFileShareApi({ projectId });
    const { userTimeZone, availableTimeZones } = useUserContext();

    const [newlyCreatedShare, setNewlyCreatedShare] = useState<{
        contentFileShare: IContentFileShareModel;
        onDone?: (entity: IContentFileShareModel) => void;
    } | null>(null);

    const onCreatedContentFileShare = useCallback(
        (contentFileShare: IContentFileShareModel, onDone?: (entity: IContentFileShareModel) => void) => {
            showModal({
                contentToDisplay: CreatedContentFileShareForm,
                formPropsToFlow: { shareLinks: contentFileShare.shareLinks },
                entity: {
                    contentFileId: contentFileShare.contentFileId,
                },
                onCreated: closeModal,
                modalProps: {
                    className: "createdContentFolderModal",
                    titleKey: "Share.Create",
                    titleIconClassName: "fal fa-share-nodes",
                    size: "lg",
                    expandable: true,
                    onClose: () => {
                        setNewlyCreatedShare(null);
                        onDone?.(contentFileShare);
                        closeModal();
                    },
                    primaryActionRemoved: true,
                    cancelLabel: "Common.Close",
                },
            });
        },
        [closeModal, showModal],
    );

    const updateContentFileShare = useCallback(
        (
            contentFileShare: IContentFileShareModel,
            onCreateContentFileShareCreated: (entity: IContentFileShareModel) => void,
        ) => {
            showModal({
                contentToDisplay: EditContentFileShareForm,
                onCreate: ({ expirationDate, timeZoneId }: IContentFileShareFormFields) => {
                    const model: IUpdateContentFileShareModel = {
                        expirationDate:
                            expirationDate === null || timeZoneId === null
                                ? null
                                : {
                                      date: moment.parseZone(expirationDate).utcOffset(0, true).toISOString(),
                                      timeZoneId: timeZoneId,
                                  },
                    };
                    return update(contentFileShare.contentFileId, contentFileShare.contentFileShareId, model);
                },
                onCreated: (item: IContentFileShareModel) => {
                    closeModal();
                    onCreateContentFileShareCreated(item);
                },
                formPropsToFlow: { shareLinks: contentFileShare.shareLinks },
                entity: {
                    expirationDate: contentFileShare.expirationDate,
                    timeZoneId: contentFileShare.timeZoneId,
                },
                modalProps: {
                    createLabel: "Common.Save",
                    className: "createContentFolderModal",
                    titleKey: "Share.Edit",
                    titleIconClassName: "fal fa-share-nodes",
                    unsavedWarningBody: "Common.UnsavedWarningBody",
                    size: "lg",
                    expandable: true,
                    onClose: closeModal,
                },
            });
        },
        [closeModal, showModal, update],
    );

    const createContentFileShare = useCallback(
        (fileId: string, onCreateContentFileShareCreated?: (entity: IContentFileShareModel) => void) => {
            showModal({
                contentToDisplay: CreateContentFileShareForm,
                onCreate: ({ behaviors, expirationDate, timeZoneId }: IContentFileShareFormFields) => {
                    const model: ICreateContentFileShareModel = {
                        behaviors,
                        contentFileId: fileId,
                        expirationDate:
                            expirationDate === null || timeZoneId === null
                                ? null
                                : {
                                      date: moment.parseZone(expirationDate).utcOffset(0, true).toISOString(),
                                      timeZoneId: timeZoneId,
                                  },
                    };

                    return create(fileId, model);
                },
                onCreated: (item: IContentFileShareModel) => {
                    closeModal();
                    setNewlyCreatedShare({ contentFileShare: item, onDone: onCreateContentFileShareCreated });
                },
                formPropsToFlow: {},
                entity: {
                    behaviors: ContentFileShareBehaviors.View | ContentFileShareBehaviors.Download,
                    expirationDate: getDefaultContentFileShareExpirationDate().toISOString(),
                    timeZoneId: getDefaultTimeZone(undefined, userTimeZone, availableTimeZones).timeZoneId,
                },
                modalProps: {
                    className: "createContentFolderModal",
                    titleKey: "Share.Create",
                    titleIconClassName: "fal fa-share-nodes",
                    unsavedWarningBody: "Common.UnsavedWarningBody",
                    size: "lg",
                    expandable: true,
                    onClose: closeModal,
                },
                allowCreateWhenNotDirty: true,
            });
        },
        [availableTimeZones, closeModal, create, showModal, userTimeZone],
    );

    useEffect(() => {
        if (!newlyCreatedShare) {
            return;
        }

        onCreatedContentFileShare(newlyCreatedShare.contentFileShare, newlyCreatedShare.onDone);
    }, [newlyCreatedShare, onCreatedContentFileShare]);

    return {
        createContentFileShare,
        updateContentFileShare,
        onCreatedContentFileShare,
    };
};
